/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RiskControlDeleteRequest
 */
export interface RiskControlDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof RiskControlDeleteRequest
     */
    riskId: number;
}

/**
 * Check if a given object implements the RiskControlDeleteRequest interface.
 */
export function instanceOfRiskControlDeleteRequest(value: object): value is RiskControlDeleteRequest {
    if (!('riskId' in value) || value['riskId'] === undefined) return false;
    return true;
}

export function RiskControlDeleteRequestFromJSON(json: any): RiskControlDeleteRequest {
    return RiskControlDeleteRequestFromJSONTyped(json, false);
}

export function RiskControlDeleteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskControlDeleteRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'riskId': json['risk_id'],
    };
}

export function RiskControlDeleteRequestToJSON(value?: RiskControlDeleteRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'risk_id': value['riskId'],
    };
}

