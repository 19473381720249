/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeedRequest
 */
export interface FeedRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedRequest
     */
    institutionTypes?: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof FeedRequest
     */
    publicationStartDate: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FeedRequest
     */
    publicationEndDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof FeedRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FeedRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FeedRequest
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FeedRequest
     */
    isArchived?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeedRequest
     */
    keywordsOr?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeedRequest
     */
    keywordsAnd?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FeedRequest
     */
    keywordsNot?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FeedRequest
     */
    rollingWindow?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FeedRequest
     */
    totalArticlesCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FeedRequest
     */
    updatedBy?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedRequest
     */
    publisherLocations?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedRequest
     */
    publishers?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedRequest
     */
    jurisdictions?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof FeedRequest
     */
    sections?: Array<number>;
}

/**
 * Check if a given object implements the FeedRequest interface.
 */
export function instanceOfFeedRequest(value: object): value is FeedRequest {
    if (!('publicationStartDate' in value) || value['publicationStartDate'] === undefined) return false;
    if (!('publicationEndDate' in value) || value['publicationEndDate'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function FeedRequestFromJSON(json: any): FeedRequest {
    return FeedRequestFromJSONTyped(json, false);
}

export function FeedRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'institutionTypes': json['institution_types'] == null ? undefined : json['institution_types'],
        'publicationStartDate': (json['publication_start_date'] == null ? null : new Date(json['publication_start_date'])),
        'publicationEndDate': (json['publication_end_date'] == null ? null : new Date(json['publication_end_date'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'isArchived': json['is_archived'] == null ? undefined : json['is_archived'],
        'keywordsOr': json['keywords_or'] == null ? undefined : json['keywords_or'],
        'keywordsAnd': json['keywords_and'] == null ? undefined : json['keywords_and'],
        'keywordsNot': json['keywords_not'] == null ? undefined : json['keywords_not'],
        'rollingWindow': json['rolling_window'] == null ? undefined : json['rolling_window'],
        'totalArticlesCount': json['total_articles_count'] == null ? undefined : json['total_articles_count'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
        'publisherLocations': json['publisher_locations'] == null ? undefined : json['publisher_locations'],
        'publishers': json['publishers'] == null ? undefined : json['publishers'],
        'jurisdictions': json['jurisdictions'] == null ? undefined : json['jurisdictions'],
        'sections': json['sections'] == null ? undefined : json['sections'],
    };
}

export function FeedRequestToJSON(value?: FeedRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'institution_types': value['institutionTypes'],
        'publication_start_date': (value['publicationStartDate'] == null ? null : (value['publicationStartDate'] as any).toISOString().substring(0,10)),
        'publication_end_date': (value['publicationEndDate'] == null ? null : (value['publicationEndDate'] as any).toISOString().substring(0,10)),
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'is_archived': value['isArchived'],
        'keywords_or': value['keywordsOr'],
        'keywords_and': value['keywordsAnd'],
        'keywords_not': value['keywordsNot'],
        'rolling_window': value['rollingWindow'],
        'total_articles_count': value['totalArticlesCount'],
        'updated_by': value['updatedBy'],
        'publisher_locations': value['publisherLocations'],
        'publishers': value['publishers'],
        'jurisdictions': value['jurisdictions'],
        'sections': value['sections'],
    };
}

