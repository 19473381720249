/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Todo } from './Todo';
import {
    TodoFromJSON,
    TodoFromJSONTyped,
    TodoToJSON,
} from './Todo';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 * 
 * @export
 * @interface Feed
 */
export interface Feed {
    /**
     * 
     * @type {number}
     * @memberof Feed
     */
    readonly id: number;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Feed
     */
    readonly tags: Array<Tag>;
    /**
     * 
     * @type {Array<Todo>}
     * @memberof Feed
     */
    readonly todos: Array<Todo>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Feed
     */
    institutionTypes?: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof Feed
     */
    publicationStartDate: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Feed
     */
    publicationEndDate: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Feed
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Feed
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Feed
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Feed
     */
    isArchived?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Feed
     */
    keywordsOr?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Feed
     */
    keywordsAnd?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Feed
     */
    keywordsNot?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Feed
     */
    rollingWindow?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Feed
     */
    totalArticlesCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Feed
     */
    updatedBy?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Feed
     */
    publisherLocations?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Feed
     */
    publishers?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Feed
     */
    jurisdictions?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Feed
     */
    sections?: Array<number>;
}

/**
 * Check if a given object implements the Feed interface.
 */
export function instanceOfFeed(value: object): value is Feed {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('todos' in value) || value['todos'] === undefined) return false;
    if (!('publicationStartDate' in value) || value['publicationStartDate'] === undefined) return false;
    if (!('publicationEndDate' in value) || value['publicationEndDate'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function FeedFromJSON(json: any): Feed {
    return FeedFromJSONTyped(json, false);
}

export function FeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): Feed {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tags': ((json['tags'] as Array<any>).map(TagFromJSON)),
        'todos': ((json['todos'] as Array<any>).map(TodoFromJSON)),
        'institutionTypes': json['institution_types'] == null ? undefined : json['institution_types'],
        'publicationStartDate': (json['publication_start_date'] == null ? null : new Date(json['publication_start_date'])),
        'publicationEndDate': (json['publication_end_date'] == null ? null : new Date(json['publication_end_date'])),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'isArchived': json['is_archived'] == null ? undefined : json['is_archived'],
        'keywordsOr': json['keywords_or'] == null ? undefined : json['keywords_or'],
        'keywordsAnd': json['keywords_and'] == null ? undefined : json['keywords_and'],
        'keywordsNot': json['keywords_not'] == null ? undefined : json['keywords_not'],
        'rollingWindow': json['rolling_window'] == null ? undefined : json['rolling_window'],
        'totalArticlesCount': json['total_articles_count'] == null ? undefined : json['total_articles_count'],
        'updatedBy': json['updated_by'] == null ? undefined : json['updated_by'],
        'publisherLocations': json['publisher_locations'] == null ? undefined : json['publisher_locations'],
        'publishers': json['publishers'] == null ? undefined : json['publishers'],
        'jurisdictions': json['jurisdictions'] == null ? undefined : json['jurisdictions'],
        'sections': json['sections'] == null ? undefined : json['sections'],
    };
}

export function FeedToJSON(value?: Omit<Feed, 'id'|'tags'|'todos'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'institution_types': value['institutionTypes'],
        'publication_start_date': (value['publicationStartDate'] == null ? null : (value['publicationStartDate'] as any).toISOString().substring(0,10)),
        'publication_end_date': (value['publicationEndDate'] == null ? null : (value['publicationEndDate'] as any).toISOString().substring(0,10)),
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'description': value['description'],
        'is_archived': value['isArchived'],
        'keywords_or': value['keywordsOr'],
        'keywords_and': value['keywordsAnd'],
        'keywords_not': value['keywordsNot'],
        'rolling_window': value['rollingWindow'],
        'total_articles_count': value['totalArticlesCount'],
        'updated_by': value['updatedBy'],
        'publisher_locations': value['publisherLocations'],
        'publishers': value['publishers'],
        'jurisdictions': value['jurisdictions'],
        'sections': value['sections'],
    };
}

