import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import clsx from "clsx";
import { Tooltip } from "flowbite-react";
import { useDarkMode } from "usehooks-ts";

import styles from "assets/css/sidebarV2.module.css";

import Avatar from "shared/componentsV2/avatar";
import CompanySwitcher from "shared/componentsV2/company-dropdown";
import { useCompany } from "shared/context/CompanyProvider";
import {
  ASSISTANT_MODULE,
  HORIZON_SCANNING_MODULE,
  QUESTIONNAIRE_MODULE,
} from "shared/helpers/ability";
import { IS_DARK_MODE, IS_SIDEBAR_TOGGLE, MOBILE_DEVICE } from "shared/helpers/constant";
import { defaultSideBarItems } from "shared/helpers/sidebar";
import { getItemFromLocalStorage, getUsername, setItemToLocalStorage } from "shared/helpers/util";
import useScreen from "shared/hooks/useScreen";
import { useHome } from "shared/store/home";
import { useSideMenu } from "shared/store/rightSideBar";
import { useSidebar } from "shared/store/sidebar";

import { useAssistantAIAgent } from "modules/assistant/hooks/useAssistantAIAgent";
import usePaginatedFeedsQuery from "modules/horizonScanning/feeds/hooks/usePaginatedFeedsQuery";

import Icon from "../../components/icon/Icon";
import { SideBarItems } from "../../components/sidebar/types";
import SidebarComponentModule from "./SidebarComponentModule";

const SidebarComponent = () => {
  const deviceType = useScreen();
  const profile = useHome((state) => state.profile);
  const { isDarkMode, toggle } = useDarkMode();

  const { pathname } = useLocation();

  const { currentCompany, currentUserRole } = useCompany();

  const { getAssistantAgents } = useAssistantAIAgent();
  const { data: assistantAgents } = getAssistantAgents();

  const [sideBarItems, setSidebarItems] = useState<Array<SideBarItems>>([]);
  const setIsSideMenuShown = useSideMenu((state) => state.setIsSideMenuShown);

  const feedQuery = usePaginatedFeedsQuery({ search: "", isArchived: false });

  const [isToggle, isVisible, setIsToggle, setIsVisible] = useSidebar((state) => [
    state.isToggle,
    state.isVisible,
    state.setIsToggle,
    state.setIsVisible,
    state.sideBarItems,
    state.setSidebarItems,
  ]);

  useEffect(() => {
    if (assistantAgents?.results?.length) {
      const assistantSidebarItems = assistantAgents?.results.map((agent) => ({
        label: `Talk to ${agent.name}`,
        path: `/workspace/${currentCompany.id}/assistant/agents/${agent.id}`,
      }));

      setSidebarItems((prev) => {
        return prev.map((item) => {
          if (item.subject === ASSISTANT_MODULE) {
            const existingPaths = new Set(item.items?.map((i) => i.path) || []);
            const uniqueItems = assistantSidebarItems.filter((i) => !existingPaths.has(i.path));

            return {
              ...item,
              isOpen:
                uniqueItems.map((i) => i.path).some((path) => pathname.startsWith(path)) ||
                item.isOpen,
              items: [...(item.items || []), ...uniqueItems],
            };
          }
          return item;
        });
      });
    }
  }, [assistantAgents?.results, currentCompany]);

  useEffect(() => {
    const feedItems = feedQuery.data?.results.filter((feed) => !feed.isArchived);
    const feedSidebarItems = feedItems?.map((feed) => ({
      label: feed.name,
      path: `/workspace/${currentCompany.id}/horizon-scanning/feeds/${feed.id}`,
    }));
    if (feedSidebarItems?.length) {
      feedSidebarItems.splice(0, 0, {
        label: "Mega Feed",
        path: `/workspace/${currentCompany.id}/horizon-scanning/feeds/mega`,
      });
    }
    if (feedItems?.length) {
      setSidebarItems((prev) => {
        return prev.map((item) => {
          if (item.subject === HORIZON_SCANNING_MODULE) {
            return { ...item, items: feedSidebarItems };
          }
          return item;
        });
      });
    }
  }, [feedQuery.data?.results]);

  useEffect(() => {
    if (deviceType === MOBILE_DEVICE) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
      setIsToggle(getItemFromLocalStorage(IS_SIDEBAR_TOGGLE));
    }
  }, [deviceType]);

  const onClick = useCallback(
    (index: number) => {
      setSidebarItems((prev) => {
        const newItems = [...prev];
        newItems.forEach((item: SideBarItems, itemIndex: number) => {
          if (itemIndex === index) {
            return;
          }
          item.isOpen = false;
        });
        newItems[index].isOpen = !prev[index].isOpen;
        return newItems;
      });
      setIsSideMenuShown(false);
    },
    [sideBarItems]
  );

  const closeAllSubmenu = () => {
    setSidebarItems((prev) => {
      const newItems = [...prev];
      newItems.forEach((item: SideBarItems) => {
        item.isOpen = false;
      });
      return newItems;
    });
  };

  const setOpenDropnDownOnRefresh = () => {
    setSidebarItems((prev) => {
      const newItems = prev.length === 0 ? defaultSideBarItems(currentCompany?.id) : [...prev];
      newItems.forEach((navLink: SideBarItems) => {
        navLink.items?.find((submenu) => {
          if (pathname?.startsWith(submenu.path)) navLink.isOpen = true;
        });
      });

      return currentUserRole === "GUEST"
        ? newItems.filter((x) => x.subject === QUESTIONNAIRE_MODULE)
        : newItems;
    });
  };

  useEffect(() => {
    const getIsDarkMode = getItemFromLocalStorage(IS_DARK_MODE);
    if (getIsDarkMode === undefined) {
      setItemToLocalStorage(IS_DARK_MODE, JSON.stringify(false));
    }
    if (getIsDarkMode !== isDarkMode) {
      toggle();
    } else {
      if (isDarkMode) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    }
  }, [isDarkMode]);

  useEffect(() => {
    closeAllSubmenu();
    if (!isToggle) {
      setOpenDropnDownOnRefresh();
    }
  }, [isToggle, currentUserRole, currentCompany]);

  if (!isVisible && deviceType === MOBILE_DEVICE) {
    return null;
  }

  return (
    <section
      className={clsx(
        styles.sidebar,
        "print-ignore dark:border-thunders dark:bg-darkjunglegreenss",
        {
          [styles.sidebarFullWidth]: !isToggle,
          [styles.sidebarToggle]: isToggle,
        }
      )}
    >
      <div className="flex flex-col gap-6">
        <CompanySwitcher />
        <SidebarComponentModule onClick={onClick} sideBarItems={sideBarItems} />
      </div>
      <div className="flex flex-col gap-2 px-2 py-[10px]">
        <div className="flex flex-row items-center gap-2">
          <Icon type="setting-icon" size="icon-sm" fill={true} className="table_icon" />
          <Link
            className="text-sm font-medium text-brightgrey dark:text-gray_700"
            to={"/settings/my-account"}
          >
            Settings
          </Link>
        </div>
        <div className="flex flex-row items-center gap-2">
          <Avatar
            name={getUsername(profile)}
            avatarFor="user"
            avatarType="rounded"
            size="smd"
            url={profile?.profile_picture}
            className="cursor-default"
          />
          <Tooltip
            content={profile?.name || profile?.email}
            className="metadata_tooltip"
            arrow={false}
            placement="top-start"
          >
            <span className="block w-44 overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-brightgrey dark:text-gray_700">
              {profile?.name || profile?.email}
            </span>
          </Tooltip>
        </div>
      </div>
    </section>
  );
};

export default SidebarComponent;
