import { ActionMeta, GroupBase, MultiValue, OptionProps, SingleValue } from "react-select";
import { components } from "react-select";
import { MultiValueGenericProps } from "react-select";

import { MultiSelect } from "components/ui/multi-select";
import { Avatar } from "flowbite-react";
import { useDarkMode } from "usehooks-ts";

import { optionType } from "shared/components/metadata/types";
import { getUsername } from "shared/helpers/util";

import { UserResponse } from "modules/home/types";

export const UserSelect = ({
  value,
  options,
  onChange,
  placeholder = "Search or select",
}: {
  value: optionType[];
  options: { label: string; value: string }[];
  onChange: (
    newValue: MultiValue<optionType> | SingleValue<optionType>,
    actionMeta: ActionMeta<optionType>
  ) => void;
  placeholder?: string;
}) => {
  const { isDarkMode } = useDarkMode();

  return (
    <MultiSelect
      isClearable={false}
      isMulti
      components={{ Option: UserOption, MultiValueLabel }}
      closeMenuOnSelect={false}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      overrideProperties={{
        control: () => ({
          color: isDarkMode ? "#6b7280" : "#374151",
        }),
        multiValue: () => ({
          color: isDarkMode ? "#6b7280" : "#374151",
          backgroundColor: "transparent",
          svg: {
            color: isDarkMode ? "#6b7280" : "#374151",
          },
        }),
      }}
    />
  );
};

const UserLabel = ({ user, label }: { user: UserResponse; label: string }) => {
  const userName = getUsername(user);

  return (
    <div className="flex items-center gap-2 text-gray-700 dark:text-gray-500">
      <Avatar
        img={user.profile_picture || ""}
        placeholderInitials={userName.charAt(0)}
        alt={userName}
        size="xs"
        rounded
        theme={{ root: { base: "!p-0 [&>div]:!p-0", size: { xs: "h-5 w-5" } } }}
      />
      <span className="truncate text-xs font-medium text-gray-700 dark:text-gray-500">{label}</span>
    </div>
  );
};

const MultiValueLabel = (props: MultiValueGenericProps<optionType>) => {
  return (
    <components.MultiValueLabel {...props}>
      <UserLabel user={props.data.user as UserResponse} label={props.data.label} />
    </components.MultiValueLabel>
  );
};

const UserOption = (props: OptionProps<optionType, boolean, GroupBase<optionType>>) => {
  return (
    <components.Option {...props}>
      <UserLabel user={props.data.data as UserResponse} label={props.data.label} />
    </components.Option>
  );
};
