/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Artifact,
  ArtifactRecord,
  BulkCreateFromLibraryRequest,
  ControlComment,
  ControlCommentRequest,
  ControlImportanceScore,
  ControlRequest,
  ControlRequestRequest,
  ControlResponse,
  ControlTasks,
  ControlsLibrary,
  EvidenceComment,
  EvidenceCommentRequest,
  EvidenceRecord,
  EvidenceRecordRequest,
  EvidenceRequestRequest,
  EvidenceResponse,
  PaginatedArtifactList,
  PaginatedArtifactRecordList,
  PaginatedControlCommentList,
  PaginatedControlResponseList,
  PaginatedControlsLibraryList,
  PaginatedEvidenceCommentList,
  PaginatedEvidenceRecordList,
  PaginatedEvidenceResponseList,
  PatchedControlCommentRequest,
  PatchedControlRequestRequest,
  PatchedEvidenceCommentRequest,
  PatchedEvidenceRequestRequest,
  PatchedReviewFrequencyRequest,
  ReviewFrequency,
  ReviewFrequencyRequest,
  RiskCalculationResult,
  RiskControlDeleteRequest,
  RiskControlRead,
  RiskControlRequest,
  TaskConnectionRequest,
} from '../models/index';
import {
    ArtifactFromJSON,
    ArtifactToJSON,
    ArtifactRecordFromJSON,
    ArtifactRecordToJSON,
    BulkCreateFromLibraryRequestFromJSON,
    BulkCreateFromLibraryRequestToJSON,
    ControlCommentFromJSON,
    ControlCommentToJSON,
    ControlCommentRequestFromJSON,
    ControlCommentRequestToJSON,
    ControlImportanceScoreFromJSON,
    ControlImportanceScoreToJSON,
    ControlRequestFromJSON,
    ControlRequestToJSON,
    ControlRequestRequestFromJSON,
    ControlRequestRequestToJSON,
    ControlResponseFromJSON,
    ControlResponseToJSON,
    ControlTasksFromJSON,
    ControlTasksToJSON,
    ControlsLibraryFromJSON,
    ControlsLibraryToJSON,
    EvidenceCommentFromJSON,
    EvidenceCommentToJSON,
    EvidenceCommentRequestFromJSON,
    EvidenceCommentRequestToJSON,
    EvidenceRecordFromJSON,
    EvidenceRecordToJSON,
    EvidenceRecordRequestFromJSON,
    EvidenceRecordRequestToJSON,
    EvidenceRequestRequestFromJSON,
    EvidenceRequestRequestToJSON,
    EvidenceResponseFromJSON,
    EvidenceResponseToJSON,
    PaginatedArtifactListFromJSON,
    PaginatedArtifactListToJSON,
    PaginatedArtifactRecordListFromJSON,
    PaginatedArtifactRecordListToJSON,
    PaginatedControlCommentListFromJSON,
    PaginatedControlCommentListToJSON,
    PaginatedControlResponseListFromJSON,
    PaginatedControlResponseListToJSON,
    PaginatedControlsLibraryListFromJSON,
    PaginatedControlsLibraryListToJSON,
    PaginatedEvidenceCommentListFromJSON,
    PaginatedEvidenceCommentListToJSON,
    PaginatedEvidenceRecordListFromJSON,
    PaginatedEvidenceRecordListToJSON,
    PaginatedEvidenceResponseListFromJSON,
    PaginatedEvidenceResponseListToJSON,
    PatchedControlCommentRequestFromJSON,
    PatchedControlCommentRequestToJSON,
    PatchedControlRequestRequestFromJSON,
    PatchedControlRequestRequestToJSON,
    PatchedEvidenceCommentRequestFromJSON,
    PatchedEvidenceCommentRequestToJSON,
    PatchedEvidenceRequestRequestFromJSON,
    PatchedEvidenceRequestRequestToJSON,
    PatchedReviewFrequencyRequestFromJSON,
    PatchedReviewFrequencyRequestToJSON,
    ReviewFrequencyFromJSON,
    ReviewFrequencyToJSON,
    ReviewFrequencyRequestFromJSON,
    ReviewFrequencyRequestToJSON,
    RiskCalculationResultFromJSON,
    RiskCalculationResultToJSON,
    RiskControlDeleteRequestFromJSON,
    RiskControlDeleteRequestToJSON,
    RiskControlReadFromJSON,
    RiskControlReadToJSON,
    RiskControlRequestFromJSON,
    RiskControlRequestToJSON,
    TaskConnectionRequestFromJSON,
    TaskConnectionRequestToJSON,
} from '../models/index';

export interface ControlsArtifactRecordsListRequest {
    company: number;
    evidence: number;
    createdAfter?: Date;
    createdBefore?: Date;
    evidenceRecord?: number;
    page?: number;
    pageSize?: number;
}

export interface ControlsArtifactRecordsRetrieveRequest {
    company: number;
    evidence: number;
    id: number;
}

export interface ControlsArtifactsCreateRequest {
    company: number;
    title: string;
    evidence: number;
    description?: string | null;
    url?: string | null;
    file?: Blob | null;
}

export interface ControlsArtifactsCreateFromRecordCreateRequest {
    company: number;
    evidence: number;
    recordId: number;
}

export interface ControlsArtifactsDestroyRequest {
    company: number;
    id: number;
}

export interface ControlsArtifactsListRequest {
    company: number;
    control?: number;
    controls?: Array<number>;
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    description?: string;
    evidence?: number;
    evidenceIn?: Array<number>;
    page?: number;
    pageSize?: number;
    title?: string;
    updatedAtAfter?: Date;
    updatedAtBefore?: Date;
}

export interface ControlsArtifactsRetrieveRequest {
    company: number;
    id: number;
}

export interface ControlsBulkCreateFromLibraryCreateRequest {
    company: number;
    bulkCreateFromLibraryRequest: BulkCreateFromLibraryRequest;
}

export interface ControlsConnectRiskToControlCreateRequest {
    company: number;
    id: number;
    riskControlRequest: RiskControlRequest;
}

export interface ControlsConnectTaskToControlCreateRequest {
    company: number;
    id: number;
    taskConnectionRequest: TaskConnectionRequest;
}

export interface ControlsControlCommentsCreateRequest {
    controlCommentRequest: ControlCommentRequest;
}

export interface ControlsControlCommentsDestroyRequest {
    id: number;
}

export interface ControlsControlCommentsListRequest {
    control?: number;
    page?: number;
    pageSize?: number;
    status?: ControlsControlCommentsListStatusEnum;
}

export interface ControlsControlCommentsPartialUpdateRequest {
    id: number;
    patchedControlCommentRequest?: PatchedControlCommentRequest;
}

export interface ControlsControlCommentsRetrieveRequest {
    id: number;
}

export interface ControlsControlCommentsUpdateRequest {
    id: number;
    controlCommentRequest: ControlCommentRequest;
}

export interface ControlsControlsLibrariesListRequest {
    company: number;
    controlArea?: string;
    page?: number;
    pageSize?: number;
}

export interface ControlsControlsLibrariesRetrieveRequest {
    company: number;
    id: number;
}

export interface ControlsCreateRequest {
    company: number;
    controlRequestRequest: ControlRequestRequest;
}

export interface ControlsDestroyRequest {
    company: number;
    id: number;
}

export interface ControlsDisconnectRiskFromControlCreateRequest {
    company: number;
    id: number;
    riskControlDeleteRequest: RiskControlDeleteRequest;
}

export interface ControlsDisconnectTaskFromControlCreateRequest {
    company: number;
    id: number;
    taskConnectionRequest: TaskConnectionRequest;
}

export interface ControlsEvidenceCommentsCreateRequest {
    evidenceCommentRequest: EvidenceCommentRequest;
}

export interface ControlsEvidenceCommentsDestroyRequest {
    id: number;
}

export interface ControlsEvidenceCommentsListRequest {
    evidence?: number;
    page?: number;
    pageSize?: number;
    status?: ControlsEvidenceCommentsListStatusEnum;
}

export interface ControlsEvidenceCommentsPartialUpdateRequest {
    id: number;
    patchedEvidenceCommentRequest?: PatchedEvidenceCommentRequest;
}

export interface ControlsEvidenceCommentsRetrieveRequest {
    id: number;
}

export interface ControlsEvidenceCommentsUpdateRequest {
    id: number;
    evidenceCommentRequest: EvidenceCommentRequest;
}

export interface ControlsEvidenceRecordCreateRequest {
    company: number;
    evidenceRecordRequest: EvidenceRecordRequest;
}

export interface ControlsEvidenceRecordDestroyRequest {
    company: number;
    id: number;
    control?: number;
    evidence?: number;
}

export interface ControlsEvidenceRecordListRequest {
    company: number;
    control?: number;
    evidence?: number;
    page?: number;
    pageSize?: number;
}

export interface ControlsEvidenceRecordRetrieveRequest {
    company: number;
    id: number;
    evidence?: number;
}

export interface ControlsEvidenceReviewFrequenciesMarkReviewedUpdateRequest {
    company: number;
    evidence: number;
    id: number;
    reviewFrequencyRequest?: ReviewFrequencyRequest;
}

export interface ControlsEvidenceReviewFrequenciesPartialUpdateRequest {
    company: number;
    evidence: number;
    id: number;
    patchedReviewFrequencyRequest?: PatchedReviewFrequencyRequest;
}

export interface ControlsEvidenceReviewFrequenciesUpdateRequest {
    company: number;
    id: number;
    reviewFrequencyRequest?: ReviewFrequencyRequest;
}

export interface ControlsEvidencesAddToControlCreateRequest {
    company: number;
    control: number;
    id: number;
}

export interface ControlsEvidencesControlsListRequest {
    company: number;
    id: number;
    control?: number;
    controlExclude?: number;
    controls?: Array<number>;
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    nameIcontains?: string;
    page?: number;
    pageSize?: number;
    query?: string;
    status?: ControlsEvidencesControlsListStatusEnum;
    statusIn?: Array<string>;
    updatedAtAfter?: Date;
    updatedAtBefore?: Date;
}

export interface ControlsEvidencesCreateRequest {
    company: number;
    evidenceRequestRequest: EvidenceRequestRequest;
}

export interface ControlsEvidencesDestroyRequest {
    company: number;
    id: number;
}

export interface ControlsEvidencesListRequest {
    company: number;
    control?: number;
    controlExclude?: number;
    controls?: Array<number>;
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    nameIcontains?: string;
    page?: number;
    pageSize?: number;
    query?: string;
    status?: ControlsEvidencesListStatusEnum;
    statusIn?: Array<string>;
    updatedAtAfter?: Date;
    updatedAtBefore?: Date;
}

export interface ControlsEvidencesPartialUpdateRequest {
    company: number;
    id: number;
    patchedEvidenceRequestRequest?: PatchedEvidenceRequestRequest;
}

export interface ControlsEvidencesRemoveFromControlCreateRequest {
    company: number;
    control: number;
    id: number;
}

export interface ControlsEvidencesRetrieveRequest {
    company: number;
    id: number;
}

export interface ControlsEvidencesUpdateRequest {
    company: number;
    id: number;
    evidenceRequestRequest: EvidenceRequestRequest;
}

export interface ControlsListRequest {
    company: number;
    applicabilityTo?: Array<ControlsListApplicabilityToEnum | null>;
    approvalBy?: Array<number>;
    approvalStatus?: Array<ControlsListApprovalStatusEnum | null>;
    controlArea?: Array<ControlsListControlAreaEnum | null>;
    controlType?: Array<ControlsListControlTypeEnum | null>;
    controlsSort?: string;
    createdAt?: Date;
    createdBy?: number;
    customId?: string;
    decisionDetails?: string;
    description?: string;
    evidences?: Array<number>;
    executionFrequency?: Array<ControlsListExecutionFrequencyEnum | null>;
    executionType?: Array<ControlsListExecutionTypeEnum | null>;
    implementedAtAfter?: Date;
    implementedAtBefore?: Date;
    importanceScore?: number;
    importanceScoreGt?: number;
    importanceScoreLt?: number;
    importanceScoreRangeMax?: string | null;
    importanceScoreRangeMin?: string | null;
    isAiGenerated?: boolean;
    keywordsAnd?: string;
    keywordsNot?: string;
    keywordsOr?: string;
    library?: number;
    name?: string;
    nextReviewAtAfter?: Date;
    nextReviewAtBefore?: Date;
    objectIsPublic?: boolean;
    objectOwner?: Array<number>;
    obligations?: Array<number>;
    page?: number;
    pageSize?: number;
    query?: string;
    questionnaires?: Array<number>;
    reviewFrequency?: number;
    riskLevel?: ControlsListRiskLevelEnum;
    risks?: Array<number>;
    status?: Array<ControlsListStatusEnum>;
    tags?: Array<string>;
    updatedAtAfter?: Date;
    updatedAtBefore?: Date;
    updatedBy?: number;
}

export interface ControlsPartialUpdateRequest {
    company: number;
    id: number;
    patchedControlRequestRequest?: PatchedControlRequestRequest;
}

export interface ControlsPreviewImportanceScoreCreateRequest {
    company: number;
    id: number;
    riskControlRequest: Array<RiskControlRequest>;
}

export interface ControlsPreviewRiskControlCreateRequest {
    company: number;
    id: number;
    riskControlRequest: RiskControlRequest;
}

export interface ControlsRetrieveRequest {
    company: number;
    id: number;
}

export interface ControlsReviewFrequenciesMarkReviewedUpdateRequest {
    company: number;
    control: number;
    id: number;
    reviewFrequencyRequest?: ReviewFrequencyRequest;
}

export interface ControlsReviewFrequenciesPartialUpdateRequest {
    company: number;
    control: number;
    id: number;
    patchedReviewFrequencyRequest?: PatchedReviewFrequencyRequest;
}

export interface ControlsReviewFrequenciesUpdateRequest {
    company: number;
    id: number;
    reviewFrequencyRequest?: ReviewFrequencyRequest;
}

export interface ControlsTasksRetrieveRequest {
    company: number;
    id: number;
}

export interface ControlsUpdateRequest {
    company: number;
    id: number;
    controlRequestRequest: ControlRequestRequest;
}

/**
 * 
 */
export class ControlsApi extends runtime.BaseAPI {

    /**
     */
    async controlsArtifactRecordsListRaw(requestParameters: ControlsArtifactRecordsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedArtifactRecordList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsArtifactRecordsList().'
            );
        }

        if (requestParameters['evidence'] == null) {
            throw new runtime.RequiredError(
                'evidence',
                'Required parameter "evidence" was null or undefined when calling controlsArtifactRecordsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['createdAfter'] != null) {
            queryParameters['created_after'] = (requestParameters['createdAfter'] as any).toISOString();
        }

        if (requestParameters['createdBefore'] != null) {
            queryParameters['created_before'] = (requestParameters['createdBefore'] as any).toISOString();
        }

        if (requestParameters['evidence'] != null) {
            queryParameters['evidence'] = requestParameters['evidence'];
        }

        if (requestParameters['evidenceRecord'] != null) {
            queryParameters['evidence_record'] = requestParameters['evidenceRecord'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/artifact-records/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedArtifactRecordListFromJSON(jsonValue));
    }

    /**
     */
    async controlsArtifactRecordsList(requestParameters: ControlsArtifactRecordsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedArtifactRecordList> {
        const response = await this.controlsArtifactRecordsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsArtifactRecordsRetrieveRaw(requestParameters: ControlsArtifactRecordsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArtifactRecord>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsArtifactRecordsRetrieve().'
            );
        }

        if (requestParameters['evidence'] == null) {
            throw new runtime.RequiredError(
                'evidence',
                'Required parameter "evidence" was null or undefined when calling controlsArtifactRecordsRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsArtifactRecordsRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['evidence'] != null) {
            queryParameters['evidence'] = requestParameters['evidence'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/artifact-records/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtifactRecordFromJSON(jsonValue));
    }

    /**
     */
    async controlsArtifactRecordsRetrieve(requestParameters: ControlsArtifactRecordsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArtifactRecord> {
        const response = await this.controlsArtifactRecordsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsArtifactsCreateRaw(requestParameters: ControlsArtifactsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Artifact>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsArtifactsCreate().'
            );
        }

        if (requestParameters['title'] == null) {
            throw new runtime.RequiredError(
                'title',
                'Required parameter "title" was null or undefined when calling controlsArtifactsCreate().'
            );
        }

        if (requestParameters['evidence'] == null) {
            throw new runtime.RequiredError(
                'evidence',
                'Required parameter "evidence" was null or undefined when calling controlsArtifactsCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['title'] != null) {
            formParams.append('title', requestParameters['title'] as any);
        }

        if (requestParameters['description'] != null) {
            formParams.append('description', requestParameters['description'] as any);
        }

        if (requestParameters['evidence'] != null) {
            formParams.append('evidence', requestParameters['evidence'] as any);
        }

        if (requestParameters['url'] != null) {
            formParams.append('url', requestParameters['url'] as any);
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/api/v1/controls/artifacts/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtifactFromJSON(jsonValue));
    }

    /**
     */
    async controlsArtifactsCreate(requestParameters: ControlsArtifactsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Artifact> {
        const response = await this.controlsArtifactsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsArtifactsCreateFromRecordCreateRaw(requestParameters: ControlsArtifactsCreateFromRecordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Artifact>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsArtifactsCreateFromRecordCreate().'
            );
        }

        if (requestParameters['evidence'] == null) {
            throw new runtime.RequiredError(
                'evidence',
                'Required parameter "evidence" was null or undefined when calling controlsArtifactsCreateFromRecordCreate().'
            );
        }

        if (requestParameters['recordId'] == null) {
            throw new runtime.RequiredError(
                'recordId',
                'Required parameter "recordId" was null or undefined when calling controlsArtifactsCreateFromRecordCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['evidence'] != null) {
            queryParameters['evidence'] = requestParameters['evidence'];
        }

        if (requestParameters['recordId'] != null) {
            queryParameters['record_id'] = requestParameters['recordId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/artifacts/create-from-record/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtifactFromJSON(jsonValue));
    }

    /**
     */
    async controlsArtifactsCreateFromRecordCreate(requestParameters: ControlsArtifactsCreateFromRecordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Artifact> {
        const response = await this.controlsArtifactsCreateFromRecordCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsArtifactsDestroyRaw(requestParameters: ControlsArtifactsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsArtifactsDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsArtifactsDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/artifacts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async controlsArtifactsDestroy(requestParameters: ControlsArtifactsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.controlsArtifactsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async controlsArtifactsListRaw(requestParameters: ControlsArtifactsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedArtifactList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsArtifactsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['control'] != null) {
            queryParameters['control'] = requestParameters['control'];
        }

        if (requestParameters['controls'] != null) {
            queryParameters['controls'] = requestParameters['controls']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['description'] != null) {
            queryParameters['description'] = requestParameters['description'];
        }

        if (requestParameters['evidence'] != null) {
            queryParameters['evidence'] = requestParameters['evidence'];
        }

        if (requestParameters['evidenceIn'] != null) {
            queryParameters['evidence__in'] = requestParameters['evidenceIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        if (requestParameters['updatedAtAfter'] != null) {
            queryParameters['updated_at_after'] = (requestParameters['updatedAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['updatedAtBefore'] != null) {
            queryParameters['updated_at_before'] = (requestParameters['updatedAtBefore'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/artifacts/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedArtifactListFromJSON(jsonValue));
    }

    /**
     */
    async controlsArtifactsList(requestParameters: ControlsArtifactsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedArtifactList> {
        const response = await this.controlsArtifactsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsArtifactsRetrieveRaw(requestParameters: ControlsArtifactsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Artifact>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsArtifactsRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsArtifactsRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/artifacts/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtifactFromJSON(jsonValue));
    }

    /**
     */
    async controlsArtifactsRetrieve(requestParameters: ControlsArtifactsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Artifact> {
        const response = await this.controlsArtifactsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsBulkCreateFromLibraryCreateRaw(requestParameters: ControlsBulkCreateFromLibraryCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsBulkCreateFromLibraryCreate().'
            );
        }

        if (requestParameters['bulkCreateFromLibraryRequest'] == null) {
            throw new runtime.RequiredError(
                'bulkCreateFromLibraryRequest',
                'Required parameter "bulkCreateFromLibraryRequest" was null or undefined when calling controlsBulkCreateFromLibraryCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/bulk_create_from_library/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkCreateFromLibraryRequestToJSON(requestParameters['bulkCreateFromLibraryRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async controlsBulkCreateFromLibraryCreate(requestParameters: ControlsBulkCreateFromLibraryCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.controlsBulkCreateFromLibraryCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Connect a risk to a control with optional mitigation values
     */
    async controlsConnectRiskToControlCreateRaw(requestParameters: ControlsConnectRiskToControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskControlRead>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsConnectRiskToControlCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsConnectRiskToControlCreate().'
            );
        }

        if (requestParameters['riskControlRequest'] == null) {
            throw new runtime.RequiredError(
                'riskControlRequest',
                'Required parameter "riskControlRequest" was null or undefined when calling controlsConnectRiskToControlCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/{id}/connect_risk_to_control/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RiskControlRequestToJSON(requestParameters['riskControlRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskControlReadFromJSON(jsonValue));
    }

    /**
     * Connect a risk to a control with optional mitigation values
     */
    async controlsConnectRiskToControlCreate(requestParameters: ControlsConnectRiskToControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskControlRead> {
        const response = await this.controlsConnectRiskToControlCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Connect a task to a control.
     */
    async controlsConnectTaskToControlCreateRaw(requestParameters: ControlsConnectTaskToControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsConnectTaskToControlCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsConnectTaskToControlCreate().'
            );
        }

        if (requestParameters['taskConnectionRequest'] == null) {
            throw new runtime.RequiredError(
                'taskConnectionRequest',
                'Required parameter "taskConnectionRequest" was null or undefined when calling controlsConnectTaskToControlCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/{id}/connect_task_to_control/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskConnectionRequestToJSON(requestParameters['taskConnectionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Connect a task to a control.
     */
    async controlsConnectTaskToControlCreate(requestParameters: ControlsConnectTaskToControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.controlsConnectTaskToControlCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async controlsControlCommentsCreateRaw(requestParameters: ControlsControlCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlComment>> {
        if (requestParameters['controlCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'controlCommentRequest',
                'Required parameter "controlCommentRequest" was null or undefined when calling controlsControlCommentsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/control-comments/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlCommentRequestToJSON(requestParameters['controlCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlCommentFromJSON(jsonValue));
    }

    /**
     */
    async controlsControlCommentsCreate(requestParameters: ControlsControlCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlComment> {
        const response = await this.controlsControlCommentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsControlCommentsDestroyRaw(requestParameters: ControlsControlCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsControlCommentsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/control-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async controlsControlCommentsDestroy(requestParameters: ControlsControlCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.controlsControlCommentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async controlsControlCommentsListRaw(requestParameters: ControlsControlCommentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedControlCommentList>> {
        const queryParameters: any = {};

        if (requestParameters['control'] != null) {
            queryParameters['control'] = requestParameters['control'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/control-comments/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedControlCommentListFromJSON(jsonValue));
    }

    /**
     */
    async controlsControlCommentsList(requestParameters: ControlsControlCommentsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedControlCommentList> {
        const response = await this.controlsControlCommentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsControlCommentsPartialUpdateRaw(requestParameters: ControlsControlCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsControlCommentsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/control-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedControlCommentRequestToJSON(requestParameters['patchedControlCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlCommentFromJSON(jsonValue));
    }

    /**
     */
    async controlsControlCommentsPartialUpdate(requestParameters: ControlsControlCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlComment> {
        const response = await this.controlsControlCommentsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsControlCommentsRetrieveRaw(requestParameters: ControlsControlCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsControlCommentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/control-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlCommentFromJSON(jsonValue));
    }

    /**
     */
    async controlsControlCommentsRetrieve(requestParameters: ControlsControlCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlComment> {
        const response = await this.controlsControlCommentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsControlCommentsUpdateRaw(requestParameters: ControlsControlCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsControlCommentsUpdate().'
            );
        }

        if (requestParameters['controlCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'controlCommentRequest',
                'Required parameter "controlCommentRequest" was null or undefined when calling controlsControlCommentsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/control-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlCommentRequestToJSON(requestParameters['controlCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlCommentFromJSON(jsonValue));
    }

    /**
     */
    async controlsControlCommentsUpdate(requestParameters: ControlsControlCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlComment> {
        const response = await this.controlsControlCommentsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsControlsLibrariesListRaw(requestParameters: ControlsControlsLibrariesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedControlsLibraryList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsControlsLibrariesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['controlArea'] != null) {
            queryParameters['control_area'] = requestParameters['controlArea'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/controls-libraries/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedControlsLibraryListFromJSON(jsonValue));
    }

    /**
     */
    async controlsControlsLibrariesList(requestParameters: ControlsControlsLibrariesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedControlsLibraryList> {
        const response = await this.controlsControlsLibrariesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsControlsLibrariesRetrieveRaw(requestParameters: ControlsControlsLibrariesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlsLibrary>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsControlsLibrariesRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsControlsLibrariesRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/controls-libraries/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlsLibraryFromJSON(jsonValue));
    }

    /**
     */
    async controlsControlsLibrariesRetrieve(requestParameters: ControlsControlsLibrariesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlsLibrary> {
        const response = await this.controlsControlsLibrariesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsCreateRaw(requestParameters: ControlsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlRequest>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsCreate().'
            );
        }

        if (requestParameters['controlRequestRequest'] == null) {
            throw new runtime.RequiredError(
                'controlRequestRequest',
                'Required parameter "controlRequestRequest" was null or undefined when calling controlsCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlRequestRequestToJSON(requestParameters['controlRequestRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlRequestFromJSON(jsonValue));
    }

    /**
     */
    async controlsCreate(requestParameters: ControlsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlRequest> {
        const response = await this.controlsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsDestroyRaw(requestParameters: ControlsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async controlsDestroy(requestParameters: ControlsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.controlsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Disconnect a risk from a control
     */
    async controlsDisconnectRiskFromControlCreateRaw(requestParameters: ControlsDisconnectRiskFromControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsDisconnectRiskFromControlCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsDisconnectRiskFromControlCreate().'
            );
        }

        if (requestParameters['riskControlDeleteRequest'] == null) {
            throw new runtime.RequiredError(
                'riskControlDeleteRequest',
                'Required parameter "riskControlDeleteRequest" was null or undefined when calling controlsDisconnectRiskFromControlCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/{id}/disconnect_risk_from_control/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RiskControlDeleteRequestToJSON(requestParameters['riskControlDeleteRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Disconnect a risk from a control
     */
    async controlsDisconnectRiskFromControlCreate(requestParameters: ControlsDisconnectRiskFromControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.controlsDisconnectRiskFromControlCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Disconnect a task from a control.
     */
    async controlsDisconnectTaskFromControlCreateRaw(requestParameters: ControlsDisconnectTaskFromControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsDisconnectTaskFromControlCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsDisconnectTaskFromControlCreate().'
            );
        }

        if (requestParameters['taskConnectionRequest'] == null) {
            throw new runtime.RequiredError(
                'taskConnectionRequest',
                'Required parameter "taskConnectionRequest" was null or undefined when calling controlsDisconnectTaskFromControlCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/{id}/disconnect_task_from_control/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskConnectionRequestToJSON(requestParameters['taskConnectionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Disconnect a task from a control.
     */
    async controlsDisconnectTaskFromControlCreate(requestParameters: ControlsDisconnectTaskFromControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.controlsDisconnectTaskFromControlCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async controlsEvidenceCommentsCreateRaw(requestParameters: ControlsEvidenceCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EvidenceComment>> {
        if (requestParameters['evidenceCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'evidenceCommentRequest',
                'Required parameter "evidenceCommentRequest" was null or undefined when calling controlsEvidenceCommentsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-comments/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EvidenceCommentRequestToJSON(requestParameters['evidenceCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EvidenceCommentFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidenceCommentsCreate(requestParameters: ControlsEvidenceCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EvidenceComment> {
        const response = await this.controlsEvidenceCommentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidenceCommentsDestroyRaw(requestParameters: ControlsEvidenceCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidenceCommentsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async controlsEvidenceCommentsDestroy(requestParameters: ControlsEvidenceCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.controlsEvidenceCommentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async controlsEvidenceCommentsListRaw(requestParameters: ControlsEvidenceCommentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedEvidenceCommentList>> {
        const queryParameters: any = {};

        if (requestParameters['evidence'] != null) {
            queryParameters['evidence'] = requestParameters['evidence'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-comments/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedEvidenceCommentListFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidenceCommentsList(requestParameters: ControlsEvidenceCommentsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedEvidenceCommentList> {
        const response = await this.controlsEvidenceCommentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidenceCommentsPartialUpdateRaw(requestParameters: ControlsEvidenceCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EvidenceComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidenceCommentsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedEvidenceCommentRequestToJSON(requestParameters['patchedEvidenceCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EvidenceCommentFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidenceCommentsPartialUpdate(requestParameters: ControlsEvidenceCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EvidenceComment> {
        const response = await this.controlsEvidenceCommentsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidenceCommentsRetrieveRaw(requestParameters: ControlsEvidenceCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EvidenceComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidenceCommentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EvidenceCommentFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidenceCommentsRetrieve(requestParameters: ControlsEvidenceCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EvidenceComment> {
        const response = await this.controlsEvidenceCommentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidenceCommentsUpdateRaw(requestParameters: ControlsEvidenceCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EvidenceComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidenceCommentsUpdate().'
            );
        }

        if (requestParameters['evidenceCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'evidenceCommentRequest',
                'Required parameter "evidenceCommentRequest" was null or undefined when calling controlsEvidenceCommentsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EvidenceCommentRequestToJSON(requestParameters['evidenceCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EvidenceCommentFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidenceCommentsUpdate(requestParameters: ControlsEvidenceCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EvidenceComment> {
        const response = await this.controlsEvidenceCommentsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidenceRecordCreateRaw(requestParameters: ControlsEvidenceRecordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EvidenceRecord>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidenceRecordCreate().'
            );
        }

        if (requestParameters['evidenceRecordRequest'] == null) {
            throw new runtime.RequiredError(
                'evidenceRecordRequest',
                'Required parameter "evidenceRecordRequest" was null or undefined when calling controlsEvidenceRecordCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-record/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EvidenceRecordRequestToJSON(requestParameters['evidenceRecordRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EvidenceRecordFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidenceRecordCreate(requestParameters: ControlsEvidenceRecordCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EvidenceRecord> {
        const response = await this.controlsEvidenceRecordCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidenceRecordDestroyRaw(requestParameters: ControlsEvidenceRecordDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidenceRecordDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidenceRecordDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['control'] != null) {
            queryParameters['control'] = requestParameters['control'];
        }

        if (requestParameters['evidence'] != null) {
            queryParameters['evidence'] = requestParameters['evidence'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-record/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async controlsEvidenceRecordDestroy(requestParameters: ControlsEvidenceRecordDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.controlsEvidenceRecordDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async controlsEvidenceRecordListRaw(requestParameters: ControlsEvidenceRecordListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedEvidenceRecordList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidenceRecordList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['control'] != null) {
            queryParameters['control'] = requestParameters['control'];
        }

        if (requestParameters['evidence'] != null) {
            queryParameters['evidence'] = requestParameters['evidence'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-record/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedEvidenceRecordListFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidenceRecordList(requestParameters: ControlsEvidenceRecordListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedEvidenceRecordList> {
        const response = await this.controlsEvidenceRecordListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidenceRecordRetrieveRaw(requestParameters: ControlsEvidenceRecordRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EvidenceRecord>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidenceRecordRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidenceRecordRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['evidence'] != null) {
            queryParameters['evidence'] = requestParameters['evidence'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-record/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EvidenceRecordFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidenceRecordRetrieve(requestParameters: ControlsEvidenceRecordRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EvidenceRecord> {
        const response = await this.controlsEvidenceRecordRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidenceReviewFrequenciesMarkReviewedUpdateRaw(requestParameters: ControlsEvidenceReviewFrequenciesMarkReviewedUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewFrequency>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidenceReviewFrequenciesMarkReviewedUpdate().'
            );
        }

        if (requestParameters['evidence'] == null) {
            throw new runtime.RequiredError(
                'evidence',
                'Required parameter "evidence" was null or undefined when calling controlsEvidenceReviewFrequenciesMarkReviewedUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidenceReviewFrequenciesMarkReviewedUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['evidence'] != null) {
            queryParameters['evidence'] = requestParameters['evidence'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-review-frequencies/{id}/mark_reviewed/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewFrequencyRequestToJSON(requestParameters['reviewFrequencyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFrequencyFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidenceReviewFrequenciesMarkReviewedUpdate(requestParameters: ControlsEvidenceReviewFrequenciesMarkReviewedUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewFrequency> {
        const response = await this.controlsEvidenceReviewFrequenciesMarkReviewedUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidenceReviewFrequenciesPartialUpdateRaw(requestParameters: ControlsEvidenceReviewFrequenciesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewFrequency>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidenceReviewFrequenciesPartialUpdate().'
            );
        }

        if (requestParameters['evidence'] == null) {
            throw new runtime.RequiredError(
                'evidence',
                'Required parameter "evidence" was null or undefined when calling controlsEvidenceReviewFrequenciesPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidenceReviewFrequenciesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['evidence'] != null) {
            queryParameters['evidence'] = requestParameters['evidence'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-review-frequencies/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedReviewFrequencyRequestToJSON(requestParameters['patchedReviewFrequencyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFrequencyFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidenceReviewFrequenciesPartialUpdate(requestParameters: ControlsEvidenceReviewFrequenciesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewFrequency> {
        const response = await this.controlsEvidenceReviewFrequenciesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidenceReviewFrequenciesUpdateRaw(requestParameters: ControlsEvidenceReviewFrequenciesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewFrequency>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidenceReviewFrequenciesUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidenceReviewFrequenciesUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidence-review-frequencies/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewFrequencyRequestToJSON(requestParameters['reviewFrequencyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFrequencyFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidenceReviewFrequenciesUpdate(requestParameters: ControlsEvidenceReviewFrequenciesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewFrequency> {
        const response = await this.controlsEvidenceReviewFrequenciesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidencesAddToControlCreateRaw(requestParameters: ControlsEvidencesAddToControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidencesAddToControlCreate().'
            );
        }

        if (requestParameters['control'] == null) {
            throw new runtime.RequiredError(
                'control',
                'Required parameter "control" was null or undefined when calling controlsEvidencesAddToControlCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidencesAddToControlCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['control'] != null) {
            queryParameters['control'] = requestParameters['control'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidences/{id}/add-to-control/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async controlsEvidencesAddToControlCreate(requestParameters: ControlsEvidencesAddToControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.controlsEvidencesAddToControlCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Get all controls associated with this evidence.
     */
    async controlsEvidencesControlsListRaw(requestParameters: ControlsEvidencesControlsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedControlResponseList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidencesControlsList().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidencesControlsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['control'] != null) {
            queryParameters['control'] = requestParameters['control'];
        }

        if (requestParameters['controlExclude'] != null) {
            queryParameters['control_exclude'] = requestParameters['controlExclude'];
        }

        if (requestParameters['controls'] != null) {
            queryParameters['controls'] = requestParameters['controls']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['query'] != null) {
            queryParameters['query'] = requestParameters['query'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['statusIn'] != null) {
            queryParameters['status__in'] = requestParameters['statusIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['updatedAtAfter'] != null) {
            queryParameters['updated_at_after'] = (requestParameters['updatedAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['updatedAtBefore'] != null) {
            queryParameters['updated_at_before'] = (requestParameters['updatedAtBefore'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidences/{id}/controls/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedControlResponseListFromJSON(jsonValue));
    }

    /**
     * Get all controls associated with this evidence.
     */
    async controlsEvidencesControlsList(requestParameters: ControlsEvidencesControlsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedControlResponseList> {
        const response = await this.controlsEvidencesControlsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidencesCreateRaw(requestParameters: ControlsEvidencesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EvidenceResponse>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidencesCreate().'
            );
        }

        if (requestParameters['evidenceRequestRequest'] == null) {
            throw new runtime.RequiredError(
                'evidenceRequestRequest',
                'Required parameter "evidenceRequestRequest" was null or undefined when calling controlsEvidencesCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidences/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EvidenceRequestRequestToJSON(requestParameters['evidenceRequestRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EvidenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidencesCreate(requestParameters: ControlsEvidencesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EvidenceResponse> {
        const response = await this.controlsEvidencesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidencesDestroyRaw(requestParameters: ControlsEvidencesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidencesDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidencesDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidences/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async controlsEvidencesDestroy(requestParameters: ControlsEvidencesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.controlsEvidencesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async controlsEvidencesListRaw(requestParameters: ControlsEvidencesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedEvidenceResponseList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidencesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['control'] != null) {
            queryParameters['control'] = requestParameters['control'];
        }

        if (requestParameters['controlExclude'] != null) {
            queryParameters['control_exclude'] = requestParameters['controlExclude'];
        }

        if (requestParameters['controls'] != null) {
            queryParameters['controls'] = requestParameters['controls']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['query'] != null) {
            queryParameters['query'] = requestParameters['query'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['statusIn'] != null) {
            queryParameters['status__in'] = requestParameters['statusIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['updatedAtAfter'] != null) {
            queryParameters['updated_at_after'] = (requestParameters['updatedAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['updatedAtBefore'] != null) {
            queryParameters['updated_at_before'] = (requestParameters['updatedAtBefore'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidences/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedEvidenceResponseListFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidencesList(requestParameters: ControlsEvidencesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedEvidenceResponseList> {
        const response = await this.controlsEvidencesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidencesPartialUpdateRaw(requestParameters: ControlsEvidencesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EvidenceResponse>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidencesPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidencesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidences/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedEvidenceRequestRequestToJSON(requestParameters['patchedEvidenceRequestRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EvidenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidencesPartialUpdate(requestParameters: ControlsEvidencesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EvidenceResponse> {
        const response = await this.controlsEvidencesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidencesRemoveFromControlCreateRaw(requestParameters: ControlsEvidencesRemoveFromControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidencesRemoveFromControlCreate().'
            );
        }

        if (requestParameters['control'] == null) {
            throw new runtime.RequiredError(
                'control',
                'Required parameter "control" was null or undefined when calling controlsEvidencesRemoveFromControlCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidencesRemoveFromControlCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['control'] != null) {
            queryParameters['control'] = requestParameters['control'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidences/{id}/remove-from-control/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async controlsEvidencesRemoveFromControlCreate(requestParameters: ControlsEvidencesRemoveFromControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.controlsEvidencesRemoveFromControlCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async controlsEvidencesRetrieveRaw(requestParameters: ControlsEvidencesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EvidenceResponse>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidencesRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidencesRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidences/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EvidenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidencesRetrieve(requestParameters: ControlsEvidencesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EvidenceResponse> {
        const response = await this.controlsEvidencesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsEvidencesUpdateRaw(requestParameters: ControlsEvidencesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EvidenceResponse>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsEvidencesUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsEvidencesUpdate().'
            );
        }

        if (requestParameters['evidenceRequestRequest'] == null) {
            throw new runtime.RequiredError(
                'evidenceRequestRequest',
                'Required parameter "evidenceRequestRequest" was null or undefined when calling controlsEvidencesUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/evidences/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EvidenceRequestRequestToJSON(requestParameters['evidenceRequestRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EvidenceResponseFromJSON(jsonValue));
    }

    /**
     */
    async controlsEvidencesUpdate(requestParameters: ControlsEvidencesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EvidenceResponse> {
        const response = await this.controlsEvidencesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsExportRetrieveRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/export/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlResponseFromJSON(jsonValue));
    }

    /**
     */
    async controlsExportRetrieve(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlResponse> {
        const response = await this.controlsExportRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsListRaw(requestParameters: ControlsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedControlResponseList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['applicabilityTo'] != null) {
            queryParameters['applicability_to'] = requestParameters['applicabilityTo']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvalBy'] != null) {
            queryParameters['approval_by'] = requestParameters['approvalBy']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvalStatus'] != null) {
            queryParameters['approval_status'] = requestParameters['approvalStatus']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['controlArea'] != null) {
            queryParameters['control_area'] = requestParameters['controlArea']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['controlType'] != null) {
            queryParameters['control_type'] = requestParameters['controlType']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['controlsSort'] != null) {
            queryParameters['controls_sort'] = requestParameters['controlsSort'];
        }

        if (requestParameters['createdAt'] != null) {
            queryParameters['created_at'] = (requestParameters['createdAt'] as any).toISOString();
        }

        if (requestParameters['createdBy'] != null) {
            queryParameters['created_by'] = requestParameters['createdBy'];
        }

        if (requestParameters['customId'] != null) {
            queryParameters['custom_id'] = requestParameters['customId'];
        }

        if (requestParameters['decisionDetails'] != null) {
            queryParameters['decision_details'] = requestParameters['decisionDetails'];
        }

        if (requestParameters['description'] != null) {
            queryParameters['description'] = requestParameters['description'];
        }

        if (requestParameters['evidences'] != null) {
            queryParameters['evidences'] = requestParameters['evidences'];
        }

        if (requestParameters['executionFrequency'] != null) {
            queryParameters['execution_frequency'] = requestParameters['executionFrequency']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['executionType'] != null) {
            queryParameters['execution_type'] = requestParameters['executionType']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['implementedAtAfter'] != null) {
            queryParameters['implemented_at_after'] = (requestParameters['implementedAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['implementedAtBefore'] != null) {
            queryParameters['implemented_at_before'] = (requestParameters['implementedAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['importanceScore'] != null) {
            queryParameters['importance_score'] = requestParameters['importanceScore'];
        }

        if (requestParameters['importanceScoreGt'] != null) {
            queryParameters['importance_score_gt'] = requestParameters['importanceScoreGt'];
        }

        if (requestParameters['importanceScoreLt'] != null) {
            queryParameters['importance_score_lt'] = requestParameters['importanceScoreLt'];
        }

        if (requestParameters['importanceScoreRangeMax'] != null) {
            queryParameters['importance_score_range_max'] = requestParameters['importanceScoreRangeMax'];
        }

        if (requestParameters['importanceScoreRangeMin'] != null) {
            queryParameters['importance_score_range_min'] = requestParameters['importanceScoreRangeMin'];
        }

        if (requestParameters['isAiGenerated'] != null) {
            queryParameters['is_ai_generated'] = requestParameters['isAiGenerated'];
        }

        if (requestParameters['keywordsAnd'] != null) {
            queryParameters['keywords_and'] = requestParameters['keywordsAnd'];
        }

        if (requestParameters['keywordsNot'] != null) {
            queryParameters['keywords_not'] = requestParameters['keywordsNot'];
        }

        if (requestParameters['keywordsOr'] != null) {
            queryParameters['keywords_or'] = requestParameters['keywordsOr'];
        }

        if (requestParameters['library'] != null) {
            queryParameters['library'] = requestParameters['library'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nextReviewAtAfter'] != null) {
            queryParameters['next_review_at_after'] = (requestParameters['nextReviewAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['nextReviewAtBefore'] != null) {
            queryParameters['next_review_at_before'] = (requestParameters['nextReviewAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['objectIsPublic'] != null) {
            queryParameters['object_is_public'] = requestParameters['objectIsPublic'];
        }

        if (requestParameters['objectOwner'] != null) {
            queryParameters['object_owner'] = requestParameters['objectOwner']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['obligations'] != null) {
            queryParameters['obligations'] = requestParameters['obligations'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['query'] != null) {
            queryParameters['query'] = requestParameters['query'];
        }

        if (requestParameters['questionnaires'] != null) {
            queryParameters['questionnaires'] = requestParameters['questionnaires'];
        }

        if (requestParameters['reviewFrequency'] != null) {
            queryParameters['review_frequency'] = requestParameters['reviewFrequency'];
        }

        if (requestParameters['riskLevel'] != null) {
            queryParameters['risk_level'] = requestParameters['riskLevel'];
        }

        if (requestParameters['risks'] != null) {
            queryParameters['risks'] = requestParameters['risks'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['tags'] != null) {
            queryParameters['tags'] = requestParameters['tags']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['updatedAtAfter'] != null) {
            queryParameters['updated_at_after'] = (requestParameters['updatedAtAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['updatedAtBefore'] != null) {
            queryParameters['updated_at_before'] = (requestParameters['updatedAtBefore'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['updatedBy'] != null) {
            queryParameters['updated_by'] = requestParameters['updatedBy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedControlResponseListFromJSON(jsonValue));
    }

    /**
     */
    async controlsList(requestParameters: ControlsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedControlResponseList> {
        const response = await this.controlsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsPartialUpdateRaw(requestParameters: ControlsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlResponse>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedControlRequestRequestToJSON(requestParameters['patchedControlRequestRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlResponseFromJSON(jsonValue));
    }

    /**
     */
    async controlsPartialUpdate(requestParameters: ControlsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlResponse> {
        const response = await this.controlsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Preview importance score calculation with a new risk-control relationship.
     */
    async controlsPreviewImportanceScoreCreateRaw(requestParameters: ControlsPreviewImportanceScoreCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlImportanceScore>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsPreviewImportanceScoreCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsPreviewImportanceScoreCreate().'
            );
        }

        if (requestParameters['riskControlRequest'] == null) {
            throw new runtime.RequiredError(
                'riskControlRequest',
                'Required parameter "riskControlRequest" was null or undefined when calling controlsPreviewImportanceScoreCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/{id}/preview_importance_score/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['riskControlRequest']!.map(RiskControlRequestToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlImportanceScoreFromJSON(jsonValue));
    }

    /**
     * Preview importance score calculation with a new risk-control relationship.
     */
    async controlsPreviewImportanceScoreCreate(requestParameters: ControlsPreviewImportanceScoreCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlImportanceScore> {
        const response = await this.controlsPreviewImportanceScoreCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsPreviewRiskControlCreateRaw(requestParameters: ControlsPreviewRiskControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskCalculationResult>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsPreviewRiskControlCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsPreviewRiskControlCreate().'
            );
        }

        if (requestParameters['riskControlRequest'] == null) {
            throw new runtime.RequiredError(
                'riskControlRequest',
                'Required parameter "riskControlRequest" was null or undefined when calling controlsPreviewRiskControlCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/{id}/preview_risk_control/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RiskControlRequestToJSON(requestParameters['riskControlRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskCalculationResultFromJSON(jsonValue));
    }

    /**
     */
    async controlsPreviewRiskControlCreate(requestParameters: ControlsPreviewRiskControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskCalculationResult> {
        const response = await this.controlsPreviewRiskControlCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsRetrieveRaw(requestParameters: ControlsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlResponse>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlResponseFromJSON(jsonValue));
    }

    /**
     */
    async controlsRetrieve(requestParameters: ControlsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlResponse> {
        const response = await this.controlsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsReviewFrequenciesMarkReviewedUpdateRaw(requestParameters: ControlsReviewFrequenciesMarkReviewedUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewFrequency>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsReviewFrequenciesMarkReviewedUpdate().'
            );
        }

        if (requestParameters['control'] == null) {
            throw new runtime.RequiredError(
                'control',
                'Required parameter "control" was null or undefined when calling controlsReviewFrequenciesMarkReviewedUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsReviewFrequenciesMarkReviewedUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['control'] != null) {
            queryParameters['control'] = requestParameters['control'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/review-frequencies/{id}/mark_reviewed/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewFrequencyRequestToJSON(requestParameters['reviewFrequencyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFrequencyFromJSON(jsonValue));
    }

    /**
     */
    async controlsReviewFrequenciesMarkReviewedUpdate(requestParameters: ControlsReviewFrequenciesMarkReviewedUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewFrequency> {
        const response = await this.controlsReviewFrequenciesMarkReviewedUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsReviewFrequenciesPartialUpdateRaw(requestParameters: ControlsReviewFrequenciesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewFrequency>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsReviewFrequenciesPartialUpdate().'
            );
        }

        if (requestParameters['control'] == null) {
            throw new runtime.RequiredError(
                'control',
                'Required parameter "control" was null or undefined when calling controlsReviewFrequenciesPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsReviewFrequenciesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['control'] != null) {
            queryParameters['control'] = requestParameters['control'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/review-frequencies/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedReviewFrequencyRequestToJSON(requestParameters['patchedReviewFrequencyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFrequencyFromJSON(jsonValue));
    }

    /**
     */
    async controlsReviewFrequenciesPartialUpdate(requestParameters: ControlsReviewFrequenciesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewFrequency> {
        const response = await this.controlsReviewFrequenciesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsReviewFrequenciesUpdateRaw(requestParameters: ControlsReviewFrequenciesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ReviewFrequency>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsReviewFrequenciesUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsReviewFrequenciesUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/review-frequencies/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReviewFrequencyRequestToJSON(requestParameters['reviewFrequencyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ReviewFrequencyFromJSON(jsonValue));
    }

    /**
     */
    async controlsReviewFrequenciesUpdate(requestParameters: ControlsReviewFrequenciesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ReviewFrequency> {
        const response = await this.controlsReviewFrequenciesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get tasks for a control.
     */
    async controlsTasksRetrieveRaw(requestParameters: ControlsTasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlTasks>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsTasksRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsTasksRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/{id}/tasks/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlTasksFromJSON(jsonValue));
    }

    /**
     * Get tasks for a control.
     */
    async controlsTasksRetrieve(requestParameters: ControlsTasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlTasks> {
        const response = await this.controlsTasksRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async controlsUpdateRaw(requestParameters: ControlsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlRequest>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling controlsUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling controlsUpdate().'
            );
        }

        if (requestParameters['controlRequestRequest'] == null) {
            throw new runtime.RequiredError(
                'controlRequestRequest',
                'Required parameter "controlRequestRequest" was null or undefined when calling controlsUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/controls/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlRequestRequestToJSON(requestParameters['controlRequestRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlRequestFromJSON(jsonValue));
    }

    /**
     */
    async controlsUpdate(requestParameters: ControlsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlRequest> {
        const response = await this.controlsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ControlsControlCommentsListStatusEnum = {
    Open: 'open',
    Resolved: 'resolved'
} as const;
export type ControlsControlCommentsListStatusEnum = typeof ControlsControlCommentsListStatusEnum[keyof typeof ControlsControlCommentsListStatusEnum];
/**
 * @export
 */
export const ControlsEvidenceCommentsListStatusEnum = {
    Open: 'open',
    Resolved: 'resolved'
} as const;
export type ControlsEvidenceCommentsListStatusEnum = typeof ControlsEvidenceCommentsListStatusEnum[keyof typeof ControlsEvidenceCommentsListStatusEnum];
/**
 * @export
 */
export const ControlsEvidencesControlsListStatusEnum = {
    Inactive: 'INACTIVE',
    NeedsReview: 'NEEDS_REVIEW',
    UpToDate: 'UP_TO_DATE'
} as const;
export type ControlsEvidencesControlsListStatusEnum = typeof ControlsEvidencesControlsListStatusEnum[keyof typeof ControlsEvidencesControlsListStatusEnum];
/**
 * @export
 */
export const ControlsEvidencesListStatusEnum = {
    Inactive: 'INACTIVE',
    NeedsReview: 'NEEDS_REVIEW',
    UpToDate: 'UP_TO_DATE'
} as const;
export type ControlsEvidencesListStatusEnum = typeof ControlsEvidencesListStatusEnum[keyof typeof ControlsEvidencesListStatusEnum];
/**
 * @export
 */
export const ControlsListApplicabilityToEnum = {
    Data: 'DATA',
    Facility: 'FACILITY',
    People: 'PEOPLE',
    Process: 'PROCESS',
    Technology: 'TECHNOLOGY'
} as const;
export type ControlsListApplicabilityToEnum = typeof ControlsListApplicabilityToEnum[keyof typeof ControlsListApplicabilityToEnum];
/**
 * @export
 */
export const ControlsListApprovalStatusEnum = {
    Approved: 'APPROVED',
    NotApproved: 'NOT_APPROVED',
    Pending: 'PENDING'
} as const;
export type ControlsListApprovalStatusEnum = typeof ControlsListApprovalStatusEnum[keyof typeof ControlsListApprovalStatusEnum];
/**
 * @export
 */
export const ControlsListControlAreaEnum = {
    ArtificialTech: 'ARTIFICIAL_TECH',
    AssetManagement: 'ASSET_MANAGEMENT',
    BusinessContinuity: 'BUSINESS_CONTINUITY',
    CapacityPlanning: 'CAPACITY_PLANNING',
    ChangeManagement: 'CHANGE_MANAGEMENT',
    CloudSecurity: 'CLOUD_SECURITY',
    Compliance: 'COMPLIANCE',
    ConfigurationManagement: 'CONFIGURATION_MANAGEMENT',
    ContinuousMonitoring: 'CONTINUOUS_MONITORING',
    CryptographicProtections: 'CRYPTOGRAPHIC_PROTECTIONS',
    CybersecurityDataPrivacyGovernance: 'CYBERSECURITY_DATA_PRIVACY_GOVERNANCE',
    DataClassificationHandling: 'DATA_CLASSIFICATION_HANDLING',
    DataPrivacy: 'DATA_PRIVACY',
    EmbeddedTechnology: 'EMBEDDED_TECHNOLOGY',
    EndpointSecurity: 'ENDPOINT_SECURITY',
    HumanResourcesSecurity: 'HUMAN_RESOURCES_SECURITY',
    IdentificationAuthentication: 'IDENTIFICATION_AUTHENTICATION',
    IncidentResponse: 'INCIDENT_RESPONSE',
    InformationAssurance: 'INFORMATION_ASSURANCE',
    Maintenance: 'MAINTENANCE',
    MobileDeviceManagement: 'MOBILE_DEVICE_MANAGEMENT',
    NetworkSecurity: 'NETWORK_SECURITY',
    PhysicalEnvironmentalSecurity: 'PHYSICAL_ENVIRONMENTAL_SECURITY',
    ProjectResourceManagement: 'PROJECT_RESOURCE_MANAGEMENT',
    RiskManagement: 'RISK_MANAGEMENT',
    SecureEngineeringArchitecture: 'SECURE_ENGINEERING_ARCHITECTURE',
    SecurityAwarenessTraining: 'SECURITY_AWARENESS_TRAINING',
    SecurityOperations: 'SECURITY_OPERATIONS',
    TechnologyDevelopmentAcquisition: 'TECHNOLOGY_DEVELOPMENT_ACQUISITION',
    ThirdPartyManagement: 'THIRD_PARTY_MANAGEMENT',
    ThreatManagement: 'THREAT_MANAGEMENT',
    VulnerabilityPatchManagement: 'VULNERABILITY_PATCH_MANAGEMENT',
    WebSecurity: 'WEB_SECURITY'
} as const;
export type ControlsListControlAreaEnum = typeof ControlsListControlAreaEnum[keyof typeof ControlsListControlAreaEnum];
/**
 * @export
 */
export const ControlsListControlTypeEnum = {
    Corrective: 'CORRECTIVE',
    Detective: 'DETECTIVE',
    Preventive: 'PREVENTIVE'
} as const;
export type ControlsListControlTypeEnum = typeof ControlsListControlTypeEnum[keyof typeof ControlsListControlTypeEnum];
/**
 * @export
 */
export const ControlsListExecutionFrequencyEnum = {
    Annually: 'ANNUALLY',
    Continuous: 'CONTINUOUS',
    Daily: 'DAILY',
    Monthly: 'MONTHLY',
    OneOff: 'ONE_OFF',
    Quarterly: 'QUARTERLY',
    SemiAnnually: 'SEMI_ANNUALLY',
    Weekly: 'WEEKLY'
} as const;
export type ControlsListExecutionFrequencyEnum = typeof ControlsListExecutionFrequencyEnum[keyof typeof ControlsListExecutionFrequencyEnum];
/**
 * @export
 */
export const ControlsListExecutionTypeEnum = {
    Automatic: 'AUTOMATIC',
    Manual: 'MANUAL',
    SemiAutomatic: 'SEMI_AUTOMATIC'
} as const;
export type ControlsListExecutionTypeEnum = typeof ControlsListExecutionTypeEnum[keyof typeof ControlsListExecutionTypeEnum];
/**
 * @export
 */
export const ControlsListRiskLevelEnum = {
    High: 'HIGH',
    Low: 'LOW',
    Moderate: 'MODERATE',
    Severe: 'SEVERE',
    Significant: 'SIGNIFICANT'
} as const;
export type ControlsListRiskLevelEnum = typeof ControlsListRiskLevelEnum[keyof typeof ControlsListRiskLevelEnum];
/**
 * @export
 */
export const ControlsListStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    InProgress: 'IN_PROGRESS',
    NeedsAttention: 'NEEDS_ATTENTION'
} as const;
export type ControlsListStatusEnum = typeof ControlsListStatusEnum[keyof typeof ControlsListStatusEnum];
