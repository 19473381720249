/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `PUBLIC` - Public Library
 * * `PRIVATE` - Private Library
 * @export
 */
export const ControlsLibraryTypeEnum = {
    Public: 'PUBLIC',
    Private: 'PRIVATE'
} as const;
export type ControlsLibraryTypeEnum = typeof ControlsLibraryTypeEnum[keyof typeof ControlsLibraryTypeEnum];


export function instanceOfControlsLibraryTypeEnum(value: any): boolean {
    for (const key in ControlsLibraryTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(ControlsLibraryTypeEnum, key)) {
            if (ControlsLibraryTypeEnum[key as keyof typeof ControlsLibraryTypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ControlsLibraryTypeEnumFromJSON(json: any): ControlsLibraryTypeEnum {
    return ControlsLibraryTypeEnumFromJSONTyped(json, false);
}

export function ControlsLibraryTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlsLibraryTypeEnum {
    return json as ControlsLibraryTypeEnum;
}

export function ControlsLibraryTypeEnumToJSON(value?: ControlsLibraryTypeEnum | null): any {
    return value as any;
}

