/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RiskControlRead
 */
export interface RiskControlRead {
    /**
     * 
     * @type {number}
     * @memberof RiskControlRead
     */
    riskId: number;
    /**
     * 
     * @type {number}
     * @memberof RiskControlRead
     */
    registerId: number;
    /**
     * 
     * @type {string}
     * @memberof RiskControlRead
     */
    riskName: string;
    /**
     * Percentage reduction in impact (0-100)
     * @type {string}
     * @memberof RiskControlRead
     */
    impactReduction?: string | null;
    /**
     * Percentage reduction in likelihood (0-100)
     * @type {string}
     * @memberof RiskControlRead
     */
    likelihoodReduction?: string | null;
}

/**
 * Check if a given object implements the RiskControlRead interface.
 */
export function instanceOfRiskControlRead(value: object): value is RiskControlRead {
    if (!('riskId' in value) || value['riskId'] === undefined) return false;
    if (!('registerId' in value) || value['registerId'] === undefined) return false;
    if (!('riskName' in value) || value['riskName'] === undefined) return false;
    return true;
}

export function RiskControlReadFromJSON(json: any): RiskControlRead {
    return RiskControlReadFromJSONTyped(json, false);
}

export function RiskControlReadFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskControlRead {
    if (json == null) {
        return json;
    }
    return {
        
        'riskId': json['risk_id'],
        'registerId': json['register_id'],
        'riskName': json['risk_name'],
        'impactReduction': json['impact_reduction'] == null ? undefined : json['impact_reduction'],
        'likelihoodReduction': json['likelihood_reduction'] == null ? undefined : json['likelihood_reduction'],
    };
}

export function RiskControlReadToJSON(value?: RiskControlRead | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'risk_id': value['riskId'],
        'register_id': value['registerId'],
        'risk_name': value['riskName'],
        'impact_reduction': value['impactReduction'],
        'likelihood_reduction': value['likelihoodReduction'],
    };
}

