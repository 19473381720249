import { useCompany } from "shared/context/CompanyProvider";

export const Assignees = ({ users }: { users: number[] }) => {
  const { usersList } = useCompany();
  const assignees = usersList.filter((e) => users.includes(e.user.id));

  if (assignees.length === 0) return null;

  if (assignees.length === 1) {
    const { user } = assignees[0];
    return (
      <div className="flex items-center gap-2 rounded-md border border-gray-200 px-1.5 py-0.5">
        <img src={user.profile_picture ?? ""} alt="" className="h-4 w-4 rounded-full" />
        <p className="text-xs font-medium text-gray-800 dark:text-gray-400">
          {user.name || user.email}
        </p>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-[-4px] rounded-md border border-gray-200 px-1.5 py-0.5">
      {assignees.map((e, idx) => {
        const { user } = e;
        return (
          <div key={user.id} className={`${idx !== 0 ? "-ml-1" : ""}`}>
            {user.profile_picture ? (
              <img
                src={user.profile_picture}
                alt=""
                className="h-4 w-4 rounded-full border border-white"
              />
            ) : (
              <p className="h-4 w-4 rounded-full border border-white bg-gray-300 text-center text-xs uppercase">
                {/* better to use Avatar component here */}
                {(user.name || user.email).slice(0, 1)}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};
