/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RiskCalculationResult
 */
export interface RiskCalculationResult {
    /**
     * 
     * @type {number}
     * @memberof RiskCalculationResult
     */
    residualImpact: number;
    /**
     * 
     * @type {number}
     * @memberof RiskCalculationResult
     */
    residualLikelihood: number;
    /**
     * 
     * @type {number}
     * @memberof RiskCalculationResult
     */
    residualRiskRating: number;
    /**
     * 
     * @type {number}
     * @memberof RiskCalculationResult
     */
    residualRiskIndex: number;
}

/**
 * Check if a given object implements the RiskCalculationResult interface.
 */
export function instanceOfRiskCalculationResult(value: object): value is RiskCalculationResult {
    if (!('residualImpact' in value) || value['residualImpact'] === undefined) return false;
    if (!('residualLikelihood' in value) || value['residualLikelihood'] === undefined) return false;
    if (!('residualRiskRating' in value) || value['residualRiskRating'] === undefined) return false;
    if (!('residualRiskIndex' in value) || value['residualRiskIndex'] === undefined) return false;
    return true;
}

export function RiskCalculationResultFromJSON(json: any): RiskCalculationResult {
    return RiskCalculationResultFromJSONTyped(json, false);
}

export function RiskCalculationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskCalculationResult {
    if (json == null) {
        return json;
    }
    return {
        
        'residualImpact': json['residual_impact'],
        'residualLikelihood': json['residual_likelihood'],
        'residualRiskRating': json['residual_risk_rating'],
        'residualRiskIndex': json['residual_risk_index'],
    };
}

export function RiskCalculationResultToJSON(value?: RiskCalculationResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'residual_impact': value['residualImpact'],
        'residual_likelihood': value['residualLikelihood'],
        'residual_risk_rating': value['residualRiskRating'],
        'residual_risk_index': value['residualRiskIndex'],
    };
}

