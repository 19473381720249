import { GetTaskResultType } from "shared/types";

export default function hasRelatedObject(task: GetTaskResultType | null): boolean {
  return !!(
    task?.agreement ||
    task?.policy ||
    task?.article ||
    task?.document ||
    task?.note ||
    task?.incident ||
    task?.mitigation ||
    task?.risk ||
    task?.directory ||
    task?.obligation ||
    task?.control
  );
}
