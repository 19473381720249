/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ControlRequestControlArea } from './ControlRequestControlArea';
import {
    ControlRequestControlAreaFromJSON,
    ControlRequestControlAreaFromJSONTyped,
    ControlRequestControlAreaToJSON,
} from './ControlRequestControlArea';
import type { EvidenceResponse } from './EvidenceResponse';
import {
    EvidenceResponseFromJSON,
    EvidenceResponseFromJSONTyped,
    EvidenceResponseToJSON,
} from './EvidenceResponse';
import type { ControlRequestApplicabilityTo } from './ControlRequestApplicabilityTo';
import {
    ControlRequestApplicabilityToFromJSON,
    ControlRequestApplicabilityToFromJSONTyped,
    ControlRequestApplicabilityToToJSON,
} from './ControlRequestApplicabilityTo';
import type { ControlStatusEnum } from './ControlStatusEnum';
import {
    ControlStatusEnumFromJSON,
    ControlStatusEnumFromJSONTyped,
    ControlStatusEnumToJSON,
} from './ControlStatusEnum';
import type { ControlRequestExecutionType } from './ControlRequestExecutionType';
import {
    ControlRequestExecutionTypeFromJSON,
    ControlRequestExecutionTypeFromJSONTyped,
    ControlRequestExecutionTypeToJSON,
} from './ControlRequestExecutionType';
import type { ReviewFrequency } from './ReviewFrequency';
import {
    ReviewFrequencyFromJSON,
    ReviewFrequencyFromJSONTyped,
    ReviewFrequencyToJSON,
} from './ReviewFrequency';
import type { ControlControlType } from './ControlControlType';
import {
    ControlControlTypeFromJSON,
    ControlControlTypeFromJSONTyped,
    ControlControlTypeToJSON,
} from './ControlControlType';
import type { ControlRequestApprovalStatus } from './ControlRequestApprovalStatus';
import {
    ControlRequestApprovalStatusFromJSON,
    ControlRequestApprovalStatusFromJSONTyped,
    ControlRequestApprovalStatusToJSON,
} from './ControlRequestApprovalStatus';
import type { ControlRequestExecutionFrequency } from './ControlRequestExecutionFrequency';
import {
    ControlRequestExecutionFrequencyFromJSON,
    ControlRequestExecutionFrequencyFromJSONTyped,
    ControlRequestExecutionFrequencyToJSON,
} from './ControlRequestExecutionFrequency';

/**
 * Adds nested create feature
 * @export
 * @interface ControlRequest
 */
export interface ControlRequest {
    /**
     * 
     * @type {number}
     * @memberof ControlRequest
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ControlRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlRequest
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ControlRequest
     */
    company: number;
    /**
     * 
     * @type {ControlRequestControlArea}
     * @memberof ControlRequest
     */
    controlArea?: ControlRequestControlArea | null;
    /**
     * 
     * @type {ControlControlType}
     * @memberof ControlRequest
     */
    controlType?: ControlControlType | null;
    /**
     * 
     * @type {ControlRequestExecutionType}
     * @memberof ControlRequest
     */
    executionType?: ControlRequestExecutionType | null;
    /**
     * 
     * @type {ReviewFrequency}
     * @memberof ControlRequest
     */
    reviewFrequency?: ReviewFrequency;
    /**
     * 
     * @type {ControlRequestExecutionFrequency}
     * @memberof ControlRequest
     */
    executionFrequency?: ControlRequestExecutionFrequency | null;
    /**
     * 
     * @type {ControlRequestApplicabilityTo}
     * @memberof ControlRequest
     */
    applicabilityTo?: ControlRequestApplicabilityTo | null;
    /**
     * 
     * @type {Date}
     * @memberof ControlRequest
     */
    implementedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ControlRequest
     */
    customId?: string | null;
    /**
     * 
     * @type {ControlRequestApprovalStatus}
     * @memberof ControlRequest
     */
    approvalStatus?: ControlRequestApprovalStatus | null;
    /**
     * 
     * @type {ControlStatusEnum}
     * @memberof ControlRequest
     */
    status?: ControlStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof ControlRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlRequest
     */
    approvalBy?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ControlRequest
     */
    decisionDetails?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ControlRequest
     */
    risks?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ControlRequest
     */
    obligations?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ControlRequest
     */
    questionnaires?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ControlRequest
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<EvidenceResponse>}
     * @memberof ControlRequest
     */
    evidences?: Array<EvidenceResponse>;
    /**
     * 
     * @type {Date}
     * @memberof ControlRequest
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ControlRequest
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof ControlRequest
     */
    readonly createdBy: number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlRequest
     */
    readonly updatedBy: number | null;
}



/**
 * Check if a given object implements the ControlRequest interface.
 */
export function instanceOfControlRequest(value: object): value is ControlRequest {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('company' in value) || value['company'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('createdBy' in value) || value['createdBy'] === undefined) return false;
    if (!('updatedBy' in value) || value['updatedBy'] === undefined) return false;
    return true;
}

export function ControlRequestFromJSON(json: any): ControlRequest {
    return ControlRequestFromJSONTyped(json, false);
}

export function ControlRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'company': json['company'],
        'controlArea': json['control_area'] == null ? undefined : ControlRequestControlAreaFromJSON(json['control_area']),
        'controlType': json['control_type'] == null ? undefined : ControlControlTypeFromJSON(json['control_type']),
        'executionType': json['execution_type'] == null ? undefined : ControlRequestExecutionTypeFromJSON(json['execution_type']),
        'reviewFrequency': json['review_frequency'] == null ? undefined : ReviewFrequencyFromJSON(json['review_frequency']),
        'executionFrequency': json['execution_frequency'] == null ? undefined : ControlRequestExecutionFrequencyFromJSON(json['execution_frequency']),
        'applicabilityTo': json['applicability_to'] == null ? undefined : ControlRequestApplicabilityToFromJSON(json['applicability_to']),
        'implementedAt': json['implemented_at'] == null ? undefined : (new Date(json['implemented_at'])),
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'approvalStatus': json['approval_status'] == null ? undefined : ControlRequestApprovalStatusFromJSON(json['approval_status']),
        'status': json['status'] == null ? undefined : ControlStatusEnumFromJSON(json['status']),
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'approvalBy': json['approval_by'] == null ? undefined : json['approval_by'],
        'decisionDetails': json['decision_details'] == null ? undefined : json['decision_details'],
        'risks': json['risks'] == null ? undefined : json['risks'],
        'obligations': json['obligations'] == null ? undefined : json['obligations'],
        'questionnaires': json['questionnaires'] == null ? undefined : json['questionnaires'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'evidences': json['evidences'] == null ? undefined : ((json['evidences'] as Array<any>).map(EvidenceResponseFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'createdBy': json['created_by'],
        'updatedBy': json['updated_by'],
    };
}

export function ControlRequestToJSON(value?: Omit<ControlRequest, 'id'|'created_at'|'updated_at'|'created_by'|'updated_by'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'company': value['company'],
        'control_area': ControlRequestControlAreaToJSON(value['controlArea']),
        'control_type': ControlControlTypeToJSON(value['controlType']),
        'execution_type': ControlRequestExecutionTypeToJSON(value['executionType']),
        'review_frequency': ReviewFrequencyToJSON(value['reviewFrequency']),
        'execution_frequency': ControlRequestExecutionFrequencyToJSON(value['executionFrequency']),
        'applicability_to': ControlRequestApplicabilityToToJSON(value['applicabilityTo']),
        'implemented_at': value['implementedAt'] == null ? undefined : ((value['implementedAt'] as any).toISOString().substring(0,10)),
        'custom_id': value['customId'],
        'approval_status': ControlRequestApprovalStatusToJSON(value['approvalStatus']),
        'status': ControlStatusEnumToJSON(value['status']),
        'object_owner': value['objectOwner'],
        'approval_by': value['approvalBy'],
        'decision_details': value['decisionDetails'],
        'risks': value['risks'],
        'obligations': value['obligations'],
        'questionnaires': value['questionnaires'],
        'tags': value['tags'],
        'evidences': value['evidences'] == null ? undefined : ((value['evidences'] as Array<any>).map(EvidenceResponseToJSON)),
    };
}

