/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ControlStatusEnum } from './ControlStatusEnum';
import {
    ControlStatusEnumFromJSON,
    ControlStatusEnumFromJSONTyped,
    ControlStatusEnumToJSON,
} from './ControlStatusEnum';

/**
 * 
 * @export
 * @interface ControlMini
 */
export interface ControlMini {
    /**
     * 
     * @type {number}
     * @memberof ControlMini
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ControlMini
     */
    name: string;
    /**
     * 
     * @type {ControlStatusEnum}
     * @memberof ControlMini
     */
    status?: ControlStatusEnum;
}



/**
 * Check if a given object implements the ControlMini interface.
 */
export function instanceOfControlMini(value: object): value is ControlMini {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ControlMiniFromJSON(json: any): ControlMini {
    return ControlMiniFromJSONTyped(json, false);
}

export function ControlMiniFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlMini {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'] == null ? undefined : ControlStatusEnumFromJSON(json['status']),
    };
}

export function ControlMiniToJSON(value?: Omit<ControlMini, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'status': ControlStatusEnumToJSON(value['status']),
    };
}

