import { ReactNode, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { cva } from "class-variance-authority";
import clsx from "clsx";
import { Assignees } from "components/assignees";
import { Checkbox } from "components/ui/checkbox";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Spinner as FlowbiteSpinner } from "flowbite-react";
import { cn, formatDate } from "lib/utils";
import { Article, ArticleImpactEnum } from "openapi";

import {
  ArrowsPointingInIcon,
  BookOpenIcon as BookOpenIconOutline,
} from "@heroicons/react/24/outline";
import {
  ArrowPathIcon,
  BookOpenIcon,
  BookmarkIcon,
  EllipsisVerticalIcon,
  ShareIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

import Spinner from "shared/components/spinner/Spinner";
import Badge from "shared/componentsV2/form-controls/badge";
import Button from "shared/componentsV2/form-controls/button";
import { useCompany } from "shared/context/CompanyProvider";

import { useAppliedFeedFilters } from "modules/horizonScanning/AppliedFeedFiltersContext";
import { useUpdateArticle } from "modules/horizonScanning/hooks/useArticles";
import { useFeedItemActionState } from "modules/horizonScanning/hooks/useFeedActionState";
import { getImpactColor, getImpactLabel } from "modules/horizonScanning/utils";

import ShareArticleModal from "../../shared/components/ShareArticleModal";
import { ArticleAction, DropdownFilterEnum } from "../../types";

const FeedArticleItems = ({
  articles,
  selectedItems,
  onCheckboxChange,
  onLoadMore,
  hasNextPage,
  hideActions,
  isFetchingNextPage,
  EmptyStateComponent,
  isInitialLoading,
}: {
  articles: Article[];
  selectedItems: ID[];
  onCheckboxChange: (id: ID) => void;
  onLoadMore: () => void;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  hideActions?: boolean;
  EmptyStateComponent?: JSX.Element;
  isInitialLoading?: boolean;
}) => {
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);

  const handleAction = (article: Article, action: ArticleAction) => {
    if (action === "share") {
      setSelectedArticle(article);
      setShareModalOpen(true);
    }
  };

  if (isInitialLoading)
    return (
      <div className="flex h-full">
        <Spinner />
      </div>
    );

  if (articles.length === 0)
    return <div className="flex h-full flex-col">{EmptyStateComponent ?? <p>no results</p>}</div>;

  return (
    <div className="grid gap-2 rounded-lg border-y border-antiflashwhite px-8 py-3 dark:border-thunders">
      <div className="grid gap-2">
        {articles.map((item) => (
          <FeedArticleItem
            selected={selectedItems.includes(item.id)}
            key={item.id}
            item={item}
            onToggle={onCheckboxChange}
            onAction={handleAction}
            hideActions={hideActions}
          />
        ))}
      </div>
      {hasNextPage && (
        <div className="flex items-center justify-center">
          {isFetchingNextPage ? (
            <FlowbiteSpinner size="md" />
          ) : (
            <Button
              btnType="secondary"
              btnSize="sm"
              btnTitle="Load 10 more articles"
              onClick={onLoadMore}
              className="w-fit"
            />
          )}
        </div>
      )}
      {selectedArticle && (
        <ShareArticleModal
          article={selectedArticle}
          open={shareModalOpen}
          onClose={() => setShareModalOpen(false)}
        />
      )}
    </div>
  );
};

export const FeedArticleItem = ({
  item: article,
  selected,
  hideActions,
  onToggle,
  onAction,
}: {
  item: Article;
  hideActions?: boolean;
  selected?: boolean;
  onToggle?: (id: number) => void;
  onAction: (article: Article, action: ArticleAction) => void;
}) => {
  const navigate = useNavigate();
  const { currentCompany } = useCompany();
  const { feedId } = useParams();
  const { uiFilter } = useAppliedFeedFilters();

  const stateAction = useFeedItemActionState({ feedId: 0, articleId: article.id });
  const archiveStateAction = useFeedItemActionState({
    feedId: Number(feedId),
    articleId: article.id,
  });

  const isRead = stateAction.isRead ?? article.isRead;
  const isSaved = stateAction.isSaved ?? article.isSaved;
  const isArchived = archiveStateAction.isArchived ?? article.isArchived;

  const updateArticle = useUpdateArticle({
    feedId: 0,
    companyId: currentCompany.id,
    id: article.id,
  });
  const archiveArticle = useUpdateArticle({
    feedId: Number(feedId),
    companyId: currentCompany.id,
    id: article.id,
  });

  const goToArticle = (item: Article) => {
    if (hideActions) {
      return;
    }
    navigate(`/workspace/${currentCompany.id}/horizon-scanning/feeds/${feedId}/article/${item.id}`);
  };

  const onOptimisticSuccessCallback = (str: string) => {
    toast(str, { type: "success" });
  };
  if (isArchived && uiFilter.dropdownFilter !== DropdownFilterEnum.archived) return null;

  const articleVariant = getArticleVariant({
    isRead,
    selected: !!selected,
    archived: !!isArchived,
  });
  return (
    <div className={articleVariants({ variant: articleVariant })}>
      <div className="flex items-start gap-3">
        <div
          className={cn("hover:opacity-100", {
            "invisible pointer-events-none opacity-0": !onToggle,
          })}
        >
          <Checkbox
            checked={!!selected}
            onChange={() => {
              onToggle?.(article.id);
            }}
          />
        </div>
        <div className="grid w-full">
          <div className="relative flex items-center gap-2">
            <h5
              className={cn(
                articlePublicationTitleVariants({
                  variant: articleVariant,
                  className: "text-xs font-medium",
                })
              )}
            >
              {article.publisher.title}
            </h5>
            <span className="text-xs font-medium text-gray-300">&bull;</span>
            {article.publicationDate && (
              <h5
                className={cn(
                  articlePublicationTitleVariants({
                    variant: articleVariant,
                    className: "text-xs font-medium",
                  })
                )}
              >
                {formatDate(article.publicationDate)}
              </h5>
            )}
            <div className="ml-auto flex items-center gap-1">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <button className="rounded-md px-1 py-2 hover:bg-gray-100">
                    <EllipsisVerticalIcon className="h-4 w-4 text-gray-500" />
                  </button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="center">
                  <DropdownMenuItem
                    onClick={() => (isSaved ? updateArticle.unsave() : updateArticle.save())}
                  >
                    <BookmarkIcon className={isSaved ? "fill-blue-600 stroke-blue-600" : ""} />
                    <p>{isSaved ? "Unsave" : "Save"}</p>
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={() => onAction(article, "share")}>
                    <ShareIcon className="h-4 w-4 text-gray-500" />
                    <p>Share</p>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <button
                className={cn(
                  "rounded-md px-1 py-2 hover:bg-gray-100",
                  isNaN(Number(feedId)) && "hidden"
                )}
                onClick={() =>
                  isArchived
                    ? archiveArticle.unarchive(() =>
                        onOptimisticSuccessCallback("Article restored")
                      )
                    : archiveArticle.archive(() => onOptimisticSuccessCallback("Article ignored"))
                }
              >
                {isArchived ? (
                  <ArrowPathIcon className="h-4 w-4 text-gray-500" />
                ) : (
                  <XMarkIcon className="h-4 w-4 text-gray-500" />
                )}
              </button>
            </div>
          </div>
          <div className="h-1" />
          <h2
            className={cn(
              "cursor-pointer text-lg font-semibold",
              isRead ? "text-gray-500" : "text-gray-900"
            )}
            onClick={() => goToArticle(article)}
          >
            {article.title}
          </h2>
          <p
            className={cn(
              "cursor-pointer break-words pt-2 text-sm font-normal",
              isRead ? "text-gray-500" : "text-gray-700"
            )}
            onClick={() => goToArticle(article)}
          >
            <span className={cn("font-medium", isRead ? "text-gray-700" : "text-gray-900")}>
              Summary:{" "}
            </span>
            {article.description}
          </p>
          <div className="h-4" />
          {!hideActions && (
            <div className="flex flex-col gap-y-2 md:flex-row md:items-center md:justify-between">
              <div className="flex flex-wrap items-center gap-2">
                {article.status && (
                  <ItemBadge
                    label={getStatusLabel(article.status)}
                    icon={
                      <span
                        className={clsx("h-2 w-2 rounded-xl", getStatusColor(article.status))}
                      />
                    }
                    className="capitalize"
                  />
                )}
                <Assignees users={article.assignees} />
                {/* add todo badge */}
                {article.impact && article.impact !== ArticleImpactEnum.NotSet && (
                  <ItemBadge
                    label={getImpactLabel(article.impact)}
                    icon={
                      <ArrowsPointingInIcon
                        className="h-4 w-4 stroke-2"
                        color={getImpactColor(article.impact)}
                      />
                    }
                  />
                )}
                {article.tags?.map((tag) => (
                  <Badge
                    key={tag.id}
                    badgeTitle={tag.name}
                    badgeColor="#1C64F2"
                    className="!bg-lavender"
                  />
                ))}
              </div>
              <button
                type="button"
                className="flex items-center gap-2"
                onClick={() => (isRead ? updateArticle.unread() : updateArticle.read())}
              >
                {isRead ? (
                  <BookOpenIconOutline className={"h-4 w-4 stroke-gray-500"} />
                ) : (
                  <BookOpenIcon className={"h-4 w-4 fill-blue-500"} />
                )}
                <p
                  className={cn(
                    "text-xs font-medium text-blue-500",
                    isRead && "text-gray-600 dark:text-gray-500"
                  )}
                >
                  {isRead ? "Mark as unread" : "Mark as read"}
                </p>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ItemBadge = ({
  label,
  icon,
  onClick,
  className,
}: {
  label: string;
  icon: ReactNode;
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <span
      className={clsx(
        "inline-flex cursor-pointer items-center gap-1 rounded-md border border-gray-200 px-1.5 py-0.5 text-xs text-gray-600 dark:border-thunders dark:text-gray-500",
        className
      )}
      onClick={onClick}
    >
      {icon}
      {label}
    </span>
  );
};

export default FeedArticleItems;

const articleVariants = cva(
  "rounded-lg border border-gray-200 px-3 py-4 dark:border-jet dark:bg-mirage hover:shadow-lg duration-300",
  {
    variants: {
      variant: {
        default: "bg-gray-50",
        isRead: "bg-white",
        isSelected: "bg-blue-50 border-transparent hover:shadow-none",
        isArchived: "bg-gray-50",
        readSelected: "bg-blue-50 border-transparent hover:shadow-none",
      },
    },
  }
);

const articlePublicationTitleVariants = cva("text-gray-800", {
  variants: {
    variant: {
      default: "dark:text-gray-400",
      isRead: "text-gray-500",
      isSelected: "text-gray-500",
      isArchived: "text-gray-500",
      readSelected: "text-gray-500",
    },
  },
});

const getArticleVariant = ({
  isRead,
  selected,
  archived,
}: {
  isRead: boolean;
  selected: boolean;
  archived: boolean;
}) => {
  if (archived) return "isArchived";
  if (selected && isRead) return "readSelected";
  if (selected) return "isSelected";
  if (isRead) return "isRead";
  return "default";
};

const getStatusColor = (status: string) => {
  switch (status) {
    case "pending":
      return "bg-gray-400";
    case "in_progress":
      return "bg-yellow-300";
    case "completed":
      return "bg-green-400";
    default:
      return "bg-orange-400";
  }
};

const getStatusLabel = (status: string) => {
  switch (status) {
    case "pending":
      return "Pending";
    case "in_progress":
      return "In Progress";
    case "completed":
      return "Completed";
    default:
      return "";
  }
};
