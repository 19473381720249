/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuthorityBodyMini } from './AuthorityBodyMini';
import {
    AuthorityBodyMiniFromJSON,
    AuthorityBodyMiniFromJSONTyped,
    AuthorityBodyMiniToJSON,
} from './AuthorityBodyMini';
import type { InstitutionTypesMini } from './InstitutionTypesMini';
import {
    InstitutionTypesMiniFromJSON,
    InstitutionTypesMiniFromJSONTyped,
    InstitutionTypesMiniToJSON,
} from './InstitutionTypesMini';
import type { JurisdictionMini } from './JurisdictionMini';
import {
    JurisdictionMiniFromJSON,
    JurisdictionMiniFromJSONTyped,
    JurisdictionMiniToJSON,
} from './JurisdictionMini';
import type { UserMini } from './UserMini';
import {
    UserMiniFromJSON,
    UserMiniFromJSONTyped,
    UserMiniToJSON,
} from './UserMini';
import type { OriginEnum } from './OriginEnum';
import {
    OriginEnumFromJSON,
    OriginEnumFromJSONTyped,
    OriginEnumToJSON,
} from './OriginEnum';

/**
 * 
 * @export
 * @interface AuthorityDocumentView
 */
export interface AuthorityDocumentView {
    /**
     * 
     * @type {number}
     * @memberof AuthorityDocumentView
     */
    readonly id: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthorityDocumentView
     */
    tags: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AuthorityDocumentView
     */
    name: string;
    /**
     * 
     * @type {Array<JurisdictionMini>}
     * @memberof AuthorityDocumentView
     */
    jurisdictions: Array<JurisdictionMini>;
    /**
     * 
     * @type {Array<InstitutionTypesMini>}
     * @memberof AuthorityDocumentView
     */
    institutionTypes: Array<InstitutionTypesMini>;
    /**
     * 
     * @type {Array<AuthorityBodyMini>}
     * @memberof AuthorityDocumentView
     */
    authorityBodies: Array<AuthorityBodyMini>;
    /**
     * 
     * @type {UserMini}
     * @memberof AuthorityDocumentView
     */
    owner: UserMini;
    /**
     * 
     * @type {number}
     * @memberof AuthorityDocumentView
     */
    readonly nrOfObligations: number;
    /**
     * 
     * @type {Date}
     * @memberof AuthorityDocumentView
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof AuthorityDocumentView
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorityDocumentView
     */
    readonly embeddings: boolean;
    /**
     * Upload a PDF file
     * @type {string}
     * @memberof AuthorityDocumentView
     */
    file: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorityDocumentView
     */
    title?: string;
    /**
     * 
     * @type {OriginEnum}
     * @memberof AuthorityDocumentView
     */
    origin: OriginEnum;
    /**
     * 
     * @type {string}
     * @memberof AuthorityDocumentView
     */
    executiveSummary?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthorityDocumentView
     */
    publishedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthorityDocumentView
     */
    lastReviewedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthorityDocumentView
     */
    complianceDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthorityDocumentView
     */
    upcomingReviewAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorityDocumentView
     */
    readonly documentText: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorityDocumentView
     */
    indexed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AuthorityDocumentView
     */
    company?: number | null;
}



/**
 * Check if a given object implements the AuthorityDocumentView interface.
 */
export function instanceOfAuthorityDocumentView(value: object): value is AuthorityDocumentView {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('jurisdictions' in value) || value['jurisdictions'] === undefined) return false;
    if (!('institutionTypes' in value) || value['institutionTypes'] === undefined) return false;
    if (!('authorityBodies' in value) || value['authorityBodies'] === undefined) return false;
    if (!('owner' in value) || value['owner'] === undefined) return false;
    if (!('nrOfObligations' in value) || value['nrOfObligations'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('embeddings' in value) || value['embeddings'] === undefined) return false;
    if (!('file' in value) || value['file'] === undefined) return false;
    if (!('origin' in value) || value['origin'] === undefined) return false;
    if (!('documentText' in value) || value['documentText'] === undefined) return false;
    return true;
}

export function AuthorityDocumentViewFromJSON(json: any): AuthorityDocumentView {
    return AuthorityDocumentViewFromJSONTyped(json, false);
}

export function AuthorityDocumentViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorityDocumentView {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tags': json['tags'],
        'name': json['name'],
        'jurisdictions': ((json['jurisdictions'] as Array<any>).map(JurisdictionMiniFromJSON)),
        'institutionTypes': ((json['institution_types'] as Array<any>).map(InstitutionTypesMiniFromJSON)),
        'authorityBodies': ((json['authority_bodies'] as Array<any>).map(AuthorityBodyMiniFromJSON)),
        'owner': UserMiniFromJSON(json['owner']),
        'nrOfObligations': json['nr_of_obligations'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'embeddings': json['embeddings'],
        'file': json['file'],
        'title': json['title'] == null ? undefined : json['title'],
        'origin': OriginEnumFromJSON(json['origin']),
        'executiveSummary': json['executive_summary'] == null ? undefined : json['executive_summary'],
        'publishedAt': json['published_at'] == null ? undefined : (new Date(json['published_at'])),
        'lastReviewedAt': json['last_reviewed_at'] == null ? undefined : (new Date(json['last_reviewed_at'])),
        'complianceDate': json['compliance_date'] == null ? undefined : (new Date(json['compliance_date'])),
        'upcomingReviewAt': json['upcoming_review_at'] == null ? undefined : (new Date(json['upcoming_review_at'])),
        'documentText': json['document_text'],
        'indexed': json['indexed'] == null ? undefined : json['indexed'],
        'company': json['company'] == null ? undefined : json['company'],
    };
}

export function AuthorityDocumentViewToJSON(value?: Omit<AuthorityDocumentView, 'id'|'nr_of_obligations'|'created_at'|'updated_at'|'embeddings'|'document_text'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tags': value['tags'],
        'name': value['name'],
        'jurisdictions': ((value['jurisdictions'] as Array<any>).map(JurisdictionMiniToJSON)),
        'institution_types': ((value['institutionTypes'] as Array<any>).map(InstitutionTypesMiniToJSON)),
        'authority_bodies': ((value['authorityBodies'] as Array<any>).map(AuthorityBodyMiniToJSON)),
        'owner': UserMiniToJSON(value['owner']),
        'file': value['file'],
        'title': value['title'],
        'origin': OriginEnumToJSON(value['origin']),
        'executive_summary': value['executiveSummary'],
        'published_at': value['publishedAt'] == null ? undefined : ((value['publishedAt'] as any).toISOString()),
        'last_reviewed_at': value['lastReviewedAt'] == null ? undefined : ((value['lastReviewedAt'] as any).toISOString()),
        'compliance_date': value['complianceDate'] == null ? undefined : ((value['complianceDate'] as any).toISOString()),
        'upcoming_review_at': value['upcomingReviewAt'] == null ? undefined : ((value['upcomingReviewAt'] as any).toISOString()),
        'indexed': value['indexed'],
        'company': value['company'],
    };
}

