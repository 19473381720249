/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RiskControlRequest
 */
export interface RiskControlRequest {
    /**
     * 
     * @type {number}
     * @memberof RiskControlRequest
     */
    riskId: number;
    /**
     * 
     * @type {number}
     * @memberof RiskControlRequest
     */
    registerId: number;
    /**
     * 
     * @type {string}
     * @memberof RiskControlRequest
     */
    impactReduction?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskControlRequest
     */
    likelihoodReduction?: string;
}

/**
 * Check if a given object implements the RiskControlRequest interface.
 */
export function instanceOfRiskControlRequest(value: object): value is RiskControlRequest {
    if (!('riskId' in value) || value['riskId'] === undefined) return false;
    if (!('registerId' in value) || value['registerId'] === undefined) return false;
    return true;
}

export function RiskControlRequestFromJSON(json: any): RiskControlRequest {
    return RiskControlRequestFromJSONTyped(json, false);
}

export function RiskControlRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskControlRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'riskId': json['risk_id'],
        'registerId': json['register_id'],
        'impactReduction': json['impact_reduction'] == null ? undefined : json['impact_reduction'],
        'likelihoodReduction': json['likelihood_reduction'] == null ? undefined : json['likelihood_reduction'],
    };
}

export function RiskControlRequestToJSON(value?: RiskControlRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'risk_id': value['riskId'],
        'register_id': value['registerId'],
        'impact_reduction': value['impactReduction'],
        'likelihood_reduction': value['likelihoodReduction'],
    };
}

