import { client } from "client";

import { GetTaskResultType } from "shared/types";
import { TeamParameter } from "shared/types";

import { getAggrementById } from "modules/agreements/overview/api";
import { getArticleById } from "modules/articles/overview/api";
import { getDirectoryByID, getSingleDocument } from "modules/documents/api";
import { getPolicyById } from "modules/policies/overview/api";

import getRiskById from "../../../risksV2/risk-detail/api/getRiskById";
import { RelatedObjectType } from "../types";

/**
 * Loads related object for tasks. assumes that task has related object.
 * If task has no related object, it will throw an error.
 */
export default function loadRelatedObject(
  task: GetTaskResultType | null,
  team: TeamParameter
): Promise<RelatedObjectType> {
  if (task?.agreement) {
    return getAggrementById(
      {
        agreementId: task?.agreement,
      },
      {
        team,
      }
    ).then((response) => response.data);
  }
  if (task?.article) {
    return getArticleById(task?.article).then((response) => response.data);
  }
  if (task?.policy) {
    return getPolicyById(task?.policy.toString(), {
      team,
    }).then((response) => response.data);
  }
  if (task?.document) {
    return getSingleDocument(task?.document.toString(), team).then((response) => response.data);
  }
  if (task?.directory) {
    return getDirectoryByID(`${task?.directory}`, team, {}).then((response) => response.data);
  }
  if (task?.risk) {
    return getRiskById({ riskId: task?.risk }, { company: team.company }).then(
      (response) =>
        ({
          id: Number(response.data.id),
          name: response.data.name,
          company: team.company,
          team: team.team,
          register: response.data.registers[0].id,
        } as RelatedObjectType)
    );
  }
  if (task?.obligation) {
    return (
      client.roi
        .roiObligationsRetrieve({ id: task.obligation, company: team.company })
        // TODO remove team: currently fixes type for tasks as team doesn't exist in Obligation
        .then((response) => ({ ...response, team: undefined }))
    );
  }
  if (task?.control) {
    return (
      client.controls
        .controlsRetrieve({ id: task.control, company: team.company })
        // TODO remove team: currently fixes type for tasks as team doesn't exist in Control
        .then((response) => ({ ...response, team: undefined }))
    );
  }
  throw new Error("Task has no related object");
}
