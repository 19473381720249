/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorityBody,
  PaginatedAuthorityBodyList,
} from '../models/index';
import {
    AuthorityBodyFromJSON,
    AuthorityBodyToJSON,
    PaginatedAuthorityBodyListFromJSON,
    PaginatedAuthorityBodyListToJSON,
} from '../models/index';

export interface CoreAuthorityBodiesListRequest {
    page?: number;
    pageSize?: number;
}

export interface CoreAuthorityBodiesRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class CoreApi extends runtime.BaseAPI {

    /**
     */
    async coreAuthorityBodiesListRaw(requestParameters: CoreAuthorityBodiesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedAuthorityBodyList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/core/authority-bodies/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedAuthorityBodyListFromJSON(jsonValue));
    }

    /**
     */
    async coreAuthorityBodiesList(requestParameters: CoreAuthorityBodiesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedAuthorityBodyList> {
        const response = await this.coreAuthorityBodiesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async coreAuthorityBodiesRetrieveRaw(requestParameters: CoreAuthorityBodiesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorityBody>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling coreAuthorityBodiesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/core/authority-bodies/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorityBodyFromJSON(jsonValue));
    }

    /**
     */
    async coreAuthorityBodiesRetrieve(requestParameters: CoreAuthorityBodiesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorityBody> {
        const response = await this.coreAuthorityBodiesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
