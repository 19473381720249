import React, { useEffect, useState } from "react";

import get from "lodash/get";

import { useTableFilter } from "shared/store/table-column-selection";

import { FilterOptionType } from "./types";

export default function DisplayFilters({ name }: { name: string }) {
  const [moduleFilter, setModuleFilter] = useState<FilterOptionType[]>([]);

  const selectedFilters = useTableFilter((state) => state.selectedFilters);

  useEffect(() => {
    setModuleFilter(get(selectedFilters, name, []) || []);
  }, [selectedFilters, name]);

  return (
    <div className=" mt-4 flex gap-2">
      {moduleFilter?.length > 0 ? <span className="mt-1 text-sm text-davygrey">Filter</span> : null}
      <div className="flex flex-wrap items-center gap-x-2 gap-y-1.5">
        {moduleFilter.map((filter) => (
          <React.Fragment key={`${filter.field}-${filter.key}`}>{filter?.label}</React.Fragment>
        ))}
      </div>
    </div>
  );
}
