/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthorityDocumentUpdateRequest
 */
export interface AuthorityDocumentUpdateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthorityDocumentUpdateRequest
     */
    tags: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AuthorityDocumentUpdateRequest
     */
    company?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AuthorityDocumentUpdateRequest
     */
    jurisdictions?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AuthorityDocumentUpdateRequest
     */
    institutionTypes?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AuthorityDocumentUpdateRequest
     */
    authorityBodies?: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof AuthorityDocumentUpdateRequest
     */
    publishedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthorityDocumentUpdateRequest
     */
    complianceDate: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthorityDocumentUpdateRequest
     */
    lastReviewedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof AuthorityDocumentUpdateRequest
     */
    upcomingReviewAt: Date | null;
    /**
     * 
     * @type {string}
     * @memberof AuthorityDocumentUpdateRequest
     */
    name: string;
}

/**
 * Check if a given object implements the AuthorityDocumentUpdateRequest interface.
 */
export function instanceOfAuthorityDocumentUpdateRequest(value: object): value is AuthorityDocumentUpdateRequest {
    if (!('tags' in value) || value['tags'] === undefined) return false;
    if (!('publishedAt' in value) || value['publishedAt'] === undefined) return false;
    if (!('complianceDate' in value) || value['complianceDate'] === undefined) return false;
    if (!('lastReviewedAt' in value) || value['lastReviewedAt'] === undefined) return false;
    if (!('upcomingReviewAt' in value) || value['upcomingReviewAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function AuthorityDocumentUpdateRequestFromJSON(json: any): AuthorityDocumentUpdateRequest {
    return AuthorityDocumentUpdateRequestFromJSONTyped(json, false);
}

export function AuthorityDocumentUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorityDocumentUpdateRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'tags': json['tags'],
        'company': json['company'] == null ? undefined : json['company'],
        'jurisdictions': json['jurisdictions'] == null ? undefined : json['jurisdictions'],
        'institutionTypes': json['institution_types'] == null ? undefined : json['institution_types'],
        'authorityBodies': json['authority_bodies'] == null ? undefined : json['authority_bodies'],
        'publishedAt': (json['published_at'] == null ? null : new Date(json['published_at'])),
        'complianceDate': (json['compliance_date'] == null ? null : new Date(json['compliance_date'])),
        'lastReviewedAt': (json['last_reviewed_at'] == null ? null : new Date(json['last_reviewed_at'])),
        'upcomingReviewAt': (json['upcoming_review_at'] == null ? null : new Date(json['upcoming_review_at'])),
        'name': json['name'],
    };
}

export function AuthorityDocumentUpdateRequestToJSON(value?: AuthorityDocumentUpdateRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'tags': value['tags'],
        'company': value['company'],
        'jurisdictions': value['jurisdictions'],
        'institution_types': value['institutionTypes'],
        'authority_bodies': value['authorityBodies'],
        'published_at': (value['publishedAt'] == null ? null : (value['publishedAt'] as any).toISOString().substring(0,10)),
        'compliance_date': (value['complianceDate'] == null ? null : (value['complianceDate'] as any).toISOString().substring(0,10)),
        'last_reviewed_at': (value['lastReviewedAt'] == null ? null : (value['lastReviewedAt'] as any).toISOString().substring(0,10)),
        'upcoming_review_at': (value['upcomingReviewAt'] == null ? null : (value['upcomingReviewAt'] as any).toISOString().substring(0,10)),
        'name': value['name'],
    };
}

