import { useFormik } from "formik";

const useAddUserFilter = (action: CallableFunction) => {
  return useFormik<{ assignees: { label: string; value: string }[] }>({
    initialValues: {
      assignees: [],
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (_, { resetForm }) => {
      action();
      resetForm();
    },
  });
};

export default useAddUserFilter;
