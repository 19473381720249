/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorityDocumentUpdateRequest,
  AuthorityDocumentView,
  ControlConnectionRequest,
  Obligation,
  ObligationComment,
  ObligationCommentRequest,
  ObligationRequest,
  ObligationTasks,
  PaginatedAuthorityDocumentViewList,
  PaginatedObligationCommentList,
  PaginatedObligationList,
  PatchedAuthorityDocumentUpdateRequest,
  PatchedObligationCommentRequest,
  PatchedObligationRequest,
  SimilarObligation,
  TaskConnectionRequest,
} from '../models/index';
import {
    AuthorityDocumentUpdateRequestFromJSON,
    AuthorityDocumentUpdateRequestToJSON,
    AuthorityDocumentViewFromJSON,
    AuthorityDocumentViewToJSON,
    ControlConnectionRequestFromJSON,
    ControlConnectionRequestToJSON,
    ObligationFromJSON,
    ObligationToJSON,
    ObligationCommentFromJSON,
    ObligationCommentToJSON,
    ObligationCommentRequestFromJSON,
    ObligationCommentRequestToJSON,
    ObligationRequestFromJSON,
    ObligationRequestToJSON,
    ObligationTasksFromJSON,
    ObligationTasksToJSON,
    PaginatedAuthorityDocumentViewListFromJSON,
    PaginatedAuthorityDocumentViewListToJSON,
    PaginatedObligationCommentListFromJSON,
    PaginatedObligationCommentListToJSON,
    PaginatedObligationListFromJSON,
    PaginatedObligationListToJSON,
    PatchedAuthorityDocumentUpdateRequestFromJSON,
    PatchedAuthorityDocumentUpdateRequestToJSON,
    PatchedObligationCommentRequestFromJSON,
    PatchedObligationCommentRequestToJSON,
    PatchedObligationRequestFromJSON,
    PatchedObligationRequestToJSON,
    SimilarObligationFromJSON,
    SimilarObligationToJSON,
    TaskConnectionRequestFromJSON,
    TaskConnectionRequestToJSON,
} from '../models/index';

export interface RoiAuthorityDocumentsCreateRequest {
    file: Blob;
    name: string;
    company?: number | null;
}

export interface RoiAuthorityDocumentsDestroyRequest {
    company: number;
    id: number;
}

export interface RoiAuthorityDocumentsListRequest {
    company: number;
    authorityBodies?: Array<number>;
    authorityDocumentsSort?: string;
    createdAtAfter?: Date;
    createdAtBefore?: Date;
    institutionTypes?: Array<number>;
    jurisdictions?: Array<number>;
    origin?: RoiAuthorityDocumentsListOriginEnum;
    page?: number;
    pageSize?: number;
    search?: string;
    tags?: Array<string>;
    upcomingReviewAfter?: Date;
    upcomingReviewBefore?: Date;
}

export interface RoiAuthorityDocumentsPartialUpdateRequest {
    id: number;
    patchedAuthorityDocumentUpdateRequest?: PatchedAuthorityDocumentUpdateRequest;
}

export interface RoiAuthorityDocumentsRetrieveRequest {
    company: number;
    id: number;
}

export interface RoiAuthorityDocumentsUpdateRequest {
    id: number;
    authorityDocumentUpdateRequest: AuthorityDocumentUpdateRequest;
}

export interface RoiObligationCommentsCreateRequest {
    obligationCommentRequest: ObligationCommentRequest;
}

export interface RoiObligationCommentsDestroyRequest {
    id: number;
}

export interface RoiObligationCommentsListRequest {
    obligation?: number;
    page?: number;
    pageSize?: number;
    status?: RoiObligationCommentsListStatusEnum;
}

export interface RoiObligationCommentsPartialUpdateRequest {
    id: number;
    patchedObligationCommentRequest?: PatchedObligationCommentRequest;
}

export interface RoiObligationCommentsRetrieveRequest {
    id: number;
}

export interface RoiObligationCommentsUpdateRequest {
    id: number;
    obligationCommentRequest: ObligationCommentRequest;
}

export interface RoiObligationsConnectControlCreateRequest {
    company: number;
    id: number;
    controlConnectionRequest: ControlConnectionRequest;
}

export interface RoiObligationsConnectTaskCreateRequest {
    company: number;
    id: number;
    taskConnectionRequest: TaskConnectionRequest;
}

export interface RoiObligationsCreateRequest {
    company: number;
    obligationRequest: ObligationRequest;
}

export interface RoiObligationsDestroyRequest {
    company: number;
    id: number;
}

export interface RoiObligationsDisconnectControlCreateRequest {
    company: number;
    id: number;
    controlConnectionRequest: ControlConnectionRequest;
}

export interface RoiObligationsDisconnectTaskCreateRequest {
    company: number;
    id: number;
    taskConnectionRequest: TaskConnectionRequest;
}

export interface RoiObligationsListRequest {
    company: number;
    authorityDocument?: number;
    authorityDocuments?: Array<number>;
    categories?: Array<string>;
    obligationTopic?: Array<string>;
    obligationsSort?: string;
    page?: number;
    pageSize?: number;
    policyCoverage?: boolean;
    search?: string;
}

export interface RoiObligationsPartialUpdateRequest {
    company: number;
    id: number;
    patchedObligationRequest?: PatchedObligationRequest;
}

export interface RoiObligationsRetrieveRequest {
    company: number;
    id: number;
}

export interface RoiObligationsSimilarListRequest {
    company: number;
    id: number;
    authorityDocument?: number;
    authorityDocuments?: Array<number>;
    categories?: Array<string>;
    obligationTopic?: Array<string>;
    obligationsSort?: string;
    policyCoverage?: boolean;
    search?: string;
    type?: RoiObligationsSimilarListTypeEnum;
}

export interface RoiObligationsTasksRetrieveRequest {
    company: number;
    id: number;
}

export interface RoiObligationsUpdateRequest {
    company: number;
    id: number;
    obligationRequest: ObligationRequest;
}

/**
 * 
 */
export class RoiApi extends runtime.BaseAPI {

    /**
     */
    async roiAuthorityDocumentsCreateRaw(requestParameters: RoiAuthorityDocumentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorityDocumentView>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling roiAuthorityDocumentsCreate().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling roiAuthorityDocumentsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        if (requestParameters['company'] != null) {
            formParams.append('company', requestParameters['company'] as any);
        }

        if (requestParameters['name'] != null) {
            formParams.append('name', requestParameters['name'] as any);
        }

        const response = await this.request({
            path: `/api/v1/roi/authority-documents/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorityDocumentViewFromJSON(jsonValue));
    }

    /**
     */
    async roiAuthorityDocumentsCreate(requestParameters: RoiAuthorityDocumentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorityDocumentView> {
        const response = await this.roiAuthorityDocumentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiAuthorityDocumentsDestroyRaw(requestParameters: RoiAuthorityDocumentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiAuthorityDocumentsDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiAuthorityDocumentsDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/authority-documents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async roiAuthorityDocumentsDestroy(requestParameters: RoiAuthorityDocumentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.roiAuthorityDocumentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async roiAuthorityDocumentsListRaw(requestParameters: RoiAuthorityDocumentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedAuthorityDocumentViewList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiAuthorityDocumentsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['authorityBodies'] != null) {
            queryParameters['authority_bodies'] = requestParameters['authorityBodies']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['authorityDocumentsSort'] != null) {
            queryParameters['authority_documents_sort'] = requestParameters['authorityDocumentsSort'];
        }

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['createdAtAfter'] != null) {
            queryParameters['created_at_after'] = (requestParameters['createdAtAfter'] as any).toISOString();
        }

        if (requestParameters['createdAtBefore'] != null) {
            queryParameters['created_at_before'] = (requestParameters['createdAtBefore'] as any).toISOString();
        }

        if (requestParameters['institutionTypes'] != null) {
            queryParameters['institution_types'] = requestParameters['institutionTypes']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['jurisdictions'] != null) {
            queryParameters['jurisdictions'] = requestParameters['jurisdictions']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['origin'] != null) {
            queryParameters['origin'] = requestParameters['origin'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['tags'] != null) {
            queryParameters['tags'] = requestParameters['tags'];
        }

        if (requestParameters['upcomingReviewAfter'] != null) {
            queryParameters['upcoming_review_after'] = (requestParameters['upcomingReviewAfter'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['upcomingReviewBefore'] != null) {
            queryParameters['upcoming_review_before'] = (requestParameters['upcomingReviewBefore'] as any).toISOString().substring(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/authority-documents/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedAuthorityDocumentViewListFromJSON(jsonValue));
    }

    /**
     */
    async roiAuthorityDocumentsList(requestParameters: RoiAuthorityDocumentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedAuthorityDocumentViewList> {
        const response = await this.roiAuthorityDocumentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiAuthorityDocumentsPartialUpdateRaw(requestParameters: RoiAuthorityDocumentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorityDocumentView>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiAuthorityDocumentsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/authority-documents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedAuthorityDocumentUpdateRequestToJSON(requestParameters['patchedAuthorityDocumentUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorityDocumentViewFromJSON(jsonValue));
    }

    /**
     */
    async roiAuthorityDocumentsPartialUpdate(requestParameters: RoiAuthorityDocumentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorityDocumentView> {
        const response = await this.roiAuthorityDocumentsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiAuthorityDocumentsRetrieveRaw(requestParameters: RoiAuthorityDocumentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorityDocumentView>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiAuthorityDocumentsRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiAuthorityDocumentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/authority-documents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorityDocumentViewFromJSON(jsonValue));
    }

    /**
     */
    async roiAuthorityDocumentsRetrieve(requestParameters: RoiAuthorityDocumentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorityDocumentView> {
        const response = await this.roiAuthorityDocumentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiAuthorityDocumentsUpdateRaw(requestParameters: RoiAuthorityDocumentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorityDocumentView>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiAuthorityDocumentsUpdate().'
            );
        }

        if (requestParameters['authorityDocumentUpdateRequest'] == null) {
            throw new runtime.RequiredError(
                'authorityDocumentUpdateRequest',
                'Required parameter "authorityDocumentUpdateRequest" was null or undefined when calling roiAuthorityDocumentsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/authority-documents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorityDocumentUpdateRequestToJSON(requestParameters['authorityDocumentUpdateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorityDocumentViewFromJSON(jsonValue));
    }

    /**
     */
    async roiAuthorityDocumentsUpdate(requestParameters: RoiAuthorityDocumentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorityDocumentView> {
        const response = await this.roiAuthorityDocumentsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationCommentsCreateRaw(requestParameters: RoiObligationCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ObligationComment>> {
        if (requestParameters['obligationCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'obligationCommentRequest',
                'Required parameter "obligationCommentRequest" was null or undefined when calling roiObligationCommentsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligation-comments/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ObligationCommentRequestToJSON(requestParameters['obligationCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationCommentFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationCommentsCreate(requestParameters: RoiObligationCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ObligationComment> {
        const response = await this.roiObligationCommentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationCommentsDestroyRaw(requestParameters: RoiObligationCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationCommentsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligation-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async roiObligationCommentsDestroy(requestParameters: RoiObligationCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.roiObligationCommentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async roiObligationCommentsListRaw(requestParameters: RoiObligationCommentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedObligationCommentList>> {
        const queryParameters: any = {};

        if (requestParameters['obligation'] != null) {
            queryParameters['obligation'] = requestParameters['obligation'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligation-comments/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedObligationCommentListFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationCommentsList(requestParameters: RoiObligationCommentsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedObligationCommentList> {
        const response = await this.roiObligationCommentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationCommentsPartialUpdateRaw(requestParameters: RoiObligationCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ObligationComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationCommentsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligation-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedObligationCommentRequestToJSON(requestParameters['patchedObligationCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationCommentFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationCommentsPartialUpdate(requestParameters: RoiObligationCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ObligationComment> {
        const response = await this.roiObligationCommentsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationCommentsRetrieveRaw(requestParameters: RoiObligationCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ObligationComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationCommentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligation-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationCommentFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationCommentsRetrieve(requestParameters: RoiObligationCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ObligationComment> {
        const response = await this.roiObligationCommentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationCommentsUpdateRaw(requestParameters: RoiObligationCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ObligationComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationCommentsUpdate().'
            );
        }

        if (requestParameters['obligationCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'obligationCommentRequest',
                'Required parameter "obligationCommentRequest" was null or undefined when calling roiObligationCommentsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligation-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ObligationCommentRequestToJSON(requestParameters['obligationCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationCommentFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationCommentsUpdate(requestParameters: RoiObligationCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ObligationComment> {
        const response = await this.roiObligationCommentsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Connect a control to an obligation.
     */
    async roiObligationsConnectControlCreateRaw(requestParameters: RoiObligationsConnectControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiObligationsConnectControlCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsConnectControlCreate().'
            );
        }

        if (requestParameters['controlConnectionRequest'] == null) {
            throw new runtime.RequiredError(
                'controlConnectionRequest',
                'Required parameter "controlConnectionRequest" was null or undefined when calling roiObligationsConnectControlCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/connect_control/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlConnectionRequestToJSON(requestParameters['controlConnectionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Connect a control to an obligation.
     */
    async roiObligationsConnectControlCreate(requestParameters: RoiObligationsConnectControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.roiObligationsConnectControlCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Connect a task to an obligation.
     */
    async roiObligationsConnectTaskCreateRaw(requestParameters: RoiObligationsConnectTaskCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiObligationsConnectTaskCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsConnectTaskCreate().'
            );
        }

        if (requestParameters['taskConnectionRequest'] == null) {
            throw new runtime.RequiredError(
                'taskConnectionRequest',
                'Required parameter "taskConnectionRequest" was null or undefined when calling roiObligationsConnectTaskCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/connect_task/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskConnectionRequestToJSON(requestParameters['taskConnectionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Connect a task to an obligation.
     */
    async roiObligationsConnectTaskCreate(requestParameters: RoiObligationsConnectTaskCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.roiObligationsConnectTaskCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async roiObligationsCreateRaw(requestParameters: RoiObligationsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Obligation>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiObligationsCreate().'
            );
        }

        if (requestParameters['obligationRequest'] == null) {
            throw new runtime.RequiredError(
                'obligationRequest',
                'Required parameter "obligationRequest" was null or undefined when calling roiObligationsCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ObligationRequestToJSON(requestParameters['obligationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationsCreate(requestParameters: RoiObligationsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Obligation> {
        const response = await this.roiObligationsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationsDestroyRaw(requestParameters: RoiObligationsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiObligationsDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async roiObligationsDestroy(requestParameters: RoiObligationsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.roiObligationsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Disconnect a control from an obligation.
     */
    async roiObligationsDisconnectControlCreateRaw(requestParameters: RoiObligationsDisconnectControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiObligationsDisconnectControlCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsDisconnectControlCreate().'
            );
        }

        if (requestParameters['controlConnectionRequest'] == null) {
            throw new runtime.RequiredError(
                'controlConnectionRequest',
                'Required parameter "controlConnectionRequest" was null or undefined when calling roiObligationsDisconnectControlCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/disconnect_control/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlConnectionRequestToJSON(requestParameters['controlConnectionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Disconnect a control from an obligation.
     */
    async roiObligationsDisconnectControlCreate(requestParameters: RoiObligationsDisconnectControlCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.roiObligationsDisconnectControlCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Disconnect a task from an obligation.
     */
    async roiObligationsDisconnectTaskCreateRaw(requestParameters: RoiObligationsDisconnectTaskCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiObligationsDisconnectTaskCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsDisconnectTaskCreate().'
            );
        }

        if (requestParameters['taskConnectionRequest'] == null) {
            throw new runtime.RequiredError(
                'taskConnectionRequest',
                'Required parameter "taskConnectionRequest" was null or undefined when calling roiObligationsDisconnectTaskCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/disconnect_task/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskConnectionRequestToJSON(requestParameters['taskConnectionRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Disconnect a task from an obligation.
     */
    async roiObligationsDisconnectTaskCreate(requestParameters: RoiObligationsDisconnectTaskCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.roiObligationsDisconnectTaskCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async roiObligationsListRaw(requestParameters: RoiObligationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedObligationList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiObligationsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['authorityDocument'] != null) {
            queryParameters['authority_document'] = requestParameters['authorityDocument'];
        }

        if (requestParameters['authorityDocuments'] != null) {
            queryParameters['authority_documents'] = requestParameters['authorityDocuments']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['categories'] != null) {
            queryParameters['categories'] = requestParameters['categories'];
        }

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['obligationTopic'] != null) {
            queryParameters['obligation_topic'] = requestParameters['obligationTopic']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['obligationsSort'] != null) {
            queryParameters['obligations_sort'] = requestParameters['obligationsSort'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['policyCoverage'] != null) {
            queryParameters['policy_coverage'] = requestParameters['policyCoverage'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedObligationListFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationsList(requestParameters: RoiObligationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedObligationList> {
        const response = await this.roiObligationsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationsPartialUpdateRaw(requestParameters: RoiObligationsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Obligation>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiObligationsPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedObligationRequestToJSON(requestParameters['patchedObligationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationsPartialUpdate(requestParameters: RoiObligationsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Obligation> {
        const response = await this.roiObligationsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationsRetrieveRaw(requestParameters: RoiObligationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Obligation>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiObligationsRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationsRetrieve(requestParameters: RoiObligationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Obligation> {
        const response = await this.roiObligationsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve obligations similar to a given obligation ID.
     */
    async roiObligationsSimilarListRaw(requestParameters: RoiObligationsSimilarListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SimilarObligation>>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiObligationsSimilarList().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsSimilarList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['authorityDocument'] != null) {
            queryParameters['authority_document'] = requestParameters['authorityDocument'];
        }

        if (requestParameters['authorityDocuments'] != null) {
            queryParameters['authority_documents'] = requestParameters['authorityDocuments']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['categories'] != null) {
            queryParameters['categories'] = requestParameters['categories'];
        }

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['obligationTopic'] != null) {
            queryParameters['obligation_topic'] = requestParameters['obligationTopic']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['obligationsSort'] != null) {
            queryParameters['obligations_sort'] = requestParameters['obligationsSort'];
        }

        if (requestParameters['policyCoverage'] != null) {
            queryParameters['policy_coverage'] = requestParameters['policyCoverage'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/similar/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SimilarObligationFromJSON));
    }

    /**
     * Retrieve obligations similar to a given obligation ID.
     */
    async roiObligationsSimilarList(requestParameters: RoiObligationsSimilarListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SimilarObligation>> {
        const response = await this.roiObligationsSimilarListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get tasks for an obligation.
     */
    async roiObligationsTasksRetrieveRaw(requestParameters: RoiObligationsTasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ObligationTasks>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiObligationsTasksRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsTasksRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/tasks/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationTasksFromJSON(jsonValue));
    }

    /**
     * Get tasks for an obligation.
     */
    async roiObligationsTasksRetrieve(requestParameters: RoiObligationsTasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ObligationTasks> {
        const response = await this.roiObligationsTasksRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async roiObligationsUpdateRaw(requestParameters: RoiObligationsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Obligation>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling roiObligationsUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling roiObligationsUpdate().'
            );
        }

        if (requestParameters['obligationRequest'] == null) {
            throw new runtime.RequiredError(
                'obligationRequest',
                'Required parameter "obligationRequest" was null or undefined when calling roiObligationsUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/roi/obligations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ObligationRequestToJSON(requestParameters['obligationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ObligationFromJSON(jsonValue));
    }

    /**
     */
    async roiObligationsUpdate(requestParameters: RoiObligationsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Obligation> {
        const response = await this.roiObligationsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const RoiAuthorityDocumentsListOriginEnum = {
    External: 'EXTERNAL',
    Internal: 'INTERNAL'
} as const;
export type RoiAuthorityDocumentsListOriginEnum = typeof RoiAuthorityDocumentsListOriginEnum[keyof typeof RoiAuthorityDocumentsListOriginEnum];
/**
 * @export
 */
export const RoiObligationCommentsListStatusEnum = {
    Open: 'open',
    Resolved: 'resolved'
} as const;
export type RoiObligationCommentsListStatusEnum = typeof RoiObligationCommentsListStatusEnum[keyof typeof RoiObligationCommentsListStatusEnum];
/**
 * @export
 */
export const RoiObligationsSimilarListTypeEnum = {
    ControlBased: 'control_based',
    VectorBased: 'vector_based'
} as const;
export type RoiObligationsSimilarListTypeEnum = typeof RoiObligationsSimilarListTypeEnum[keyof typeof RoiObligationsSimilarListTypeEnum];
