/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CommentStatusEnum } from './CommentStatusEnum';
import {
    CommentStatusEnumFromJSON,
    CommentStatusEnumFromJSONTyped,
    CommentStatusEnumToJSON,
} from './CommentStatusEnum';

/**
 * 
 * @export
 * @interface PatchedObligationCommentRequest
 */
export interface PatchedObligationCommentRequest {
    /**
     * 
     * @type {Array<Blob>}
     * @memberof PatchedObligationCommentRequest
     */
    attachments?: Array<Blob>;
    /**
     * 
     * @type {string}
     * @memberof PatchedObligationCommentRequest
     */
    text?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof PatchedObligationCommentRequest
     */
    status?: CommentStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PatchedObligationCommentRequest
     */
    obligation?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedObligationCommentRequest
     */
    mentions?: Array<number>;
}



/**
 * Check if a given object implements the PatchedObligationCommentRequest interface.
 */
export function instanceOfPatchedObligationCommentRequest(value: object): value is PatchedObligationCommentRequest {
    return true;
}

export function PatchedObligationCommentRequestFromJSON(json: any): PatchedObligationCommentRequest {
    return PatchedObligationCommentRequestFromJSONTyped(json, false);
}

export function PatchedObligationCommentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedObligationCommentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'attachments': json['attachments'] == null ? undefined : json['attachments'],
        'text': json['text'] == null ? undefined : json['text'],
        'status': json['status'] == null ? undefined : CommentStatusEnumFromJSON(json['status']),
        'obligation': json['obligation'] == null ? undefined : json['obligation'],
        'mentions': json['mentions'] == null ? undefined : json['mentions'],
    };
}

export function PatchedObligationCommentRequestToJSON(value?: PatchedObligationCommentRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attachments': value['attachments'],
        'text': value['text'],
        'status': CommentStatusEnumToJSON(value['status']),
        'obligation': value['obligation'],
        'mentions': value['mentions'],
    };
}

