import { useEffect } from "react";

import { UserSelect } from "components/user-select/user-select";

import headerStyles from "assets/css/common-header.module.css";

import { useCompany } from "shared/context/CompanyProvider";

import { optionType } from "../metadata/types";
import useAddUserFilter from "./hooks/useAddUserFilter";
import { FilterValues, FiltersProp } from "./types";

function UserFilter({
  onClick,
  item,
  selectedFilters,
}: {
  onClick: (data: { label: string; value: string }[]) => void;
  item: FiltersProp;
  selectedFilters: FilterValues[];
}) {
  const { usersList } = useCompany();
  const users = usersList.map((user) => ({
    label: user.user.name || user.user.email,
    value: user.memberId.toString(),
    data: user.user,
  }));

  const { handleSubmit, values, resetForm, setFieldValue } = useAddUserFilter(() => {
    onClick(values?.assignees);
  });

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [item.field]);

  useEffect(() => {
    const selectedUsers = selectedFilters
      .map((filter) => {
        return users.find((u) => Number(u.value) === Number(filter));
      })
      .filter(Boolean);

    setFieldValue(item.field, selectedUsers);
  }, [selectedFilters]);

  return (
    <>
      <form onSubmit={handleSubmit} className="mt-2 grid gap-2">
        <UserSelect
          options={users}
          placeholder="Select Assignee"
          value={
            values?.assignees
              ?.map((v) => {
                const user = users.find((u) => Number(u.value) === Number(v.value));
                return user ? { label: user.label, value: user.value, user: user.data } : undefined;
              })
              .filter(Boolean) as optionType[]
          }
          onChange={(value) => {
            if (Array.isArray(value)) {
              setFieldValue(
                item.field,
                value.map((p) => ({ label: p.label, value: p.value }))
              );
            }
          }}
        />
        <div className="!w-fit maxMd:!w-full">
          <button
            type="submit"
            className={headerStyles.btn_primary__imp}
            disabled={values?.assignees.length === 0}
          >
            Apply
          </button>
        </div>
      </form>
    </>
  );
}

export default UserFilter;
