/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkCreateFromLibraryRequest
 */
export interface BulkCreateFromLibraryRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof BulkCreateFromLibraryRequest
     */
    controlIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof BulkCreateFromLibraryRequest
     */
    libraryId: number;
}

/**
 * Check if a given object implements the BulkCreateFromLibraryRequest interface.
 */
export function instanceOfBulkCreateFromLibraryRequest(value: object): value is BulkCreateFromLibraryRequest {
    if (!('controlIds' in value) || value['controlIds'] === undefined) return false;
    if (!('libraryId' in value) || value['libraryId'] === undefined) return false;
    return true;
}

export function BulkCreateFromLibraryRequestFromJSON(json: any): BulkCreateFromLibraryRequest {
    return BulkCreateFromLibraryRequestFromJSONTyped(json, false);
}

export function BulkCreateFromLibraryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCreateFromLibraryRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'controlIds': json['control_ids'],
        'libraryId': json['library_id'],
    };
}

export function BulkCreateFromLibraryRequestToJSON(value?: BulkCreateFromLibraryRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'control_ids': value['controlIds'],
        'library_id': value['libraryId'],
    };
}

