/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TextAreaRequest } from './TextAreaRequest';
import {
    TextAreaRequestFromJSON,
    TextAreaRequestFromJSONTyped,
    TextAreaRequestToJSON,
} from './TextAreaRequest';

/**
 * 
 * @export
 * @interface PatchedObligationRequest
 */
export interface PatchedObligationRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchedObligationRequest
     */
    categories?: Array<string>;
    /**
     * 
     * @type {Array<TextAreaRequest>}
     * @memberof PatchedObligationRequest
     */
    textAreas?: Array<TextAreaRequest>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedObligationRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedObligationRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedObligationRequest
     */
    text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedObligationRequest
     */
    policyCoverage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedObligationRequest
     */
    indexed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PatchedObligationRequest
     */
    startingPageIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedObligationRequest
     */
    minY0?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedObligationRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedObligationRequest
     */
    authorityDocument?: number;
}

/**
 * Check if a given object implements the PatchedObligationRequest interface.
 */
export function instanceOfPatchedObligationRequest(value: object): value is PatchedObligationRequest {
    return true;
}

export function PatchedObligationRequestFromJSON(json: any): PatchedObligationRequest {
    return PatchedObligationRequestFromJSONTyped(json, false);
}

export function PatchedObligationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedObligationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'categories': json['categories'] == null ? undefined : json['categories'],
        'textAreas': json['text_areas'] == null ? undefined : ((json['text_areas'] as Array<any>).map(TextAreaRequestFromJSON)),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'] == null ? undefined : json['name'],
        'text': json['text'] == null ? undefined : json['text'],
        'policyCoverage': json['policy_coverage'] == null ? undefined : json['policy_coverage'],
        'indexed': json['indexed'] == null ? undefined : json['indexed'],
        'startingPageIndex': json['starting_page_index'] == null ? undefined : json['starting_page_index'],
        'minY0': json['min_y0'] == null ? undefined : json['min_y0'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'authorityDocument': json['authority_document'] == null ? undefined : json['authority_document'],
    };
}

export function PatchedObligationRequestToJSON(value?: PatchedObligationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'categories': value['categories'],
        'text_areas': value['textAreas'] == null ? undefined : ((value['textAreas'] as Array<any>).map(TextAreaRequestToJSON)),
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'text': value['text'],
        'policy_coverage': value['policyCoverage'],
        'indexed': value['indexed'],
        'starting_page_index': value['startingPageIndex'],
        'min_y0': value['minY0'],
        'object_owner': value['objectOwner'],
        'authority_document': value['authorityDocument'],
    };
}

