import { TodoRequest } from "openapi";

export type ArticleAction = "save" | "read" | "archive" | "share";

export type AttachTaskParams = {
  companyId: number;
  feedId: number;
  articleId: string;
  taskIds: number[];
};

export enum DropdownFilterEnum {
  all = "all",
  unread = "unread",
  read = "read",
  assigned = "assigned",
  mentioned = "mentioned",
  comments = "comments", // same as mentioned
  saved = "saved",
  archived = "archived",
}

export type TodoRequestWithId = TodoRequest & { id: number };
