import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

import { useDebounce } from "usehooks-ts";

import { DropdownFilterEnum } from "modules/horizonScanning/types";

import { DropdownSortEnum, DropdownSortEnumValues, FeedFiltersOmitted } from "./constants";

export type UIFilter = {
  search: string | null;
  assignedTo: string[];
  tags: string[];
  status: string[];
  impact: string[];
  dropdownFilter: DropdownFilterEnum;
  sortBy: DropdownSortEnumValues;
};

type CAppliedFeedFilters = {
  appliedFilters: FeedFiltersOmitted;
  // sidebarFilters: FeedFiltersOmitted;
  debouncedSearch: string | null;
  setAppliedFilters: (filters: FeedFiltersOmitted) => void;
  uiFilter: UIFilter;
  setUIFilter: Dispatch<SetStateAction<UIFilter>>;
};
export const defaultFeedUIFilter: UIFilter = {
  search: null,
  assignedTo: [],
  tags: [],
  status: [],
  impact: [],
  dropdownFilter: DropdownFilterEnum.all,
  sortBy: DropdownSortEnum.date,
};

const AppliedFeedFiltersContext = createContext<CAppliedFeedFilters>({
  appliedFilters: {},
  debouncedSearch: null,
  uiFilter: defaultFeedUIFilter,
  setAppliedFilters: () => console.error("Missing AppliedFeedFiltersProvider"),
  setUIFilter: () => console.error("Missing AppliedFeedFiltersProvider"),
});
export const AppliedFeedFiltersProvider = ({ children }: PropsWithChildren) => {
  const [appliedFilters, setAppliedFilters] = useState<FeedFiltersOmitted>({});

  const [uiFilter, setUIFilter] = useState<UIFilter>(defaultFeedUIFilter);
  const debouncedSearch = useDebounce(uiFilter.search, 500);
  return (
    <AppliedFeedFiltersContext.Provider
      value={{
        appliedFilters,
        debouncedSearch,
        setAppliedFilters,
        uiFilter,
        setUIFilter,
      }}
    >
      {children}
    </AppliedFeedFiltersContext.Provider>
  );
};

export const useAppliedFeedFilters = () => useContext(AppliedFeedFiltersContext);
