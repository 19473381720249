/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TaskMini } from './TaskMini';
import {
    TaskMiniFromJSON,
    TaskMiniFromJSONTyped,
    TaskMiniToJSON,
} from './TaskMini';

/**
 * 
 * @export
 * @interface ObligationTasks
 */
export interface ObligationTasks {
    /**
     * 
     * @type {number}
     * @memberof ObligationTasks
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ObligationTasks
     */
    name: string;
    /**
     * 
     * @type {Array<TaskMini>}
     * @memberof ObligationTasks
     */
    readonly tasks: Array<TaskMini>;
}

/**
 * Check if a given object implements the ObligationTasks interface.
 */
export function instanceOfObligationTasks(value: object): value is ObligationTasks {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('tasks' in value) || value['tasks'] === undefined) return false;
    return true;
}

export function ObligationTasksFromJSON(json: any): ObligationTasks {
    return ObligationTasksFromJSONTyped(json, false);
}

export function ObligationTasksFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObligationTasks {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'tasks': ((json['tasks'] as Array<any>).map(TaskMiniFromJSON)),
    };
}

export function ObligationTasksToJSON(value?: Omit<ObligationTasks, 'id'|'tasks'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

