/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlImportanceScore
 */
export interface ControlImportanceScore {
    /**
     * 
     * @type {number}
     * @memberof ControlImportanceScore
     */
    importanceScore: number;
    /**
     * 
     * @type {string}
     * @memberof ControlImportanceScore
     */
    riskLevel: string;
}

/**
 * Check if a given object implements the ControlImportanceScore interface.
 */
export function instanceOfControlImportanceScore(value: object): value is ControlImportanceScore {
    if (!('importanceScore' in value) || value['importanceScore'] === undefined) return false;
    if (!('riskLevel' in value) || value['riskLevel'] === undefined) return false;
    return true;
}

export function ControlImportanceScoreFromJSON(json: any): ControlImportanceScore {
    return ControlImportanceScoreFromJSONTyped(json, false);
}

export function ControlImportanceScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlImportanceScore {
    if (json == null) {
        return json;
    }
    return {
        
        'importanceScore': json['importance_score'],
        'riskLevel': json['risk_level'],
    };
}

export function ControlImportanceScoreToJSON(value?: ControlImportanceScore | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'importance_score': value['importanceScore'],
        'risk_level': value['riskLevel'],
    };
}

