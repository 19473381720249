/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CommentStatusEnum } from './CommentStatusEnum';
import {
    CommentStatusEnumFromJSON,
    CommentStatusEnumFromJSONTyped,
    CommentStatusEnumToJSON,
} from './CommentStatusEnum';

/**
 * 
 * @export
 * @interface EvidenceComment
 */
export interface EvidenceComment {
    /**
     * 
     * @type {number}
     * @memberof EvidenceComment
     */
    readonly id: number;
    /**
     * 
     * @type {Date}
     * @memberof EvidenceComment
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EvidenceComment
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof EvidenceComment
     */
    text?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof EvidenceComment
     */
    status?: CommentStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof EvidenceComment
     */
    evidence: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof EvidenceComment
     */
    mentions?: Array<number>;
}



/**
 * Check if a given object implements the EvidenceComment interface.
 */
export function instanceOfEvidenceComment(value: object): value is EvidenceComment {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('evidence' in value) || value['evidence'] === undefined) return false;
    return true;
}

export function EvidenceCommentFromJSON(json: any): EvidenceComment {
    return EvidenceCommentFromJSONTyped(json, false);
}

export function EvidenceCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvidenceComment {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'text': json['text'] == null ? undefined : json['text'],
        'status': json['status'] == null ? undefined : CommentStatusEnumFromJSON(json['status']),
        'evidence': json['evidence'],
        'mentions': json['mentions'] == null ? undefined : json['mentions'],
    };
}

export function EvidenceCommentToJSON(value?: Omit<EvidenceComment, 'id'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'text': value['text'],
        'status': CommentStatusEnumToJSON(value['status']),
        'evidence': value['evidence'],
        'mentions': value['mentions'],
    };
}

