import { create } from "zustand";

import { User } from "shared/context/types/company";

export interface NotificationType {
  id: number;
  created_at: Date;
  updated_at: Date;
  name: string;
  description: string;
  is_read: boolean;
  is_notified: boolean;
  user: number;
  content_type: number;
  object_type: string;
  object_id: number;
  created_by: User | null;
  company: number | null;
  company_id: number | null;
  payload: Record<"feed_id" | "article_id", number> | null;
}

interface NotificationProps {
  notificationList: NotificationType[];
  setNotifications: (notification: NotificationType[]) => void;
}

export const useNotification = create<NotificationProps>((set) => ({
  notificationList: [],
  setNotifications: (notificationList: NotificationType[]) => set({ notificationList }),
}));
