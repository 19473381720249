import ReactSelect, {
  CSSObjectWithLabel,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  GroupBase,
  LoadingIndicatorProps,
  MultiValueRemoveProps,
  NoticeProps,
  OptionProps,
  PlaceholderProps,
  StylesConfig,
  components,
} from "react-select";
import AsyncCreatableSelect, { AsyncCreatableProps } from "react-select/async-creatable";
import { PublicBaseSelectProps } from "react-select/dist/declarations/src/Select";

import { useDarkMode } from "usehooks-ts";

import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

import { optionType } from "shared/components/metadata/types";

import { Spinner } from "./spinner";

export type MultiSelectVariant = "default" | "ghost";

type MultiSelectProps = Partial<
  PublicBaseSelectProps<optionType, boolean, GroupBase<optionType>>
> & {
  variant?: MultiSelectVariant;
  overrideProperties?: StylesConfig<optionType, boolean, GroupBase<optionType>>;
};

type AsyncCreatableMultiSelectProps = Partial<
  AsyncCreatableProps<optionType, boolean, GroupBase<optionType>>
> & {
  variant?: MultiSelectVariant;
  overrideProperties?: StylesConfig<optionType, boolean, GroupBase<optionType>>;
};

type StyleVariants = {
  default: StylesConfig<optionType, boolean, GroupBase<optionType>>;
  ghost: StylesConfig<optionType, boolean, GroupBase<optionType>>;
};

const getBaseStyles = ({
  isDarkMode,
  overrideProperties = {},
}: {
  isDarkMode: boolean;
  overrideProperties?: StylesConfig<optionType, boolean, GroupBase<optionType>>;
}): StyleVariants => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const baseStyles: StyleVariants[keyof StyleVariants] = {
    multiValue: (provided: any) => {
      return {
        ...provided,
        borderRadius: "0.375rem",
        backgroundColor: "#E1EFFE",
        fontFamily: "InterMedium",
        div: {
          fontWeight: "500",
          fontSize: "0.75rem",
          color: "#1C64F2",
          paddingTop: "2px",
          paddingBottom: "2px",
          "&:hover": {
            backgroundColor: "inherit",
            color: "#1C64F2",
            borderRadius: "inherit",
          },
        },
        svg: {
          color: "#1C64F2 !important",
        },
      };
    },
    multiValueLabel: (provided: any) => ({
      ...provided,
      maxWidth: "25ch",
      fontFamily: "InterMedium",
    }),
    option: (
      provided: CSSObjectWithLabel,
      state: OptionProps<optionType, boolean, GroupBase<optionType>>
    ) => ({
      provided,
      padding: "0.375rem 0.5rem",
      cursor: "pointer",
      color: !isDarkMode ? "#111827" : "#C6C6C6",
      backgroundColor: !isDarkMode
        ? state.isFocused
          ? "#F6F6F6"
          : "#ffffff"
        : state.isFocused
        ? "#1d1d1f"
        : "#1d1d1f",
      fontSize: "0.75rem",
      borderColor: "#343435",
      borderRadius: "0.375rem",
      "&:hover": {
        backgroundColor: !isDarkMode ? "#F6F6F6" : "#262628",
      },
    }),
    menuList: (provided: any) => ({
      ...provided,
      zIndex: "50",
      borderRadius: "8px",
      padding: "4px",
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: "20",
      borderRadius: "8px",
      boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)",
      borderColor: isDarkMode ? "#343434" : "#E4E6EA",
      borderWidth: "1px",
      backgroundColor: !isDarkMode
        ? state.isFocused
          ? "#F6F6F6"
          : "#ffffff"
        : state.isFocused
        ? "#262628"
        : "#1d1d1f",
    }),
    control: (provided, state) => {
      return {
        ...provided,
        paddingTop: "2px",
        paddingBottom: "2px",
        fontSize: "0.875rem",
        borderRadius: "0.5rem",
        backgroundColor: !isDarkMode ? "#FFFFFF !important" : "#1D1D1F !important",
        minHeight: "2.375rem",
        borderColor: isDarkMode ? "#343435" : "#E5E7EB",
        boxShadow: "none",
        cursor: state.isDisabled ? "not-allowed" : "pointer",
        opacity: state.isDisabled ? "0.5" : "1",
        "&:hover": {
          borderColor: !isDarkMode ? "#E5E7EB" : "#343435",
        },
      } as CSSObjectWithLabel;
    },
    indicatorsContainer: (provided: any) => {
      return {
        ...provided,
        "& > div": {
          color: "inherit !important",
        },
      };
    },
    indicatorSeparator: (provided: any) => {
      return {
        ...provided,
        display: "none",
      };
    },
    valueContainer: (provided: any) => ({
      ...provided,
    }),
  };

  const overrideStyles = Object.keys(overrideProperties).reduce((acc, key) => {
    const styleKey = key as keyof StyleVariants[keyof StyleVariants];

    acc[styleKey] = (provided: any, state: any) => {
      const baseStyleFn = baseStyles[styleKey];
      const overrideStyleFn = overrideProperties[styleKey];

      return {
        ...(typeof baseStyleFn === "function" ? baseStyleFn(provided, state) : {}),
        ...(typeof overrideStyleFn === "function" ? overrideStyleFn(provided, state) : {}),
      };
    };

    return acc;
  }, {} as StyleVariants[keyof StyleVariants]);

  return {
    default: { ...baseStyles, ...overrideStyles },
    ghost: {
      ...baseStyles,
      control: (base, props) =>
        ({
          ...baseStyles?.control?.(base, props),
          border: "none",
          padding: "0",
          width: "fit-content",
          minHeight: "unset",
        } as CSSObjectWithLabel),
      valueContainer: (base, props) =>
        ({
          ...baseStyles?.valueContainer?.(base, props),
          paddingLeft: "0",
        } as CSSObjectWithLabel),
      ...overrideStyles,
    },
  };
};

export const MultiSelect = ({
  variant = "default",
  overrideProperties = {},
  ...props
}: MultiSelectProps) => {
  const { isDarkMode } = useDarkMode();
  const styles = getBaseStyles({ isDarkMode, overrideProperties });
  const { components, ...rest } = props;

  return (
    <ReactSelect
      styles={styles[variant]}
      components={{
        Option,
        MultiValueRemove,
        Placeholder,
        DropdownIndicator,
        NoOptionsMessage,
        LoadingIndicator,
        ...components,
      }}
      {...rest}
    />
  );
};

export const AsyncCreatableMultiSelect = ({
  variant = "default",
  overrideProperties = {},
  ...props
}: AsyncCreatableMultiSelectProps) => {
  const { isDarkMode } = useDarkMode();
  const styles = getBaseStyles({ isDarkMode, overrideProperties });
  const { components, ...rest } = props;

  return (
    <AsyncCreatableSelect
      styles={styles[variant]}
      components={{
        Option,
        MultiValueRemove,
        Placeholder,
        DropdownIndicator,
        NoOptionsMessage,
        LoadingIndicator,
        ClearIndicator,
        ...components,
      }}
      {...rest}
    />
  );
};

export const LoadingIndicator = (props: LoadingIndicatorProps<optionType>) => {
  return <Spinner {...props} size="xs" />;
};

export const NoOptionsMessage = (props: NoticeProps<optionType>) => {
  return <components.NoOptionsMessage {...props} className="text-sm" />;
};

export const MultiValueRemove = (props: MultiValueRemoveProps<optionType>) => {
  return (
    <components.MultiValueRemove {...props}>
      <XMarkIcon className="h-3.5 w-3.5" />
    </components.MultiValueRemove>
  );
};

export const ClearIndicator = (props: ClearIndicatorProps<optionType>) => {
  return (
    <components.ClearIndicator {...props}>
      <XMarkIcon className="h-3.5 w-3.5 stroke-aurometalsaurus stroke-2" />
    </components.ClearIndicator>
  );
};

export const DropdownIndicator = (
  props: DropdownIndicatorProps<optionType, boolean, GroupBase<optionType>>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className="stroke-1.5 h-3.5 w-3.5 stroke-aurometalsaurus !opacity-100" />
    </components.DropdownIndicator>
  );
};

export const Option = (props: OptionProps<optionType, boolean, GroupBase<optionType>>) => {
  return (
    <components.Option {...props}>
      <div className="flex flex-row items-center justify-between">
        <div className="font-medium"> {props.data.label} </div>
      </div>
    </components.Option>
  );
};

export const Placeholder = (props: PlaceholderProps<optionType>) => {
  return (
    <components.Placeholder {...props}>
      <div className="flex flex-row items-center gap-2">
        <MagnifyingGlassIcon className="h-4 w-4" />
        {props.children}
      </div>
    </components.Placeholder>
  );
};
