import { useParams } from "react-router-dom";

import { client } from "client";

import { useInfiniteQuery } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";

import {
  DropdownSortEnum,
  DropdownSortEnumValues,
  FeedFiltersOmitted,
} from "modules/horizonScanning/constants";

const useFeedArticles = (params: {
  feedId: number;
  filters: FeedFiltersOmitted;
  sortBy?: DropdownSortEnumValues;
  enabled?: boolean;
}) => {
  const { feedId: id } = useParams();
  const { feedId, filters, enabled = true, sortBy = DropdownSortEnum.date } = params;

  const { currentCompany } = useCompany();

  return useInfiniteQuery({
    queryKey: ["feed-articles", feedId, currentCompany.id, filters, sortBy],
    queryFn: ({ pageParam = 1 }) =>
      id === "mega"
        ? client.horizonScanning.horizonFeedsArticlesMegaFeedList({
            ...filters,
            sort: sortBy,
            feedId: feedId.toString(),
            companyId: currentCompany.id.toFixed(),
            page: pageParam,
            pageSize: 10,
          })
        : client.horizonScanning.horizonFeedsArticlesList({
            ...filters, // allow to override the default filters except for pages
            sort: sortBy,
            feedId: feedId,
            companyId: currentCompany.id,
            page: pageParam,
            pageSize: 10,
          }),

    getNextPageParam: (lastPage) => lastPage.nextPage,
    refetchOnMount: false,
    enabled,
  });
};

export default useFeedArticles;
