/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskConnectionRequest
 */
export interface TaskConnectionRequest {
    /**
     * 
     * @type {number}
     * @memberof TaskConnectionRequest
     */
    taskId: number;
}

/**
 * Check if a given object implements the TaskConnectionRequest interface.
 */
export function instanceOfTaskConnectionRequest(value: object): value is TaskConnectionRequest {
    if (!('taskId' in value) || value['taskId'] === undefined) return false;
    return true;
}

export function TaskConnectionRequestFromJSON(json: any): TaskConnectionRequest {
    return TaskConnectionRequestFromJSONTyped(json, false);
}

export function TaskConnectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskConnectionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'taskId': json['task_id'],
    };
}

export function TaskConnectionRequestToJSON(value?: TaskConnectionRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'task_id': value['taskId'],
    };
}

