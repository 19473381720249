import { useEffect, useRef } from "react";
import { toast } from "react-toastify";

import { AxiosError } from "axios";
import { Spinner } from "flowbite-react";
import set from "lodash/set";
import { usePostHog } from "posthog-js/react";

import { useMutation } from "@tanstack/react-query";

import ServerErrors from "shared/components/server-errors";
import Modal from "shared/componentsV2/common-modal";
import { useCompany } from "shared/context/CompanyProvider";
import { ADD_TASK_FORM } from "shared/helpers/constant";
import useDocumentBodyRef from "shared/hooks/useDocumentBodyRef";
import { GetTaskResultType } from "shared/types";

import { StatusResultType } from "modules/tasks/statuses/types";

import { addTask } from "../api";
import { TaskForm } from "../components/TaskForm";
import { useTaskForm } from "../hooks/useTaskForm";
import { RelatedObjectType, RelatedObjectTypeName, TaskResultType } from "../types";

const TaskFormModal = ({
  isModalOpen,
  eventDate,
  statusId,
  statuses,
  relatedObject,
  relatedObjectType,
  teamId,
  onCreate,
  onClose,
  isTeamEditable = true,
  relatedObjectTypeName,
}: {
  isModalOpen: boolean;
  eventDate?: string;
  statusId?: number;
  statuses?: Array<StatusResultType>;
  relatedObject?: RelatedObjectType;
  relatedObjectType?: string;
  teamId: number;
  onCreate: (task?: GetTaskResultType) => void;
  onClose: () => void;
  isTeamEditable?: boolean;
  relatedObjectTypeName?: RelatedObjectTypeName;
}) => {
  const posthog = usePostHog();

  const { currentCompany } = useCompany();

  const { documentBodyRef } = useDocumentBodyRef();

  const onTaskUpdated = useRef<null | ((task: GetTaskResultType) => Promise<void>)>();

  const setOnTaskCreated = (onUpdated: null | ((task: GetTaskResultType) => Promise<void>)) => {
    onTaskUpdated.current = onUpdated;
  };

  const taskFormHook = useTaskForm(teamId, async () => {
    const payload = relatedObjectType
      ? set(taskFormHook.values, relatedObjectType, relatedObject?.id)
      : taskFormHook.values;

    const result = await onAddTask(payload);

    if (onTaskUpdated.current) {
      await onTaskUpdated.current(result.data);
    }

    onClose();

    return result;
  });

  const {
    isLoading: newTaskLoading,
    mutateAsync: onAddTask,
    isError: newTaskHasError,
    error: newTaskError,
  } = useMutation(
    (data: TaskResultType) => {
      return addTask(data);
    },
    {
      onSuccess: (response) => {
        posthog.capture("taskCreated");
        taskFormHook.resetForm();
        onCreate(response?.data);
      },
      onError: (e: AxiosError): AxiosError => {
        toast("Something went wrong", { type: "error" });
        return e;
      },
    }
  );

  useEffect(() => {
    if (eventDate) {
      taskFormHook.setFieldValue("due_date", eventDate);
    }

    taskFormHook.setFieldValue("status", statusId ? statusId : statuses?.[0]?.id);
    taskFormHook.setFieldValue(
      "status_object",
      statusId ? statuses?.find((status) => status.id === statusId) : statuses?.[0]
    );
  }, [eventDate, statusId, isModalOpen]);

  return (
    <Modal
      root={documentBodyRef}
      show={isModalOpen}
      size="2xl"
      popup={true}
      onClose={() => {
        taskFormHook.resetForm();
        onClose();
      }}
      onClick={(event) => event.stopPropagation()}
      dismissible={true}
    >
      <Modal.Header className="border-b dark:!border-thunders">
        <div className="text-lg">Create Task</div>
      </Modal.Header>
      <Modal.Body>
        {/* tasks common form */}
        {isModalOpen && (
          <TaskForm
            isTeamEditable={isTeamEditable}
            formName={ADD_TASK_FORM}
            relatedObject={relatedObject}
            relatedObjectType={relatedObjectTypeName}
            taskForm={taskFormHook}
            teamId={teamId}
            companyId={currentCompany?.id}
            setOnSuccess={setOnTaskCreated}
            handleClose={onClose}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="justify-end border-t dark:!border-thunders maxMd:p-4 mac13Inch:py-4">
        <button
          className="btn_primary maxMd:w-full"
          data-testid="create_task_btn"
          type="submit"
          onClick={() => taskFormHook.handleSubmit()}
        >
          <Spinner
            size="md"
            light={true}
            hidden={!newTaskLoading}
            className="mr-3 fill-crayolasblue stroke-crayolasblue"
          />
          Create Task
        </button>
        {newTaskHasError ? <ServerErrors className="mb-4" error={newTaskError} /> : ""}
      </Modal.Footer>
    </Modal>
  );
};

export default TaskFormModal;
