import { client } from "client";
import { PaginatedTodoList, PatchedTodoRequest, TodoRequest } from "openapi";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useFeed from "modules/horizonScanning/shared/hooks/useFeed";

import { useArticleContext } from "../contexts/ArticleContext";

export const useArticleTodos = () => {
  const { feedId: id, articleId, companyId, isPublic } = useArticleContext();
  const queryClient = useQueryClient();
  const { data: feed } = useFeed({ feedId: id });
  const feedId = Number(id) || 0; // this is for mega feeds

  const fetchArticleTodos = useQuery({
    queryKey: ["article-todos", { feedId, companyId, articleId }],
    queryFn: () =>
      client.horizonScanning.horizonFeedsArticlesTodosList({
        feedId,
        companyId,
        articleId,
        pageSize: 40,
      }),
    enabled: !isPublic,
  });

  const createArticleTodo = useMutation({
    mutationFn: (todoRequest: TodoRequest) =>
      client.horizonScanning.horizonFeedsArticlesTodosCreate({
        feedId,
        companyId,
        articleId,
        todoRequest,
      }),
  });

  const invalidateTodoQuery = () => {
    queryClient.invalidateQueries({
      queryKey: ["article-todos", { feedId, companyId, articleId }],
    });
  };

  const updateArticleTodo = useMutation({
    mutationFn: ({ id, ...todoRequest }: PatchedTodoRequest & { id: number }) =>
      client.horizonScanning.horizonFeedsArticlesTodosPartialUpdate({
        feedId,
        companyId,
        articleId,
        id,
        patchedTodoRequest: todoRequest,
      }),
    onMutate: async (updatedTodo) => {
      await queryClient.cancelQueries(["article-todos", { feedId, companyId, articleId }]);
      const previousTodos = queryClient.getQueryData<PaginatedTodoList>([
        "article-todos",
        { feedId, companyId, articleId },
      ]);

      queryClient.setQueryData<PaginatedTodoList>(
        ["article-todos", { feedId, companyId, articleId }],
        () =>
          previousTodos
            ? {
                ...previousTodos,
                results: previousTodos?.results.map((todo) =>
                  todo.id === updatedTodo.id ? { ...todo, ...updatedTodo } : todo
                ),
              }
            : undefined
      );

      return { previousTodos };
    },
    onError: (err, updatedTodo, context) => {
      queryClient.setQueryData(
        ["article-todos", { feedId, companyId, articleId }],
        context?.previousTodos
      );
    },
    onSuccess: (newTodo) => {
      queryClient.setQueryData<PaginatedTodoList>(
        ["article-todos", { feedId, companyId, articleId }],
        (old) =>
          old
            ? {
                ...old,
                results: old.results.map((todo) => (todo.id === newTodo.id ? newTodo : todo)),
              }
            : undefined
      );
    },
  });

  const feedArticleTodos = fetchArticleTodos.data?.results ?? [];
  const articleTodos = feedArticleTodos.filter((todo) => todo.feedArticle !== null);
  const todos =
    articleTodos.length > 0 ? articleTodos : feed?.todos.filter((todo) => !todo.feedArticle) ?? [];

  return {
    todos,
    totalTodosLength: todos.length,
    completedTodosLength: todos.filter((e) => e.isCompleted).length,
    fetchArticleTodos,
    updateArticleTodo,
    createArticleTodo,
    invalidateTodoQuery,
  };
};
