import { client } from "client";
import { FeedView } from "openapi";

import { useQuery } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";

interface UseFeedProps {
  feedId: number;
  onSuccess?: (data: FeedView) => void;
}

const useFeed = ({ feedId, onSuccess }: UseFeedProps) => {
  const { currentCompany } = useCompany();
  return useQuery({
    queryKey: ["feed", { feedId, companyId: currentCompany.id }],
    queryFn: () =>
      client.horizonScanning.horizonFeedsRetrieve({
        id: String(feedId),
        companyId: String(currentCompany.id),
      }),
    enabled: !!feedId,
    onSuccess,
  });
};

export default useFeed;
