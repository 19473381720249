import { Spinner as FlowbiteSpinner, SpinnerColors } from "flowbite-react";

type SpinnerProps = {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  color?: keyof SpinnerColors;
  className?: string;
};

export const Spinner = ({ size = "md", color = "gray", className }: SpinnerProps) => {
  return <FlowbiteSpinner size={size} color={color} className={className} />;
};
