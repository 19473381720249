/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimilarObligation
 */
export interface SimilarObligation {
    /**
     * 
     * @type {string}
     * @memberof SimilarObligation
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SimilarObligation
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof SimilarObligation
     */
    readonly authorityDocumentId: number;
    /**
     * 
     * @type {string}
     * @memberof SimilarObligation
     */
    authorityDocumentName: string;
}

/**
 * Check if a given object implements the SimilarObligation interface.
 */
export function instanceOfSimilarObligation(value: object): value is SimilarObligation {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('authorityDocumentId' in value) || value['authorityDocumentId'] === undefined) return false;
    if (!('authorityDocumentName' in value) || value['authorityDocumentName'] === undefined) return false;
    return true;
}

export function SimilarObligationFromJSON(json: any): SimilarObligation {
    return SimilarObligationFromJSONTyped(json, false);
}

export function SimilarObligationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimilarObligation {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
        'authorityDocumentId': json['authority_document_id'],
        'authorityDocumentName': json['authority_document_name'],
    };
}

export function SimilarObligationToJSON(value?: Omit<SimilarObligation, 'id'|'authority_document_id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'authority_document_name': value['authorityDocumentName'],
    };
}

