/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DocumentPriority } from './DocumentPriority';
import {
    DocumentPriorityFromJSON,
    DocumentPriorityFromJSONTyped,
    DocumentPriorityToJSON,
} from './DocumentPriority';

/**
 * 
 * @export
 * @interface TaskMini
 */
export interface TaskMini {
    /**
     * 
     * @type {number}
     * @memberof TaskMini
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof TaskMini
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TaskMini
     */
    status?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof TaskMini
     */
    dueDate?: Date | null;
    /**
     * 
     * @type {DocumentPriority}
     * @memberof TaskMini
     */
    priority?: DocumentPriority | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof TaskMini
     */
    assignees?: Array<number>;
}

/**
 * Check if a given object implements the TaskMini interface.
 */
export function instanceOfTaskMini(value: object): value is TaskMini {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function TaskMiniFromJSON(json: any): TaskMini {
    return TaskMiniFromJSONTyped(json, false);
}

export function TaskMiniFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskMini {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'] == null ? undefined : json['status'],
        'dueDate': json['due_date'] == null ? undefined : (new Date(json['due_date'])),
        'priority': json['priority'] == null ? undefined : DocumentPriorityFromJSON(json['priority']),
        'assignees': json['assignees'] == null ? undefined : json['assignees'],
    };
}

export function TaskMiniToJSON(value?: Omit<TaskMini, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'status': value['status'],
        'due_date': value['dueDate'] == null ? undefined : ((value['dueDate'] as any).toISOString().substring(0,10)),
        'priority': DocumentPriorityToJSON(value['priority']),
        'assignees': value['assignees'],
    };
}

