import { useParams } from "react-router-dom";

import { HorizonFeedsArticlesListImpactEnum, HorizonFeedsArticlesListStatusEnum } from "openapi";

import { useAppliedFeedFilters } from "../AppliedFeedFiltersContext";
import { feedViewToFilters } from "../constants";
import useFeedArticles from "../hooks/useFeedArticles";
import useFeed from "../shared/hooks/useFeed";
import { DropdownFilterEnum } from "../types";

export const useFeedArticlesWithFeed = ({ feedId }: { feedId: number }) => {
  const { feedId: id } = useParams();
  const { appliedFilters, uiFilter, debouncedSearch, setAppliedFilters } = useAppliedFeedFilters();
  const feedQuery = useFeed({
    feedId,
    onSuccess: (feed) => {
      const feedFilters = feedViewToFilters(feed);
      setAppliedFilters(feedFilters);
    },
  });

  const feedArticlesQuery = useFeedArticles({
    feedId,
    sortBy: uiFilter.sortBy,
    filters: {
      ...appliedFilters,
      query: debouncedSearch ?? undefined,
      assignees: uiFilter.assignedTo.map(Number),
      tags: uiFilter.tags,
      status: uiFilter.status.length
        ? (uiFilter.status as HorizonFeedsArticlesListStatusEnum[])
        : undefined,
      impact: uiFilter.impact.length
        ? (uiFilter.impact as HorizonFeedsArticlesListImpactEnum[])
        : undefined,
      showOnly:
        uiFilter.dropdownFilter === DropdownFilterEnum.comments
          ? "mentioned"
          : uiFilter.dropdownFilter,
    },
    enabled:
      id === "mega" || id === "all" || (!!feedQuery.data && Object.keys(appliedFilters).length > 0),
  });

  return { feedArticlesQuery, feedQuery };
};
