import { createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { Article } from "openapi";

import { UseQueryResult } from "@tanstack/react-query";

import { useArticle, useUpdateArticle } from "../hooks/useArticles";

type ArticleContextProps = {
  // number or 0 for mega feeds or all articles
  feedId: number;
  articleId: number;
  companyId: number;
  isPublic: boolean;
};

type ArticleContextType = {
  isMegaFeedPage: boolean;
  isAllArticlesPage: boolean;
  articleQuery: UseQueryResult<Article, unknown>;
  shareModalArticle: Article | undefined;
  setShareModalArticle: React.Dispatch<React.SetStateAction<Article | undefined>>;
  showToDosModal: boolean;
  setShowToDosModal: React.Dispatch<React.SetStateAction<boolean>>;
} & ArticleContextProps &
  Omit<ReturnType<typeof useUpdateArticle>, "isLoading" | "isSuccess" | "isError">;

const ArticleContext = createContext<ArticleContextType>({} as ArticleContextType);

export const ArticleProvider = ({
  children,
  ...props
}: { children: React.ReactNode } & ArticleContextProps) => {
  const { feedId: id } = useParams();
  const articleQuery = useArticle({
    ...props,
    feedId: Number(props.feedId) || 0,
    id: props.articleId,
  });
  const articleActions = useUpdateArticle({
    ...props,
    feedId: Number(props.feedId) || 0,
    id: props.articleId,
  });

  const [shareModalArticle, setShareModalArticle] = useState<Article>();
  const [showToDosModal, setShowToDosModal] = useState(false);

  return (
    <ArticleContext.Provider
      value={{
        ...props,
        ...articleActions,
        feedId: Number(props.feedId) || 0,
        isMegaFeedPage: id === "mega",
        isAllArticlesPage: id === "all" || id === "0",
        articleQuery,
        shareModalArticle,
        setShareModalArticle,
        showToDosModal,
        setShowToDosModal,
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};

export const useArticleContext = () => useContext(ArticleContext);
