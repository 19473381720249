import { useCallback } from "react";

import { create } from "zustand";

type Params = {
  feedId: ID;
  articleId: ID;
  // companyId: ID;
};

type FeedActionState = {
  savedFeedArticles: { [key: string]: boolean };
  readFeedArticles: { [key: string]: boolean };
  archivedFeedArticles: { [key: string]: boolean };
  isSaved: (props: Params) => boolean;
  isRead: (props: Params) => boolean;
  isArchived: (props: Params) => boolean;
  read: (props: Params) => void;
  unread: (props: Params) => void;
  save: (props: Params) => void;
  unsave: (props: Params) => void;
  archive: (props: Params) => void;
  unarchive: (props: Params) => void;
  areAllSaved: (ids: Params[]) => boolean;
  areAllRead: (ids: Params[]) => boolean;
  areAllArchived: (ids: Params[]) => boolean;
  bulkArchive: (ids: Params[]) => void;
  bulkRead: (ids: Params[]) => void;
  bulkSave: (ids: Params[]) => void;
  bulkUnarchive: (ids: Params[]) => void;
  bulkUnread: (ids: Params[]) => void;
  bulkUnsave: (ids: Params[]) => void;
};
const idPrefix = (feedId: ID) => `feed_${feedId}-article_`;
const getId = (props: Params) => `feed_${props.feedId}-article_${props.articleId}`;

const useFeedActionState = create<FeedActionState>((set, get) => ({
  readFeedArticles: {},
  savedFeedArticles: {},
  archivedFeedArticles: {},

  isRead: (props: Params) => get().readFeedArticles[getId(props)],
  isSaved: (props: Params) => get().savedFeedArticles[getId(props)],
  isArchived: (props: Params) => get().archivedFeedArticles[getId(props)],

  save: (props: Params) =>
    set((state) => ({
      savedFeedArticles: {
        ...state.savedFeedArticles,
        [getId(props)]: true,
      },
    })),

  unsave: (props: Params) =>
    set((state) => ({
      savedFeedArticles: {
        ...state.savedFeedArticles,
        [getId(props)]: false,
      },
    })),

  read: (props: Params) =>
    set((state) => ({
      readFeedArticles: {
        ...state.readFeedArticles,
        [getId(props)]: true,
      },
    })),

  unread: (props: Params) =>
    set((state) => ({
      readFeedArticles: {
        ...state.readFeedArticles,
        [getId(props)]: false,
      },
    })),

  archive: (props: Params) =>
    set((state) => ({
      archivedFeedArticles: { ...state.archivedFeedArticles, [getId(props)]: true },
    })),

  unarchive: (props: Params) =>
    set((state) => ({
      archivedFeedArticles: { ...state.archivedFeedArticles, [getId(props)]: false },
    })),

  areAllSaved: (props: Params[]) => props.every((id) => get().isSaved(id) === true),
  areAllRead: (props: Params[]) => props.every((id) => get().isRead(id) === true),
  areAllArchived: (props: Params[]) => props.every((id) => get().isArchived(id) === true),

  bulkArchive: (props: Params[]) => props.forEach((id) => get().archive(id)),
  bulkRead: (props: Params[]) => props.forEach((id) => get().read(id)),
  bulkSave: (props: Params[]) => props.forEach((id) => get().save(id)),
  bulkUnarchive: (props: Params[]) => props.forEach((id) => get().unarchive(id)),
  bulkUnread: (props: Params[]) => props.forEach((id) => get().unread(id)),
  bulkUnsave: (props: Params[]) => props.forEach((id) => get().unsave(id)),
}));

export const useArchivedFeedArticles = (feedId: ID) => {
  return useFeedActionState((state) =>
    Object.keys(state.archivedFeedArticles)
      .filter((key) => key.startsWith(idPrefix(feedId)))
      .map((key) => key.split("-article_")[1])
  );
};

export const useFeedActionStateCheck = (ids: Params[]) => {
  const state = useFeedActionState();
  return {
    areAllSaved: state.areAllSaved(ids),
    areAllRead: state.areAllRead(ids),
    areAllArchived: state.areAllArchived(ids),
  };
};

export const useFeedItemActionState = (props: Params) => {
  return useFeedActionState(
    useCallback(
      (state) => ({
        isSaved: state.isSaved(props),
        isRead: state.isRead(props),
        isArchived: state.isArchived(props),
      }),
      [props.articleId, props.feedId]
    )
  );
};

export const useFeedItemActions = (props: Params) => {
  return useFeedActionState(
    useCallback(
      (state) => ({
        archive: () => state.archive(props),
        unarchive: () => state.unarchive(props),
        save: () => state.save(props),
        unsave: () => state.unsave(props),
        read: () => state.read(props),
        unread: () => state.unread(props),
      }),
      [props.articleId, props.feedId]
    )
  );
};

export const useFeedItemBulkActions = () => {
  return useFeedActionState(
    useCallback(
      (state) => ({
        bulkArchive: (ids: Params[]) => state.bulkArchive(ids),
        bulkRead: (ids: Params[]) => state.bulkRead(ids),
        bulkSave: (ids: Params[]) => state.bulkSave(ids),
        bulkUnarchive: (ids: Params[]) => state.bulkUnarchive(ids),
        bulkUnread: (ids: Params[]) => state.bulkUnread(ids),
        bulkUnsave: (ids: Params[]) => state.bulkUnsave(ids),
      }),
      []
    )
  );
};
