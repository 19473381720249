/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `SEVERE` - Severe
 * * `HIGH` - High
 * * `SIGNIFICANT` - Significant
 * * `MODERATE` - Moderate
 * * `LOW` - Low
 * @export
 */
export const RiskLevelEnum = {
    Severe: 'SEVERE',
    High: 'HIGH',
    Significant: 'SIGNIFICANT',
    Moderate: 'MODERATE',
    Low: 'LOW'
} as const;
export type RiskLevelEnum = typeof RiskLevelEnum[keyof typeof RiskLevelEnum];


export function instanceOfRiskLevelEnum(value: any): boolean {
    for (const key in RiskLevelEnum) {
        if (Object.prototype.hasOwnProperty.call(RiskLevelEnum, key)) {
            if (RiskLevelEnum[key as keyof typeof RiskLevelEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RiskLevelEnumFromJSON(json: any): RiskLevelEnum {
    return RiskLevelEnumFromJSONTyped(json, false);
}

export function RiskLevelEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): RiskLevelEnum {
    return json as RiskLevelEnum;
}

export function RiskLevelEnumToJSON(value?: RiskLevelEnum | null): any {
    return value as any;
}

