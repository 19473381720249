/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddAssigneesRequest,
  AddImpactRequest,
  AddStatusRequest,
  AddTagsRequest,
  Article,
  ArticleList,
  Feed,
  FeedArticleComment,
  FeedArticleCommentRequest,
  FeedRequest,
  FeedView,
  InstitutionType,
  PaginatedArticleListList,
  PaginatedFeedArticleCommentList,
  PaginatedFeedViewList,
  PaginatedInstitutionTypeList,
  PaginatedPublisherList,
  PaginatedRegionList,
  PaginatedRegulationList,
  PaginatedTagList,
  PaginatedTaskList,
  PaginatedTodoList,
  PaginatedTopicList,
  PatchedFeedArticleCommentRequest,
  PatchedFeedRequest,
  PatchedTagRequest,
  PatchedTaskRequest,
  PatchedTodoRequest,
  Publisher,
  Region,
  Regulation,
  Tag,
  TagRequest,
  Task,
  TaskRequest,
  Todo,
  TodoRequest,
  Topic,
} from '../models/index';
import {
    AddAssigneesRequestFromJSON,
    AddAssigneesRequestToJSON,
    AddImpactRequestFromJSON,
    AddImpactRequestToJSON,
    AddStatusRequestFromJSON,
    AddStatusRequestToJSON,
    AddTagsRequestFromJSON,
    AddTagsRequestToJSON,
    ArticleFromJSON,
    ArticleToJSON,
    ArticleListFromJSON,
    ArticleListToJSON,
    FeedFromJSON,
    FeedToJSON,
    FeedArticleCommentFromJSON,
    FeedArticleCommentToJSON,
    FeedArticleCommentRequestFromJSON,
    FeedArticleCommentRequestToJSON,
    FeedRequestFromJSON,
    FeedRequestToJSON,
    FeedViewFromJSON,
    FeedViewToJSON,
    InstitutionTypeFromJSON,
    InstitutionTypeToJSON,
    PaginatedArticleListListFromJSON,
    PaginatedArticleListListToJSON,
    PaginatedFeedArticleCommentListFromJSON,
    PaginatedFeedArticleCommentListToJSON,
    PaginatedFeedViewListFromJSON,
    PaginatedFeedViewListToJSON,
    PaginatedInstitutionTypeListFromJSON,
    PaginatedInstitutionTypeListToJSON,
    PaginatedPublisherListFromJSON,
    PaginatedPublisherListToJSON,
    PaginatedRegionListFromJSON,
    PaginatedRegionListToJSON,
    PaginatedRegulationListFromJSON,
    PaginatedRegulationListToJSON,
    PaginatedTagListFromJSON,
    PaginatedTagListToJSON,
    PaginatedTaskListFromJSON,
    PaginatedTaskListToJSON,
    PaginatedTodoListFromJSON,
    PaginatedTodoListToJSON,
    PaginatedTopicListFromJSON,
    PaginatedTopicListToJSON,
    PatchedFeedArticleCommentRequestFromJSON,
    PatchedFeedArticleCommentRequestToJSON,
    PatchedFeedRequestFromJSON,
    PatchedFeedRequestToJSON,
    PatchedTagRequestFromJSON,
    PatchedTagRequestToJSON,
    PatchedTaskRequestFromJSON,
    PatchedTaskRequestToJSON,
    PatchedTodoRequestFromJSON,
    PatchedTodoRequestToJSON,
    PublisherFromJSON,
    PublisherToJSON,
    RegionFromJSON,
    RegionToJSON,
    RegulationFromJSON,
    RegulationToJSON,
    TagFromJSON,
    TagToJSON,
    TagRequestFromJSON,
    TagRequestToJSON,
    TaskFromJSON,
    TaskToJSON,
    TaskRequestFromJSON,
    TaskRequestToJSON,
    TodoFromJSON,
    TodoToJSON,
    TodoRequestFromJSON,
    TodoRequestToJSON,
    TopicFromJSON,
    TopicToJSON,
} from '../models/index';

export interface HorizonArticleCommentsCreateRequest {
    feedArticleCommentRequest: FeedArticleCommentRequest;
    article?: number;
    company?: number;
    feed?: number;
}

export interface HorizonArticleCommentsDestroyRequest {
    id: number;
    article?: number;
    company?: number;
    feed?: number;
}

export interface HorizonArticleCommentsListRequest {
    article?: number;
    company?: number;
    feed?: number;
    page?: number;
    pageSize?: number;
    status?: HorizonArticleCommentsListStatusEnum;
}

export interface HorizonArticleCommentsPartialUpdateRequest {
    id: number;
    patchedFeedArticleCommentRequest?: PatchedFeedArticleCommentRequest;
}

export interface HorizonArticleCommentsRetrieveRequest {
    id: number;
    article?: number;
    company?: number;
    feed?: number;
}

export interface HorizonArticleCommentsUpdateRequest {
    id: number;
    feedArticleCommentRequest: FeedArticleCommentRequest;
    article?: number;
    company?: number;
    feed?: number;
}

export interface HorizonFeedsArticlesAddImpactCreateRequest {
    companyId: number;
    feedId: number;
    id: number;
    addImpactRequest?: AddImpactRequest;
}

export interface HorizonFeedsArticlesAddStatusCreateRequest {
    companyId: number;
    feedId: number;
    id: number;
    addStatusRequest?: AddStatusRequest;
}

export interface HorizonFeedsArticlesAddTagsCreateRequest {
    companyId: number;
    feedId: number;
    id: number;
    addTagsRequest?: AddTagsRequest;
}

export interface HorizonFeedsArticlesArchiveCreateRequest {
    companyId: number;
    feedId: number;
    id: number;
}

export interface HorizonFeedsArticlesExportRetrieveRequest {
    companyId: string;
    feedId: string;
}

export interface HorizonFeedsArticlesListRequest {
    companyId: number;
    feedId: number;
    assignees?: Array<number>;
    impact?: Array<HorizonFeedsArticlesListImpactEnum | null>;
    institutionTypes?: Array<number>;
    jurisdictions?: Array<number>;
    keywordsAnd?: Array<string>;
    keywordsNot?: Array<string>;
    keywordsOr?: Array<string>;
    originalLanguage?: boolean;
    page?: number;
    pageSize?: number;
    publicationEndDate?: Date;
    publicationStartDate?: Date;
    publishers?: Array<number>;
    query?: string;
    regulations?: Array<number>;
    rollingWindow?: HorizonFeedsArticlesListRollingWindowEnum;
    showOnly?: HorizonFeedsArticlesListShowOnlyEnum;
    sort?: string;
    status?: Array<HorizonFeedsArticlesListStatusEnum | null>;
    tags?: Array<string>;
}

export interface HorizonFeedsArticlesMarkAsReadCreateRequest {
    companyId: number;
    feedId: number;
    id: number;
}

export interface HorizonFeedsArticlesMarkAsUnreadCreateRequest {
    companyId: number;
    feedId: number;
    id: number;
}

export interface HorizonFeedsArticlesMegaFeedListRequest {
    companyId: string;
    feedId: string;
    assignees?: Array<number>;
    impact?: Array<HorizonFeedsArticlesMegaFeedListImpactEnum | null>;
    institutionTypes?: Array<number>;
    jurisdictions?: Array<number>;
    keywordsAnd?: Array<string>;
    keywordsNot?: Array<string>;
    keywordsOr?: Array<string>;
    originalLanguage?: boolean;
    page?: number;
    pageSize?: number;
    publicationEndDate?: Date;
    publicationStartDate?: Date;
    publishers?: Array<number>;
    query?: string;
    regulations?: Array<number>;
    rollingWindow?: HorizonFeedsArticlesMegaFeedListRollingWindowEnum;
    showOnly?: HorizonFeedsArticlesMegaFeedListShowOnlyEnum;
    sort?: string;
    status?: Array<HorizonFeedsArticlesMegaFeedListStatusEnum | null>;
    tags?: Array<string>;
}

export interface HorizonFeedsArticlesRetrieveRequest {
    companyId: number;
    feedId: number;
    id: number;
}

export interface HorizonFeedsArticlesSaveForLaterCreateRequest {
    companyId: number;
    feedId: number;
    id: number;
}

export interface HorizonFeedsArticlesSimilarArticlesListRequest {
    companyId: number;
    feedId: number;
    id: number;
    sort?: string;
}

export interface HorizonFeedsArticlesTasksCreateRequest {
    articleId: number;
    companyId: number;
    feedId: number;
    taskRequest: TaskRequest;
}

export interface HorizonFeedsArticlesTasksDestroyRequest {
    articleId: number;
    companyId: number;
    feedId: number;
    id: number;
}

export interface HorizonFeedsArticlesTasksListRequest {
    articleId: number;
    companyId: number;
    feedId: number;
    page?: number;
    pageSize?: number;
}

export interface HorizonFeedsArticlesTasksPartialUpdateRequest {
    articleId: string;
    companyId: string;
    feedId: string;
    id: number;
    patchedTaskRequest?: PatchedTaskRequest;
}

export interface HorizonFeedsArticlesTasksRetrieveRequest {
    articleId: number;
    companyId: number;
    feedId: number;
    id: number;
}

export interface HorizonFeedsArticlesTasksUpdateRequest {
    articleId: number;
    companyId: number;
    feedId: number;
    id: number;
    taskRequest: TaskRequest;
}

export interface HorizonFeedsArticlesTodosCreateRequest {
    articleId: number;
    companyId: number;
    feedId: number;
    todoRequest: TodoRequest;
}

export interface HorizonFeedsArticlesTodosDestroyRequest {
    articleId: number;
    companyId: number;
    feedId: number;
    id: number;
}

export interface HorizonFeedsArticlesTodosListRequest {
    articleId: number;
    companyId: number;
    feedId: number;
    isCompleted?: boolean;
    ordering?: string;
    page?: number;
    pageSize?: number;
}

export interface HorizonFeedsArticlesTodosPartialUpdateRequest {
    articleId: number;
    companyId: number;
    feedId: number;
    id: number;
    patchedTodoRequest?: PatchedTodoRequest;
}

export interface HorizonFeedsArticlesTodosRetrieveRequest {
    articleId: number;
    companyId: number;
    feedId: number;
    id: number;
}

export interface HorizonFeedsArticlesTodosUpdateRequest {
    articleId: number;
    companyId: number;
    feedId: number;
    id: number;
    todoRequest: TodoRequest;
}

export interface HorizonFeedsArticlesUnarchiveCreateRequest {
    companyId: number;
    feedId: number;
    id: number;
}

export interface HorizonFeedsArticlesUnsaveForLaterCreateRequest {
    companyId: number;
    feedId: number;
    id: number;
}

export interface HorizonFeedsArticlesUpdateAssigneesCreateRequest {
    companyId: number;
    feedId: number;
    id: number;
    addAssigneesRequest?: AddAssigneesRequest;
}

export interface HorizonFeedsCreateRequest {
    companyId: number;
    feedRequest: FeedRequest;
}

export interface HorizonFeedsDestroyRequest {
    companyId: number;
    id: number;
}

export interface HorizonFeedsLeaveCreateRequest {
    companyId: number;
    id: number;
}

export interface HorizonFeedsListRequest {
    companyId: number;
    ordering?: string;
    page?: number;
    pageSize?: number;
    search?: string;
}

export interface HorizonFeedsPartialUpdateRequest {
    companyId: string;
    id: string;
    patchedFeedRequest?: PatchedFeedRequest;
}

export interface HorizonFeedsRetrieveRequest {
    companyId: string;
    id: string;
}

export interface HorizonFeedsTodosCreateRequest {
    companyId: string;
    feedId: string;
    todoRequest: TodoRequest;
}

export interface HorizonFeedsTodosDestroyRequest {
    companyId: string;
    feedId: string;
    id: number;
}

export interface HorizonFeedsTodosListRequest {
    companyId: string;
    feedId: string;
    page?: number;
    pageSize?: number;
}

export interface HorizonFeedsTodosPartialUpdateRequest {
    companyId: string;
    feedId: string;
    id: number;
    patchedTodoRequest?: PatchedTodoRequest;
}

export interface HorizonFeedsTodosRetrieveRequest {
    companyId: string;
    feedId: string;
    id: number;
}

export interface HorizonFeedsTodosUpdateRequest {
    companyId: string;
    feedId: string;
    id: number;
    todoRequest: TodoRequest;
}

export interface HorizonFeedsUpdateRequest {
    companyId: number;
    id: number;
    feedRequest: FeedRequest;
}

export interface HorizonInstitutionTypesListRequest {
    page?: number;
    pageSize?: number;
}

export interface HorizonInstitutionTypesRetrieveRequest {
    id: number;
}

export interface HorizonJurisdictionsListRequest {
    page?: number;
    pageSize?: number;
}

export interface HorizonJurisdictionsRetrieveRequest {
    id: number;
}

export interface HorizonPublishersListRequest {
    page?: number;
    pageSize?: number;
    regionId?: number;
    regionIdIn?: Array<number>;
}

export interface HorizonPublishersRetrieveRequest {
    id: number;
}

export interface HorizonRegulationsListRequest {
    page?: number;
    pageSize?: number;
}

export interface HorizonRegulationsRetrieveRequest {
    id: number;
}

export interface HorizonTagsCreateRequest {
    companyId: number;
    tagRequest: TagRequest;
}

export interface HorizonTagsDestroyRequest {
    companyId: number;
    id: number;
}

export interface HorizonTagsListRequest {
    companyId: number;
    page?: number;
    pageSize?: number;
}

export interface HorizonTagsPartialUpdateRequest {
    companyId: string;
    id: number;
    patchedTagRequest?: PatchedTagRequest;
}

export interface HorizonTagsRetrieveRequest {
    companyId: number;
    id: number;
}

export interface HorizonTagsUpdateRequest {
    companyId: number;
    id: number;
    tagRequest: TagRequest;
}

export interface HorizonTopicsListRequest {
    page?: number;
    pageSize?: number;
}

export interface HorizonTopicsRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class HorizonApi extends runtime.BaseAPI {

    /**
     */
    async horizonArticleCommentsCreateRaw(requestParameters: HorizonArticleCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeedArticleComment>> {
        if (requestParameters['feedArticleCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'feedArticleCommentRequest',
                'Required parameter "feedArticleCommentRequest" was null or undefined when calling horizonArticleCommentsCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['article'] != null) {
            queryParameters['article'] = requestParameters['article'];
        }

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['feed'] != null) {
            queryParameters['feed'] = requestParameters['feed'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/article-comments/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedArticleCommentRequestToJSON(requestParameters['feedArticleCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleCommentFromJSON(jsonValue));
    }

    /**
     */
    async horizonArticleCommentsCreate(requestParameters: HorizonArticleCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeedArticleComment> {
        const response = await this.horizonArticleCommentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonArticleCommentsDestroyRaw(requestParameters: HorizonArticleCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonArticleCommentsDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['article'] != null) {
            queryParameters['article'] = requestParameters['article'];
        }

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['feed'] != null) {
            queryParameters['feed'] = requestParameters['feed'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/article-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonArticleCommentsDestroy(requestParameters: HorizonArticleCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonArticleCommentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonArticleCommentsListRaw(requestParameters: HorizonArticleCommentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedFeedArticleCommentList>> {
        const queryParameters: any = {};

        if (requestParameters['article'] != null) {
            queryParameters['article'] = requestParameters['article'];
        }

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['feed'] != null) {
            queryParameters['feed'] = requestParameters['feed'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/article-comments/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedFeedArticleCommentListFromJSON(jsonValue));
    }

    /**
     */
    async horizonArticleCommentsList(requestParameters: HorizonArticleCommentsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedFeedArticleCommentList> {
        const response = await this.horizonArticleCommentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonArticleCommentsPartialUpdateRaw(requestParameters: HorizonArticleCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeedArticleComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonArticleCommentsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/article-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedFeedArticleCommentRequestToJSON(requestParameters['patchedFeedArticleCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleCommentFromJSON(jsonValue));
    }

    /**
     */
    async horizonArticleCommentsPartialUpdate(requestParameters: HorizonArticleCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeedArticleComment> {
        const response = await this.horizonArticleCommentsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonArticleCommentsRetrieveRaw(requestParameters: HorizonArticleCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeedArticleComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonArticleCommentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['article'] != null) {
            queryParameters['article'] = requestParameters['article'];
        }

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['feed'] != null) {
            queryParameters['feed'] = requestParameters['feed'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/article-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleCommentFromJSON(jsonValue));
    }

    /**
     */
    async horizonArticleCommentsRetrieve(requestParameters: HorizonArticleCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeedArticleComment> {
        const response = await this.horizonArticleCommentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonArticleCommentsUpdateRaw(requestParameters: HorizonArticleCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeedArticleComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonArticleCommentsUpdate().'
            );
        }

        if (requestParameters['feedArticleCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'feedArticleCommentRequest',
                'Required parameter "feedArticleCommentRequest" was null or undefined when calling horizonArticleCommentsUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['article'] != null) {
            queryParameters['article'] = requestParameters['article'];
        }

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['feed'] != null) {
            queryParameters['feed'] = requestParameters['feed'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/article-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FeedArticleCommentRequestToJSON(requestParameters['feedArticleCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedArticleCommentFromJSON(jsonValue));
    }

    /**
     */
    async horizonArticleCommentsUpdate(requestParameters: HorizonArticleCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeedArticleComment> {
        const response = await this.horizonArticleCommentsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesAddImpactCreateRaw(requestParameters: HorizonFeedsArticlesAddImpactCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesAddImpactCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesAddImpactCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesAddImpactCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{id}/add_impact/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddImpactRequestToJSON(requestParameters['addImpactRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsArticlesAddImpactCreate(requestParameters: HorizonFeedsArticlesAddImpactCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsArticlesAddImpactCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsArticlesAddStatusCreateRaw(requestParameters: HorizonFeedsArticlesAddStatusCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesAddStatusCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesAddStatusCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesAddStatusCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{id}/add_status/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddStatusRequestToJSON(requestParameters['addStatusRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsArticlesAddStatusCreate(requestParameters: HorizonFeedsArticlesAddStatusCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsArticlesAddStatusCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsArticlesAddTagsCreateRaw(requestParameters: HorizonFeedsArticlesAddTagsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesAddTagsCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesAddTagsCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesAddTagsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{id}/add_tags/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddTagsRequestToJSON(requestParameters['addTagsRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsArticlesAddTagsCreate(requestParameters: HorizonFeedsArticlesAddTagsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsArticlesAddTagsCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsArticlesArchiveCreateRaw(requestParameters: HorizonFeedsArticlesArchiveCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesArchiveCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesArchiveCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesArchiveCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{id}/archive/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsArticlesArchiveCreate(requestParameters: HorizonFeedsArticlesArchiveCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsArticlesArchiveCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsArticlesExportRetrieveRaw(requestParameters: HorizonFeedsArticlesExportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Article>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesExportRetrieve().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesExportRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/export/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesExportRetrieve(requestParameters: HorizonFeedsArticlesExportRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Article> {
        const response = await this.horizonFeedsArticlesExportRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesListRaw(requestParameters: HorizonFeedsArticlesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedArticleListList>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesList().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['assignees'] != null) {
            queryParameters['assignees'] = requestParameters['assignees']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['impact'] != null) {
            queryParameters['impact'] = requestParameters['impact'];
        }

        if (requestParameters['institutionTypes'] != null) {
            queryParameters['institution_types'] = requestParameters['institutionTypes']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['jurisdictions'] != null) {
            queryParameters['jurisdictions'] = requestParameters['jurisdictions']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['keywordsAnd'] != null) {
            queryParameters['keywords_and'] = requestParameters['keywordsAnd']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['keywordsNot'] != null) {
            queryParameters['keywords_not'] = requestParameters['keywordsNot']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['keywordsOr'] != null) {
            queryParameters['keywords_or'] = requestParameters['keywordsOr']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['originalLanguage'] != null) {
            queryParameters['original_language'] = requestParameters['originalLanguage'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['publicationEndDate'] != null) {
            queryParameters['publication_end_date'] = (requestParameters['publicationEndDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['publicationStartDate'] != null) {
            queryParameters['publication_start_date'] = (requestParameters['publicationStartDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['publishers'] != null) {
            queryParameters['publishers'] = requestParameters['publishers']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['query'] != null) {
            queryParameters['query'] = requestParameters['query'];
        }

        if (requestParameters['regulations'] != null) {
            queryParameters['regulations'] = requestParameters['regulations']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['rollingWindow'] != null) {
            queryParameters['rolling_window'] = requestParameters['rollingWindow'];
        }

        if (requestParameters['showOnly'] != null) {
            queryParameters['show_only'] = requestParameters['showOnly'];
        }

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['tags'] != null) {
            queryParameters['tags'] = requestParameters['tags']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedArticleListListFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesList(requestParameters: HorizonFeedsArticlesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedArticleListList> {
        const response = await this.horizonFeedsArticlesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesMarkAsReadCreateRaw(requestParameters: HorizonFeedsArticlesMarkAsReadCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesMarkAsReadCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesMarkAsReadCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesMarkAsReadCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{id}/mark_as_read/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsArticlesMarkAsReadCreate(requestParameters: HorizonFeedsArticlesMarkAsReadCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsArticlesMarkAsReadCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsArticlesMarkAsUnreadCreateRaw(requestParameters: HorizonFeedsArticlesMarkAsUnreadCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesMarkAsUnreadCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesMarkAsUnreadCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesMarkAsUnreadCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{id}/mark_as_unread/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsArticlesMarkAsUnreadCreate(requestParameters: HorizonFeedsArticlesMarkAsUnreadCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsArticlesMarkAsUnreadCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsArticlesMegaFeedListRaw(requestParameters: HorizonFeedsArticlesMegaFeedListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedArticleListList>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesMegaFeedList().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesMegaFeedList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['assignees'] != null) {
            queryParameters['assignees'] = requestParameters['assignees']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['impact'] != null) {
            queryParameters['impact'] = requestParameters['impact'];
        }

        if (requestParameters['institutionTypes'] != null) {
            queryParameters['institution_types'] = requestParameters['institutionTypes']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['jurisdictions'] != null) {
            queryParameters['jurisdictions'] = requestParameters['jurisdictions']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['keywordsAnd'] != null) {
            queryParameters['keywords_and'] = requestParameters['keywordsAnd']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['keywordsNot'] != null) {
            queryParameters['keywords_not'] = requestParameters['keywordsNot']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['keywordsOr'] != null) {
            queryParameters['keywords_or'] = requestParameters['keywordsOr']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['originalLanguage'] != null) {
            queryParameters['original_language'] = requestParameters['originalLanguage'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['publicationEndDate'] != null) {
            queryParameters['publication_end_date'] = (requestParameters['publicationEndDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['publicationStartDate'] != null) {
            queryParameters['publication_start_date'] = (requestParameters['publicationStartDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['publishers'] != null) {
            queryParameters['publishers'] = requestParameters['publishers']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['query'] != null) {
            queryParameters['query'] = requestParameters['query'];
        }

        if (requestParameters['regulations'] != null) {
            queryParameters['regulations'] = requestParameters['regulations']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['rollingWindow'] != null) {
            queryParameters['rolling_window'] = requestParameters['rollingWindow'];
        }

        if (requestParameters['showOnly'] != null) {
            queryParameters['show_only'] = requestParameters['showOnly'];
        }

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['tags'] != null) {
            queryParameters['tags'] = requestParameters['tags']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/mega-feed/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedArticleListListFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesMegaFeedList(requestParameters: HorizonFeedsArticlesMegaFeedListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedArticleListList> {
        const response = await this.horizonFeedsArticlesMegaFeedListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesRetrieveRaw(requestParameters: HorizonFeedsArticlesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Article>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesRetrieve().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesRetrieve(requestParameters: HorizonFeedsArticlesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Article> {
        const response = await this.horizonFeedsArticlesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesSaveForLaterCreateRaw(requestParameters: HorizonFeedsArticlesSaveForLaterCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesSaveForLaterCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesSaveForLaterCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesSaveForLaterCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{id}/save_for_later/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsArticlesSaveForLaterCreate(requestParameters: HorizonFeedsArticlesSaveForLaterCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsArticlesSaveForLaterCreateRaw(requestParameters, initOverrides);
    }

    /**
     * Get similar articles to the current article using MeiliSearch.  This endpoint doesn\'t use filtering - it only returns articles similar to the specified article.
     */
    async horizonFeedsArticlesSimilarArticlesListRaw(requestParameters: HorizonFeedsArticlesSimilarArticlesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ArticleList>>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesSimilarArticlesList().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesSimilarArticlesList().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesSimilarArticlesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sort'] != null) {
            queryParameters['sort'] = requestParameters['sort'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{id}/similar-articles/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ArticleListFromJSON));
    }

    /**
     * Get similar articles to the current article using MeiliSearch.  This endpoint doesn\'t use filtering - it only returns articles similar to the specified article.
     */
    async horizonFeedsArticlesSimilarArticlesList(requestParameters: HorizonFeedsArticlesSimilarArticlesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ArticleList>> {
        const response = await this.horizonFeedsArticlesSimilarArticlesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesTasksCreateRaw(requestParameters: HorizonFeedsArticlesTasksCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Task>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling horizonFeedsArticlesTasksCreate().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesTasksCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesTasksCreate().'
            );
        }

        if (requestParameters['taskRequest'] == null) {
            throw new runtime.RequiredError(
                'taskRequest',
                'Required parameter "taskRequest" was null or undefined when calling horizonFeedsArticlesTasksCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{article_id}/tasks/`.replace(`{${"article_id"}}`, encodeURIComponent(String(requestParameters['articleId']))).replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaskRequestToJSON(requestParameters['taskRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesTasksCreate(requestParameters: HorizonFeedsArticlesTasksCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Task> {
        const response = await this.horizonFeedsArticlesTasksCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesTasksDestroyRaw(requestParameters: HorizonFeedsArticlesTasksDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling horizonFeedsArticlesTasksDestroy().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesTasksDestroy().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesTasksDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesTasksDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{article_id}/tasks/{id}/`.replace(`{${"article_id"}}`, encodeURIComponent(String(requestParameters['articleId']))).replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsArticlesTasksDestroy(requestParameters: HorizonFeedsArticlesTasksDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsArticlesTasksDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsArticlesTasksListRaw(requestParameters: HorizonFeedsArticlesTasksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedTaskList>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling horizonFeedsArticlesTasksList().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesTasksList().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesTasksList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{article_id}/tasks/`.replace(`{${"article_id"}}`, encodeURIComponent(String(requestParameters['articleId']))).replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedTaskListFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesTasksList(requestParameters: HorizonFeedsArticlesTasksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedTaskList> {
        const response = await this.horizonFeedsArticlesTasksListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesTasksPartialUpdateRaw(requestParameters: HorizonFeedsArticlesTasksPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Task>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling horizonFeedsArticlesTasksPartialUpdate().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesTasksPartialUpdate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesTasksPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesTasksPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{article_id}/tasks/{id}/`.replace(`{${"article_id"}}`, encodeURIComponent(String(requestParameters['articleId']))).replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedTaskRequestToJSON(requestParameters['patchedTaskRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesTasksPartialUpdate(requestParameters: HorizonFeedsArticlesTasksPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Task> {
        const response = await this.horizonFeedsArticlesTasksPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesTasksRetrieveRaw(requestParameters: HorizonFeedsArticlesTasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Task>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling horizonFeedsArticlesTasksRetrieve().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesTasksRetrieve().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesTasksRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesTasksRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{article_id}/tasks/{id}/`.replace(`{${"article_id"}}`, encodeURIComponent(String(requestParameters['articleId']))).replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesTasksRetrieve(requestParameters: HorizonFeedsArticlesTasksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Task> {
        const response = await this.horizonFeedsArticlesTasksRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesTasksUpdateRaw(requestParameters: HorizonFeedsArticlesTasksUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Task>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling horizonFeedsArticlesTasksUpdate().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesTasksUpdate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesTasksUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesTasksUpdate().'
            );
        }

        if (requestParameters['taskRequest'] == null) {
            throw new runtime.RequiredError(
                'taskRequest',
                'Required parameter "taskRequest" was null or undefined when calling horizonFeedsArticlesTasksUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{article_id}/tasks/{id}/`.replace(`{${"article_id"}}`, encodeURIComponent(String(requestParameters['articleId']))).replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TaskRequestToJSON(requestParameters['taskRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TaskFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesTasksUpdate(requestParameters: HorizonFeedsArticlesTasksUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Task> {
        const response = await this.horizonFeedsArticlesTasksUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesTodosCreateRaw(requestParameters: HorizonFeedsArticlesTodosCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Todo>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling horizonFeedsArticlesTodosCreate().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesTodosCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesTodosCreate().'
            );
        }

        if (requestParameters['todoRequest'] == null) {
            throw new runtime.RequiredError(
                'todoRequest',
                'Required parameter "todoRequest" was null or undefined when calling horizonFeedsArticlesTodosCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{article_id}/todos/`.replace(`{${"article_id"}}`, encodeURIComponent(String(requestParameters['articleId']))).replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TodoRequestToJSON(requestParameters['todoRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TodoFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesTodosCreate(requestParameters: HorizonFeedsArticlesTodosCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Todo> {
        const response = await this.horizonFeedsArticlesTodosCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesTodosDestroyRaw(requestParameters: HorizonFeedsArticlesTodosDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling horizonFeedsArticlesTodosDestroy().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesTodosDestroy().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesTodosDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesTodosDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{article_id}/todos/{id}/`.replace(`{${"article_id"}}`, encodeURIComponent(String(requestParameters['articleId']))).replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsArticlesTodosDestroy(requestParameters: HorizonFeedsArticlesTodosDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsArticlesTodosDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsArticlesTodosListRaw(requestParameters: HorizonFeedsArticlesTodosListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedTodoList>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling horizonFeedsArticlesTodosList().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesTodosList().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesTodosList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['isCompleted'] != null) {
            queryParameters['is_completed'] = requestParameters['isCompleted'];
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{article_id}/todos/`.replace(`{${"article_id"}}`, encodeURIComponent(String(requestParameters['articleId']))).replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedTodoListFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesTodosList(requestParameters: HorizonFeedsArticlesTodosListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedTodoList> {
        const response = await this.horizonFeedsArticlesTodosListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesTodosPartialUpdateRaw(requestParameters: HorizonFeedsArticlesTodosPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Todo>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling horizonFeedsArticlesTodosPartialUpdate().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesTodosPartialUpdate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesTodosPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesTodosPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{article_id}/todos/{id}/`.replace(`{${"article_id"}}`, encodeURIComponent(String(requestParameters['articleId']))).replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedTodoRequestToJSON(requestParameters['patchedTodoRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TodoFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesTodosPartialUpdate(requestParameters: HorizonFeedsArticlesTodosPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Todo> {
        const response = await this.horizonFeedsArticlesTodosPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesTodosRetrieveRaw(requestParameters: HorizonFeedsArticlesTodosRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Todo>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling horizonFeedsArticlesTodosRetrieve().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesTodosRetrieve().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesTodosRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesTodosRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{article_id}/todos/{id}/`.replace(`{${"article_id"}}`, encodeURIComponent(String(requestParameters['articleId']))).replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TodoFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesTodosRetrieve(requestParameters: HorizonFeedsArticlesTodosRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Todo> {
        const response = await this.horizonFeedsArticlesTodosRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesTodosUpdateRaw(requestParameters: HorizonFeedsArticlesTodosUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Todo>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling horizonFeedsArticlesTodosUpdate().'
            );
        }

        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesTodosUpdate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesTodosUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesTodosUpdate().'
            );
        }

        if (requestParameters['todoRequest'] == null) {
            throw new runtime.RequiredError(
                'todoRequest',
                'Required parameter "todoRequest" was null or undefined when calling horizonFeedsArticlesTodosUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{article_id}/todos/{id}/`.replace(`{${"article_id"}}`, encodeURIComponent(String(requestParameters['articleId']))).replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TodoRequestToJSON(requestParameters['todoRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TodoFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsArticlesTodosUpdate(requestParameters: HorizonFeedsArticlesTodosUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Todo> {
        const response = await this.horizonFeedsArticlesTodosUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsArticlesUnarchiveCreateRaw(requestParameters: HorizonFeedsArticlesUnarchiveCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesUnarchiveCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesUnarchiveCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesUnarchiveCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{id}/unarchive/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsArticlesUnarchiveCreate(requestParameters: HorizonFeedsArticlesUnarchiveCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsArticlesUnarchiveCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsArticlesUnsaveForLaterCreateRaw(requestParameters: HorizonFeedsArticlesUnsaveForLaterCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesUnsaveForLaterCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesUnsaveForLaterCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesUnsaveForLaterCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{id}/unsave_for_later/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsArticlesUnsaveForLaterCreate(requestParameters: HorizonFeedsArticlesUnsaveForLaterCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsArticlesUnsaveForLaterCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsArticlesUpdateAssigneesCreateRaw(requestParameters: HorizonFeedsArticlesUpdateAssigneesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsArticlesUpdateAssigneesCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsArticlesUpdateAssigneesCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsArticlesUpdateAssigneesCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/articles/{id}/update_assignees/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddAssigneesRequestToJSON(requestParameters['addAssigneesRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsArticlesUpdateAssigneesCreate(requestParameters: HorizonFeedsArticlesUpdateAssigneesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsArticlesUpdateAssigneesCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsCreateRaw(requestParameters: HorizonFeedsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeedView>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsCreate().'
            );
        }

        if (requestParameters['feedRequest'] == null) {
            throw new runtime.RequiredError(
                'feedRequest',
                'Required parameter "feedRequest" was null or undefined when calling horizonFeedsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FeedRequestToJSON(requestParameters['feedRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedViewFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsCreate(requestParameters: HorizonFeedsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeedView> {
        const response = await this.horizonFeedsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsDestroyRaw(requestParameters: HorizonFeedsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsDestroy(requestParameters: HorizonFeedsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsLeaveCreateRaw(requestParameters: HorizonFeedsLeaveCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsLeaveCreate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsLeaveCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{id}/leave/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsLeaveCreate(requestParameters: HorizonFeedsLeaveCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsLeaveCreateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsListRaw(requestParameters: HorizonFeedsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedFeedViewList>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedFeedViewListFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsList(requestParameters: HorizonFeedsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedFeedViewList> {
        const response = await this.horizonFeedsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsPartialUpdateRaw(requestParameters: HorizonFeedsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Feed>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedFeedRequestToJSON(requestParameters['patchedFeedRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsPartialUpdate(requestParameters: HorizonFeedsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Feed> {
        const response = await this.horizonFeedsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsRetrieveRaw(requestParameters: HorizonFeedsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FeedView>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedViewFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsRetrieve(requestParameters: HorizonFeedsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FeedView> {
        const response = await this.horizonFeedsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsTodosCreateRaw(requestParameters: HorizonFeedsTodosCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Todo>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsTodosCreate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsTodosCreate().'
            );
        }

        if (requestParameters['todoRequest'] == null) {
            throw new runtime.RequiredError(
                'todoRequest',
                'Required parameter "todoRequest" was null or undefined when calling horizonFeedsTodosCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/todos/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TodoRequestToJSON(requestParameters['todoRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TodoFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsTodosCreate(requestParameters: HorizonFeedsTodosCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Todo> {
        const response = await this.horizonFeedsTodosCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsTodosDestroyRaw(requestParameters: HorizonFeedsTodosDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsTodosDestroy().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsTodosDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsTodosDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/todos/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonFeedsTodosDestroy(requestParameters: HorizonFeedsTodosDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonFeedsTodosDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonFeedsTodosListRaw(requestParameters: HorizonFeedsTodosListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedTodoList>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsTodosList().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsTodosList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/todos/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedTodoListFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsTodosList(requestParameters: HorizonFeedsTodosListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedTodoList> {
        const response = await this.horizonFeedsTodosListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsTodosPartialUpdateRaw(requestParameters: HorizonFeedsTodosPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Todo>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsTodosPartialUpdate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsTodosPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsTodosPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/todos/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedTodoRequestToJSON(requestParameters['patchedTodoRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TodoFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsTodosPartialUpdate(requestParameters: HorizonFeedsTodosPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Todo> {
        const response = await this.horizonFeedsTodosPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsTodosRetrieveRaw(requestParameters: HorizonFeedsTodosRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Todo>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsTodosRetrieve().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsTodosRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsTodosRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/todos/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TodoFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsTodosRetrieve(requestParameters: HorizonFeedsTodosRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Todo> {
        const response = await this.horizonFeedsTodosRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsTodosUpdateRaw(requestParameters: HorizonFeedsTodosUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Todo>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsTodosUpdate().'
            );
        }

        if (requestParameters['feedId'] == null) {
            throw new runtime.RequiredError(
                'feedId',
                'Required parameter "feedId" was null or undefined when calling horizonFeedsTodosUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsTodosUpdate().'
            );
        }

        if (requestParameters['todoRequest'] == null) {
            throw new runtime.RequiredError(
                'todoRequest',
                'Required parameter "todoRequest" was null or undefined when calling horizonFeedsTodosUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{feed_id}/todos/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"feed_id"}}`, encodeURIComponent(String(requestParameters['feedId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TodoRequestToJSON(requestParameters['todoRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TodoFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsTodosUpdate(requestParameters: HorizonFeedsTodosUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Todo> {
        const response = await this.horizonFeedsTodosUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonFeedsUpdateRaw(requestParameters: HorizonFeedsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Feed>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonFeedsUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonFeedsUpdate().'
            );
        }

        if (requestParameters['feedRequest'] == null) {
            throw new runtime.RequiredError(
                'feedRequest',
                'Required parameter "feedRequest" was null or undefined when calling horizonFeedsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/feeds/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FeedRequestToJSON(requestParameters['feedRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FeedFromJSON(jsonValue));
    }

    /**
     */
    async horizonFeedsUpdate(requestParameters: HorizonFeedsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Feed> {
        const response = await this.horizonFeedsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonInstitutionTypesListRaw(requestParameters: HorizonInstitutionTypesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedInstitutionTypeList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/institution-types/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedInstitutionTypeListFromJSON(jsonValue));
    }

    /**
     */
    async horizonInstitutionTypesList(requestParameters: HorizonInstitutionTypesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedInstitutionTypeList> {
        const response = await this.horizonInstitutionTypesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonInstitutionTypesRetrieveRaw(requestParameters: HorizonInstitutionTypesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstitutionType>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonInstitutionTypesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/institution-types/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InstitutionTypeFromJSON(jsonValue));
    }

    /**
     */
    async horizonInstitutionTypesRetrieve(requestParameters: HorizonInstitutionTypesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstitutionType> {
        const response = await this.horizonInstitutionTypesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonJurisdictionsListRaw(requestParameters: HorizonJurisdictionsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRegionList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/jurisdictions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRegionListFromJSON(jsonValue));
    }

    /**
     */
    async horizonJurisdictionsList(requestParameters: HorizonJurisdictionsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRegionList> {
        const response = await this.horizonJurisdictionsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonJurisdictionsRetrieveRaw(requestParameters: HorizonJurisdictionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Region>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonJurisdictionsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/jurisdictions/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegionFromJSON(jsonValue));
    }

    /**
     */
    async horizonJurisdictionsRetrieve(requestParameters: HorizonJurisdictionsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Region> {
        const response = await this.horizonJurisdictionsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonPublishersListRaw(requestParameters: HorizonPublishersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedPublisherList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['regionId'] != null) {
            queryParameters['region_id'] = requestParameters['regionId'];
        }

        if (requestParameters['regionIdIn'] != null) {
            queryParameters['region_id__in'] = requestParameters['regionIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/publishers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedPublisherListFromJSON(jsonValue));
    }

    /**
     */
    async horizonPublishersList(requestParameters: HorizonPublishersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedPublisherList> {
        const response = await this.horizonPublishersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonPublishersRetrieveRaw(requestParameters: HorizonPublishersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Publisher>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonPublishersRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/publishers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublisherFromJSON(jsonValue));
    }

    /**
     */
    async horizonPublishersRetrieve(requestParameters: HorizonPublishersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Publisher> {
        const response = await this.horizonPublishersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonRegulationsListRaw(requestParameters: HorizonRegulationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRegulationList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/regulations/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRegulationListFromJSON(jsonValue));
    }

    /**
     */
    async horizonRegulationsList(requestParameters: HorizonRegulationsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRegulationList> {
        const response = await this.horizonRegulationsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonRegulationsRetrieveRaw(requestParameters: HorizonRegulationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Regulation>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonRegulationsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/regulations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegulationFromJSON(jsonValue));
    }

    /**
     */
    async horizonRegulationsRetrieve(requestParameters: HorizonRegulationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Regulation> {
        const response = await this.horizonRegulationsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonTagsCreateRaw(requestParameters: HorizonTagsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonTagsCreate().'
            );
        }

        if (requestParameters['tagRequest'] == null) {
            throw new runtime.RequiredError(
                'tagRequest',
                'Required parameter "tagRequest" was null or undefined when calling horizonTagsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/tags/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagRequestToJSON(requestParameters['tagRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     */
    async horizonTagsCreate(requestParameters: HorizonTagsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tag> {
        const response = await this.horizonTagsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonTagsDestroyRaw(requestParameters: HorizonTagsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonTagsDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonTagsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/tags/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async horizonTagsDestroy(requestParameters: HorizonTagsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.horizonTagsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async horizonTagsListRaw(requestParameters: HorizonTagsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedTagList>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonTagsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/tags/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedTagListFromJSON(jsonValue));
    }

    /**
     */
    async horizonTagsList(requestParameters: HorizonTagsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedTagList> {
        const response = await this.horizonTagsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonTagsPartialUpdateRaw(requestParameters: HorizonTagsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonTagsPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonTagsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/tags/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedTagRequestToJSON(requestParameters['patchedTagRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     */
    async horizonTagsPartialUpdate(requestParameters: HorizonTagsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tag> {
        const response = await this.horizonTagsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonTagsRetrieveRaw(requestParameters: HorizonTagsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonTagsRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonTagsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/tags/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     */
    async horizonTagsRetrieve(requestParameters: HorizonTagsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tag> {
        const response = await this.horizonTagsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonTagsUpdateRaw(requestParameters: HorizonTagsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tag>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling horizonTagsUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonTagsUpdate().'
            );
        }

        if (requestParameters['tagRequest'] == null) {
            throw new runtime.RequiredError(
                'tagRequest',
                'Required parameter "tagRequest" was null or undefined when calling horizonTagsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/{company_id}/tags/{id}/`.replace(`{${"company_id"}}`, encodeURIComponent(String(requestParameters['companyId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TagRequestToJSON(requestParameters['tagRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TagFromJSON(jsonValue));
    }

    /**
     */
    async horizonTagsUpdate(requestParameters: HorizonTagsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tag> {
        const response = await this.horizonTagsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonTopicsListRaw(requestParameters: HorizonTopicsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedTopicList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/topics/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedTopicListFromJSON(jsonValue));
    }

    /**
     */
    async horizonTopicsList(requestParameters: HorizonTopicsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedTopicList> {
        const response = await this.horizonTopicsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async horizonTopicsRetrieveRaw(requestParameters: HorizonTopicsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Topic>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling horizonTopicsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/horizon/topics/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TopicFromJSON(jsonValue));
    }

    /**
     */
    async horizonTopicsRetrieve(requestParameters: HorizonTopicsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Topic> {
        const response = await this.horizonTopicsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const HorizonArticleCommentsListStatusEnum = {
    Open: 'open',
    Resolved: 'resolved'
} as const;
export type HorizonArticleCommentsListStatusEnum = typeof HorizonArticleCommentsListStatusEnum[keyof typeof HorizonArticleCommentsListStatusEnum];
/**
 * @export
 */
export const HorizonFeedsArticlesListImpactEnum = {
    High: 'high',
    Low: 'low',
    Medium: 'medium',
    NotSet: 'not_set'
} as const;
export type HorizonFeedsArticlesListImpactEnum = typeof HorizonFeedsArticlesListImpactEnum[keyof typeof HorizonFeedsArticlesListImpactEnum];
/**
 * @export
 */
export const HorizonFeedsArticlesListRollingWindowEnum = {
    Last30Days: 'Last 30 days',
    LastMonth: 'Last month',
    LastWeek: 'Last week',
    ThisMonth: 'This month',
    ThisWeek: 'This week',
    Today: 'Today',
    Yesterday: 'Yesterday'
} as const;
export type HorizonFeedsArticlesListRollingWindowEnum = typeof HorizonFeedsArticlesListRollingWindowEnum[keyof typeof HorizonFeedsArticlesListRollingWindowEnum];
/**
 * @export
 */
export const HorizonFeedsArticlesListShowOnlyEnum = {
    All: 'all',
    Archived: 'archived',
    Assigned: 'assigned',
    Mentioned: 'mentioned',
    Read: 'read',
    Saved: 'saved',
    Unread: 'unread'
} as const;
export type HorizonFeedsArticlesListShowOnlyEnum = typeof HorizonFeedsArticlesListShowOnlyEnum[keyof typeof HorizonFeedsArticlesListShowOnlyEnum];
/**
 * @export
 */
export const HorizonFeedsArticlesListStatusEnum = {
    Completed: 'completed',
    InProgress: 'in_progress',
    Pending: 'pending'
} as const;
export type HorizonFeedsArticlesListStatusEnum = typeof HorizonFeedsArticlesListStatusEnum[keyof typeof HorizonFeedsArticlesListStatusEnum];
/**
 * @export
 */
export const HorizonFeedsArticlesMegaFeedListImpactEnum = {
    High: 'high',
    Low: 'low',
    Medium: 'medium',
    NotSet: 'not_set'
} as const;
export type HorizonFeedsArticlesMegaFeedListImpactEnum = typeof HorizonFeedsArticlesMegaFeedListImpactEnum[keyof typeof HorizonFeedsArticlesMegaFeedListImpactEnum];
/**
 * @export
 */
export const HorizonFeedsArticlesMegaFeedListRollingWindowEnum = {
    Last30Days: 'Last 30 days',
    LastMonth: 'Last month',
    LastWeek: 'Last week',
    ThisMonth: 'This month',
    ThisWeek: 'This week',
    Today: 'Today',
    Yesterday: 'Yesterday'
} as const;
export type HorizonFeedsArticlesMegaFeedListRollingWindowEnum = typeof HorizonFeedsArticlesMegaFeedListRollingWindowEnum[keyof typeof HorizonFeedsArticlesMegaFeedListRollingWindowEnum];
/**
 * @export
 */
export const HorizonFeedsArticlesMegaFeedListShowOnlyEnum = {
    All: 'all',
    Archived: 'archived',
    Assigned: 'assigned',
    Mentioned: 'mentioned',
    Read: 'read',
    Saved: 'saved',
    Unread: 'unread'
} as const;
export type HorizonFeedsArticlesMegaFeedListShowOnlyEnum = typeof HorizonFeedsArticlesMegaFeedListShowOnlyEnum[keyof typeof HorizonFeedsArticlesMegaFeedListShowOnlyEnum];
/**
 * @export
 */
export const HorizonFeedsArticlesMegaFeedListStatusEnum = {
    Completed: 'completed',
    InProgress: 'in_progress',
    Pending: 'pending'
} as const;
export type HorizonFeedsArticlesMegaFeedListStatusEnum = typeof HorizonFeedsArticlesMegaFeedListStatusEnum[keyof typeof HorizonFeedsArticlesMegaFeedListStatusEnum];
