import React, { MouseEvent, useRef } from "react";
import { useNavigate } from "react-router-dom";

import clsx from "clsx";
import { Tooltip } from "flowbite-react";
import toString from "lodash/toString";
import truncate from "lodash/truncate";
import { useOnClickOutside } from "usehooks-ts";

import { useMutation } from "@tanstack/react-query";

import vendorStyles from "assets/css/vendors.module.css";

import Icon from "shared/components/icon/Icon";
import { useCompany } from "shared/context/CompanyProvider";
import { CompanyProps } from "shared/context/types/company";
import { MOBILE_DEVICE, SEARCH_SUGGESTION } from "shared/helpers/constant";
import { removeItemFromLocalStorage, removeRefreshToken, removeToken } from "shared/helpers/util";
import useScreen from "shared/hooks/useScreen";
import { useHome } from "shared/store/home";
import { useSidebar } from "shared/store/sidebar";
import { useQueryParams, useSearchSuggestion } from "shared/store/table-column-selection";
import { useCompanyDropdown } from "shared/storeV2/companyDropdown";
import { useRouteBlock } from "shared/storeV2/routeBlock";
import { useVendor } from "shared/storeV2/vendors";

import { signout } from "modules/auth/signIn/api";

import Avatar from "../avatar";
import { Popover, PopoverContent, PopoverTrigger } from "../popover/PopOver";
import ComapniesList from "./ComapniesList";

const CompanySwitcher = () => {
  const deviceType = useScreen();
  const { currentCompany, updateCurrentCompany } = useCompany();
  const [resetCurrentVendorList] = useVendor((state) => [state.resetCurrentVendorList]);
  const profile = useHome((state) => state.profile);
  const [isMenuShown, setIsMenuShown] = useCompanyDropdown((state) => [
    state.isMenuShown,
    state.setIsMenuShown,
  ]);
  const menuRef = useRef<HTMLDivElement>(null);

  const { isBlocking } = useRouteBlock();

  const navigate = useNavigate();
  const setSearchData = useSearchSuggestion((state) => state.setSearchData);

  const [resetSelectedQueryParams] = useQueryParams((state) => [state.resetSelectedQueryParams]);

  const handleTriggerClick = () => {
    if (isBlocking) {
      alert("You have unsaved changes. Please save or discard them before switching companies.");
      return;
    }
    setIsMenuShown(!isMenuShown);
  };

  const [isToggle, isVisible, setIsToggle, setIsVisible] = useSidebar((state) => [
    state.isToggle,
    state.isVisible,
    state.setIsToggle,
    state.setIsVisible,
  ]);

  const onToggleClick = (event: MouseEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (deviceType === MOBILE_DEVICE) {
      setIsVisible(!isVisible);
      return;
    }
    setIsToggle(!isToggle);
  };

  useOnClickOutside(menuRef, () => {
    if (isMenuShown) {
      setIsMenuShown(!isMenuShown);
    }
  });

  const onCompanyClick = (company: CompanyProps) => {
    if (!isBlocking) {
      resetCurrentVendorList(null);
    }
    navigate("/");
    if (!isBlocking) {
      updateCurrentCompany(company);
      setIsMenuShown(false);
      resetSelectedQueryParams({});
    }
  };

  const { mutate: signoutFn } = useMutation(() => signout(), {
    onSuccess: () => {
      navigate("/signin", { replace: true });
      if (!isBlocking) {
        removeToken();
        removeRefreshToken();
        setIsMenuShown(false);
        resetCurrentVendorList(null);
        removeItemFromLocalStorage(SEARCH_SUGGESTION);
        setSearchData({});
      }
    },
  });

  return (
    <Popover innerref={menuRef}>
      <PopoverTrigger onClick={handleTriggerClick}>
        <div
          className={`flex flex-row items-center justify-between rounded-lg px-2 py-2 hover:bg-antiflashwhite hover:dark:bg-the_end ${
            isMenuShown && "bg-antiflashwhite dark:bg-the_end"
          }`}
        >
          <div className="flex items-center gap-2">
            <Avatar
              className="rounded-md"
              name={currentCompany?.name}
              url={toString(currentCompany?.image)}
              avatarFor="company"
              avatarType="squared"
              size="sm"
            />
            <div className="flex w-[calc(100%_-_32px)] flex-wrap break-words">
              <span className="font-inter-medium text-sm text-brightgrey dark:text-gray_700">
                {currentCompany?.name}
              </span>
            </div>
          </div>
          <div className="flex items-center">
            <Tooltip content="Hide menu" className="metadata_tooltip w-[78px]" arrow={false}>
              <button className="arrow-toggle-v2 relative z-50" onClick={onToggleClick}>
                <Icon type="doubleleft" size="icon-sm" />
              </button>
            </Tooltip>
          </div>
        </div>
      </PopoverTrigger>
      {isMenuShown ? (
        <div className={`flex transition-all delay-150 duration-300 ease-in-out`}>
          <PopoverContent className="company_dropdown_content">
            <div className="flex max-h-64 flex-col gap-1 overflow-y-auto overflow-x-hidden">
              <div className="flex items-center px-3 py-1">
                <Tooltip
                  content={profile?.email}
                  className="custom_tooltip maxMd:hidden"
                  style="dark"
                >
                  <h4 className="w-48 overflow-hidden text-ellipsis whitespace-nowrap font-inter-medium text-xs text-santagrey dark:text-westchestergrey">
                    {truncate(profile?.email)}
                  </h4>
                </Tooltip>
              </div>
              <div className="flex flex-col gap-1 px-1">
                <ComapniesList onCompanyClick={onCompanyClick} />
              </div>
            </div>
            <hr
              className={clsx(
                vendorStyles.vendorFormSeparationStyle,
                "!bg-brightgray dark:!bg-jet "
              )}
            />
            <div className="flex flex-col gap-1 px-1">
              <button
                disabled={true}
                type="button"
                className="flex items-center gap-2 px-2 py-1.5 hover:rounded-lg hover:bg-antiflashwhite disabled:cursor-not-allowed disabled:opacity-75 disabled:hover:bg-transparent hover:dark:bg-the_end"
              >
                <Icon type="plus" size="icon-sm" fill={true} className="theme_icon" />
                <span className="font-inter-medium text-sm text-brightgrey dark:text-silverpolish">
                  Add another workspace
                </span>
              </button>
              <button
                type="button"
                className="flex items-center gap-2 px-2 py-1.5 hover:rounded-lg hover:bg-antiflashwhite hover:dark:bg-the_end"
                onClick={() => signoutFn()}
              >
                <Icon type="arrow-right" size="icon-sm" fill={true} className="theme_icon" />
                <span className="font-inter-medium text-sm text-brightgrey dark:text-silverpolish">
                  Log out
                </span>
              </button>
            </div>
          </PopoverContent>
        </div>
      ) : null}
    </Popover>
  );
};

export default CompanySwitcher;
