/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TextAreaRequest } from './TextAreaRequest';
import {
    TextAreaRequestFromJSON,
    TextAreaRequestFromJSONTyped,
    TextAreaRequestToJSON,
} from './TextAreaRequest';

/**
 * 
 * @export
 * @interface ObligationRequest
 */
export interface ObligationRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ObligationRequest
     */
    categories: Array<string>;
    /**
     * 
     * @type {Array<TextAreaRequest>}
     * @memberof ObligationRequest
     */
    textAreas: Array<TextAreaRequest>;
    /**
     * 
     * @type {boolean}
     * @memberof ObligationRequest
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ObligationRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ObligationRequest
     */
    text: string;
    /**
     * 
     * @type {boolean}
     * @memberof ObligationRequest
     */
    policyCoverage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ObligationRequest
     */
    indexed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ObligationRequest
     */
    startingPageIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ObligationRequest
     */
    minY0?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ObligationRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ObligationRequest
     */
    authorityDocument: number;
}

/**
 * Check if a given object implements the ObligationRequest interface.
 */
export function instanceOfObligationRequest(value: object): value is ObligationRequest {
    if (!('categories' in value) || value['categories'] === undefined) return false;
    if (!('textAreas' in value) || value['textAreas'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('authorityDocument' in value) || value['authorityDocument'] === undefined) return false;
    return true;
}

export function ObligationRequestFromJSON(json: any): ObligationRequest {
    return ObligationRequestFromJSONTyped(json, false);
}

export function ObligationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObligationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'categories': json['categories'],
        'textAreas': ((json['text_areas'] as Array<any>).map(TextAreaRequestFromJSON)),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'text': json['text'],
        'policyCoverage': json['policy_coverage'] == null ? undefined : json['policy_coverage'],
        'indexed': json['indexed'] == null ? undefined : json['indexed'],
        'startingPageIndex': json['starting_page_index'] == null ? undefined : json['starting_page_index'],
        'minY0': json['min_y0'] == null ? undefined : json['min_y0'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'authorityDocument': json['authority_document'],
    };
}

export function ObligationRequestToJSON(value?: ObligationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'categories': value['categories'],
        'text_areas': ((value['textAreas'] as Array<any>).map(TextAreaRequestToJSON)),
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'text': value['text'],
        'policy_coverage': value['policyCoverage'],
        'indexed': value['indexed'],
        'starting_page_index': value['startingPageIndex'],
        'min_y0': value['minY0'],
        'object_owner': value['objectOwner'],
        'authority_document': value['authorityDocument'],
    };
}

