/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ControlAreaSummary } from './ControlAreaSummary';
import {
    ControlAreaSummaryFromJSON,
    ControlAreaSummaryFromJSONTyped,
    ControlAreaSummaryToJSON,
} from './ControlAreaSummary';
import type { ControlsLibraryTypeEnum } from './ControlsLibraryTypeEnum';
import {
    ControlsLibraryTypeEnumFromJSON,
    ControlsLibraryTypeEnumFromJSONTyped,
    ControlsLibraryTypeEnumToJSON,
} from './ControlsLibraryTypeEnum';

/**
 * 
 * @export
 * @interface ControlsLibrary
 */
export interface ControlsLibrary {
    /**
     * 
     * @type {number}
     * @memberof ControlsLibrary
     */
    readonly id: number;
    /**
     * 
     * @type {Array<ControlAreaSummary>}
     * @memberof ControlsLibrary
     */
    readonly controlAreasSummary: Array<ControlAreaSummary>;
    /**
     * 
     * @type {Date}
     * @memberof ControlsLibrary
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ControlsLibrary
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ControlsLibrary
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControlsLibrary
     */
    name: string;
    /**
     * 
     * @type {ControlsLibraryTypeEnum}
     * @memberof ControlsLibrary
     */
    type?: ControlsLibraryTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ControlsLibrary
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ControlsLibrary
     */
    company?: number | null;
}



/**
 * Check if a given object implements the ControlsLibrary interface.
 */
export function instanceOfControlsLibrary(value: object): value is ControlsLibrary {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('controlAreasSummary' in value) || value['controlAreasSummary'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function ControlsLibraryFromJSON(json: any): ControlsLibrary {
    return ControlsLibraryFromJSONTyped(json, false);
}

export function ControlsLibraryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlsLibrary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'controlAreasSummary': ((json['control_areas_summary'] as Array<any>).map(ControlAreaSummaryFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'type': json['type'] == null ? undefined : ControlsLibraryTypeEnumFromJSON(json['type']),
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'company': json['company'] == null ? undefined : json['company'],
    };
}

export function ControlsLibraryToJSON(value?: Omit<ControlsLibrary, 'id'|'control_areas_summary'|'created_at'|'updated_at'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'type': ControlsLibraryTypeEnumToJSON(value['type']),
        'object_owner': value['objectOwner'],
        'company': value['company'],
    };
}

