import { ReactNode, useMemo } from "react";

import { Spinner, Tooltip } from "flowbite-react";
import isEmpty from "lodash/isEmpty";

import CommonTableV2AddButton from "shared/componentsV2/common-table/CommonTableV2AddButton";
import FavoriteDropDown, {
  FavoriteDropdownProps,
} from "shared/componentsV2/common-table/FavoriteDropDown";
import Button from "shared/componentsV2/form-controls/button";
import NotificationDropdown from "shared/componentsV2/notification-dropdown/NotificationDropdown";
import { IS_SIDEBAR_TOGGLE, MOBILE_DEVICE } from "shared/helpers/constant";
import { setItemToLocalStorage } from "shared/helpers/util";
import useScreen from "shared/hooks/useScreen";
import { useSidebar } from "shared/store/sidebar";
import { useCommonTableV2Filters } from "shared/storeV2/commonTable";
import { useHorizonScanningMenuBar } from "shared/storeV2/horizonScanningMenubar";
import { useQuestionnaireMenuBar } from "shared/storeV2/questionnaireMenuBar";
import { useVendorProfileMenuBar } from "shared/storeV2/vendorProfileMenubar";
import { DropdownItemProps } from "shared/typesV2";

import { BreadCrumbTreeProps } from "../bread-crumb/types";
import Icon from "../icon/Icon";
import BreadCrumbWrapper from "./BreadCrumbWrapper";

type HeaderProps = {
  breadCrumb: BreadCrumbTreeProps[];
  displayDots?: boolean;
  isLoading?: boolean;
  isVendorBtnExists?: boolean;
  displayEditBtn?: boolean;
  displayFormBtns?: boolean;
  displaySaveAndAddBtn?: boolean;
  displayToggleMenuBar?: boolean;
  displaySidebarBtn?: boolean;
  btnDetails?: {
    icon?: string;
    title: string;
    handleClickBtn?: () => void;
    dropdownOptions?: DropdownItemProps[];
    isBtnDisable?: boolean;
  };
  secondaryBtn?: ReactNode;
  onSaveClick?: () => void;
  onDiscardClick?: () => void;
  onSaveAndAddMoreClick?: () => void;
  onSidebarBtnClick?: () => void;
  shouldNotifyClickable?: boolean;
  moduleName?: "vendors" | "questionnaires" | "reports" | "horizon-scanning" | "controls";
  disableShare?: boolean;
  disableDelete?: boolean;
  informationText?: string;
  additionalActions?: ReactNode;
} & FavoriteDropdownProps;

const Header = ({
  breadCrumb,
  displayDots,
  isLoading = false,
  displayEditBtn = false,
  displayFormBtns = false,
  displaySaveAndAddBtn = false,
  displaySidebarBtn = false,
  displayToggleMenuBar = true,
  informationText,
  btnDetails,
  secondaryBtn,
  onSaveClick,
  onDiscardClick,
  onSaveAndAddMoreClick,
  onSidebarBtnClick,
  shouldNotifyClickable,
  onAction,
  moduleName = "vendors",
  disableShare,
  disableDelete,
  additionalActions,
}: HeaderProps) => {
  const deviceType = useScreen();

  const [isToggle, isVisible, setIsToggle, setIsVisible] = useSidebar((state) => [
    state.isToggle,
    state.isVisible,
    state.setIsToggle,
    state.setIsVisible,
  ]);

  const [toggleVendorProfileMenuBar, setToggleVendorProfileMenuBar] = useVendorProfileMenuBar(
    (state) => [state.toggleMenuBar, state.setToggleMenuBar]
  );

  const [toggleHorizonScanningMenuBar, setToggleHorizonScanningMenuBar] = useHorizonScanningMenuBar(
    (state) => [state.toggleMenuBar, state.setToggleMenuBar]
  );

  const [toggleQuestionnaireMenuBar, setToggleQuestionnaireMenuBar] = useQuestionnaireMenuBar(
    (state) => [state.toggleMenuBar, state.setToggleMenuBar]
  );

  const [toggleMenuBar, setToggleMenuBar] = useMemo(() => {
    if (moduleName === "questionnaires") {
      return [toggleQuestionnaireMenuBar, setToggleQuestionnaireMenuBar];
    }
    if (moduleName === "horizon-scanning") {
      return [toggleHorizonScanningMenuBar, setToggleHorizonScanningMenuBar];
    }

    return [toggleVendorProfileMenuBar, setToggleVendorProfileMenuBar];
  }, [
    moduleName,
    toggleVendorProfileMenuBar,
    toggleQuestionnaireMenuBar,
    toggleHorizonScanningMenuBar,
  ]);

  const resetToggleFilters = useCommonTableV2Filters((state) => state.resetToggleFilters);

  const onToggleClick = () => {
    if (deviceType === MOBILE_DEVICE) {
      setIsVisible(!isVisible);
      return;
    }
    setIsToggle(!isToggle);
    setItemToLocalStorage(IS_SIDEBAR_TOGGLE, JSON.stringify(!isToggle));
  };

  return (
    <>
      <div className="flex w-full flex-row items-center justify-between gap-8 border-b px-8 py-4 dark:border-jet">
        <div className="flex w-full items-center gap-2 overflow-hidden text-ellipsis whitespace-nowrap">
          {isToggle ? (
            <div>
              <Tooltip content="Show menu" className="metadata_tooltip" arrow={false}>
                <Button
                  btnType="plain"
                  btnSize="sm"
                  icon={<Icon type="doubleleft" size="icon-sm" />}
                  onClick={onToggleClick}
                />
              </Tooltip>
            </div>
          ) : null}
          {breadCrumb ? <BreadCrumbWrapper breadCrumb={breadCrumb} /> : null}
        </div>
        <div className="flex items-center gap-2 whitespace-nowrap">
          {informationText ? (
            <span className="text-sm font-medium text-gray-500">{informationText}</span>
          ) : null}
          {secondaryBtn ? (
            <div className="flex gap-2">
              {secondaryBtn}
              {!isEmpty(btnDetails) ? <CommonTableV2AddButton btnDetails={btnDetails} /> : null}
            </div>
          ) : (
            <>{!isEmpty(btnDetails) ? <CommonTableV2AddButton btnDetails={btnDetails} /> : null}</>
          )}
          {displayEditBtn ? (
            <>
              <Button
                btnType="secondary"
                btnTitle="Discard"
                btnSize="sm"
                disabled={!displayFormBtns || isLoading}
                onClick={onDiscardClick}
                onMouseDown={(e) => e.preventDefault()}
                type="button"
              />
              {displaySaveAndAddBtn ? (
                <Button
                  btnType="secondary"
                  btnTitle="Save & add more"
                  btnSize="sm"
                  disabled={!displayFormBtns || isLoading}
                  onClick={onSaveAndAddMoreClick}
                  onMouseDown={(e) => e.preventDefault()}
                  type="button"
                />
              ) : null}
              <Button
                btnTitle="Save Changes"
                btnSize="sm"
                icon={isLoading ? <Spinner size="sm" light={true} className="flex" /> : null}
                disabled={!displayFormBtns || isLoading}
                onClick={onSaveClick}
                onMouseDown={(e) => e.preventDefault()}
              />
            </>
          ) : null}
          <NotificationDropdown
            shouldNotifyClickable={(shouldNotifyClickable && shouldNotifyClickable) || false}
          />
          {displayDots ? (
            <>
              <FavoriteDropDown
                onAction={onAction}
                disableDelete={disableDelete}
                disableShare={disableShare}
                additionalActions={additionalActions}
              />
              {!toggleMenuBar && displayToggleMenuBar ? (
                <>
                  <Tooltip content="Open sidebar" className="metadata_tooltip" arrow={false}>
                    <Button
                      btnType="plain"
                      btnSize="sm"
                      icon={<Icon type="view" fill={true} size="icon-sm" />}
                      onClick={() => {
                        resetToggleFilters();
                        setToggleMenuBar(!toggleMenuBar);
                      }}
                    />
                  </Tooltip>
                </>
              ) : null}
            </>
          ) : null}
          {displaySidebarBtn ? (
            <Tooltip content="Open sidebar" className="metadata_tooltip" arrow={false}>
              <Button
                btnType="plain"
                btnSize="sm"
                icon={<Icon type="view" fill={true} size="icon-sm" />}
                onClick={() => {
                  onSidebarBtnClick?.();
                }}
              />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Header;
