/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Control,
  ControlRegister,
  ControlRegisterRequest,
  ControlRequest,
  Incident,
  IncidentRegister,
  IncidentRegisterRequest,
  IncidentRequest,
  PaginatedControlList,
  PaginatedControlRegisterList,
  PaginatedIncidentList,
  PaginatedIncidentRegisterList,
  PaginatedRiskCommentList,
  PaginatedRiskListList,
  PaginatedRiskMatrixTemplateList,
  PaginatedRiskRegisterItemList,
  PaginatedRiskTemplateList,
  PaginatedRiskTemplateRegisterResponseList,
  PatchedControlRegisterRequest,
  PatchedControlRequest,
  PatchedIncidentRegisterRequest,
  PatchedIncidentRequest,
  PatchedRiskCommentRequest,
  PatchedRiskMatrixTemplateRequest,
  PatchedRiskRegisterRequest,
  PatchedRiskRequest,
  PatchedRiskTemplateRegisterRequest,
  PatchedRiskTemplateRequest,
  Risk,
  RiskComment,
  RiskCommentRequest,
  RiskList,
  RiskMatrixTemplate,
  RiskMatrixTemplateRequest,
  RiskRegister,
  RiskRegisterItem,
  RiskRegisterRequest,
  RiskRequest,
  RiskTemplate,
  RiskTemplateRegisterRequest,
  RiskTemplateRegisterResponse,
  RiskTemplateRequest,
} from '../models/index';
import {
    ControlFromJSON,
    ControlToJSON,
    ControlRegisterFromJSON,
    ControlRegisterToJSON,
    ControlRegisterRequestFromJSON,
    ControlRegisterRequestToJSON,
    ControlRequestFromJSON,
    ControlRequestToJSON,
    IncidentFromJSON,
    IncidentToJSON,
    IncidentRegisterFromJSON,
    IncidentRegisterToJSON,
    IncidentRegisterRequestFromJSON,
    IncidentRegisterRequestToJSON,
    IncidentRequestFromJSON,
    IncidentRequestToJSON,
    PaginatedControlListFromJSON,
    PaginatedControlListToJSON,
    PaginatedControlRegisterListFromJSON,
    PaginatedControlRegisterListToJSON,
    PaginatedIncidentListFromJSON,
    PaginatedIncidentListToJSON,
    PaginatedIncidentRegisterListFromJSON,
    PaginatedIncidentRegisterListToJSON,
    PaginatedRiskCommentListFromJSON,
    PaginatedRiskCommentListToJSON,
    PaginatedRiskListListFromJSON,
    PaginatedRiskListListToJSON,
    PaginatedRiskMatrixTemplateListFromJSON,
    PaginatedRiskMatrixTemplateListToJSON,
    PaginatedRiskRegisterItemListFromJSON,
    PaginatedRiskRegisterItemListToJSON,
    PaginatedRiskTemplateListFromJSON,
    PaginatedRiskTemplateListToJSON,
    PaginatedRiskTemplateRegisterResponseListFromJSON,
    PaginatedRiskTemplateRegisterResponseListToJSON,
    PatchedControlRegisterRequestFromJSON,
    PatchedControlRegisterRequestToJSON,
    PatchedControlRequestFromJSON,
    PatchedControlRequestToJSON,
    PatchedIncidentRegisterRequestFromJSON,
    PatchedIncidentRegisterRequestToJSON,
    PatchedIncidentRequestFromJSON,
    PatchedIncidentRequestToJSON,
    PatchedRiskCommentRequestFromJSON,
    PatchedRiskCommentRequestToJSON,
    PatchedRiskMatrixTemplateRequestFromJSON,
    PatchedRiskMatrixTemplateRequestToJSON,
    PatchedRiskRegisterRequestFromJSON,
    PatchedRiskRegisterRequestToJSON,
    PatchedRiskRequestFromJSON,
    PatchedRiskRequestToJSON,
    PatchedRiskTemplateRegisterRequestFromJSON,
    PatchedRiskTemplateRegisterRequestToJSON,
    PatchedRiskTemplateRequestFromJSON,
    PatchedRiskTemplateRequestToJSON,
    RiskFromJSON,
    RiskToJSON,
    RiskCommentFromJSON,
    RiskCommentToJSON,
    RiskCommentRequestFromJSON,
    RiskCommentRequestToJSON,
    RiskListFromJSON,
    RiskListToJSON,
    RiskMatrixTemplateFromJSON,
    RiskMatrixTemplateToJSON,
    RiskMatrixTemplateRequestFromJSON,
    RiskMatrixTemplateRequestToJSON,
    RiskRegisterFromJSON,
    RiskRegisterToJSON,
    RiskRegisterItemFromJSON,
    RiskRegisterItemToJSON,
    RiskRegisterRequestFromJSON,
    RiskRegisterRequestToJSON,
    RiskRequestFromJSON,
    RiskRequestToJSON,
    RiskTemplateFromJSON,
    RiskTemplateToJSON,
    RiskTemplateRegisterRequestFromJSON,
    RiskTemplateRegisterRequestToJSON,
    RiskTemplateRegisterResponseFromJSON,
    RiskTemplateRegisterResponseToJSON,
    RiskTemplateRequestFromJSON,
    RiskTemplateRequestToJSON,
} from '../models/index';

export interface RisksControlRegistersCreateRequest {
    controlRegisterRequest: ControlRegisterRequest;
}

export interface RisksControlRegistersDestroyRequest {
    id: number;
}

export interface RisksControlRegistersListRequest {
    name?: string;
    nameIcontains?: string;
    objectIsPublic?: boolean;
    objectOwner?: number;
    objectOwnerIn?: Array<number>;
    objectOwnerId?: number | null;
    objectOwnerIdIn?: Array<number>;
    page?: number;
    pageSize?: number;
}

export interface RisksControlRegistersPartialUpdateRequest {
    id: number;
    patchedControlRegisterRequest?: PatchedControlRegisterRequest;
}

export interface RisksControlRegistersRetrieveRequest {
    id: number;
}

export interface RisksControlRegistersUpdateRequest {
    id: number;
    controlRegisterRequest: ControlRegisterRequest;
}

export interface RisksControlsCreateRequest {
    controlRequest: Omit<ControlRequest, 'id'|'created_at'|'updated_at'|'created_by'|'updated_by'>;
}

export interface RisksControlsDestroyRequest {
    id: number;
}

export interface RisksControlsListRequest {
    approvalStatusIn?: Array<string>;
    approvedBy?: Array<string>;
    approvedByIn?: Array<string>;
    approvedById?: Array<string>;
    approvedByIdIn?: Array<string>;
    description?: string;
    descriptionIcontains?: string;
    name?: string;
    nameIcontains?: string;
    objectIsPublic?: boolean;
    objectOwner?: number;
    objectOwnerIn?: Array<number>;
    objectOwnerId?: number | null;
    objectOwnerIdIn?: Array<number>;
    page?: number;
    pageSize?: number;
}

export interface RisksControlsPartialUpdateRequest {
    id: number;
    patchedControlRequest?: PatchedControlRequest;
}

export interface RisksControlsRetrieveRequest {
    id: number;
}

export interface RisksControlsUpdateRequest {
    id: number;
    controlRequest: Omit<ControlRequest, 'id'|'created_at'|'updated_at'|'created_by'|'updated_by'>;
}

export interface RisksIncidentRegistersCreateRequest {
    incidentRegisterRequest: IncidentRegisterRequest;
}

export interface RisksIncidentRegistersDestroyRequest {
    id: number;
}

export interface RisksIncidentRegistersListRequest {
    name?: string;
    nameIcontains?: string;
    objectIsPublic?: boolean;
    objectOwner?: number;
    objectOwnerIn?: Array<number>;
    objectOwnerId?: number | null;
    objectOwnerIdIn?: Array<number>;
    page?: number;
    pageSize?: number;
}

export interface RisksIncidentRegistersPartialUpdateRequest {
    id: number;
    patchedIncidentRegisterRequest?: PatchedIncidentRegisterRequest;
}

export interface RisksIncidentRegistersRetrieveRequest {
    id: number;
}

export interface RisksIncidentRegistersUpdateRequest {
    id: number;
    incidentRegisterRequest: IncidentRegisterRequest;
}

export interface RisksIncidentsCreateRequest {
    incidentRequest: IncidentRequest;
}

export interface RisksIncidentsDestroyRequest {
    id: number;
}

export interface RisksIncidentsDocumentsCreateRequest {
    id: number;
    incidentRequest: IncidentRequest;
}

export interface RisksIncidentsDocumentsDestroyRequest {
    docId: string;
    id: number;
}

export interface RisksIncidentsDocumentsRetrieveRequest {
    id: number;
}

export interface RisksIncidentsListRequest {
    description?: string;
    descriptionIcontains?: string;
    name?: string;
    nameIcontains?: string;
    page?: number;
    pageSize?: number;
}

export interface RisksIncidentsPartialUpdateRequest {
    id: number;
    patchedIncidentRequest?: PatchedIncidentRequest;
}

export interface RisksIncidentsRetrieveRequest {
    id: number;
}

export interface RisksIncidentsUpdateRequest {
    id: number;
    incidentRequest: IncidentRequest;
}

export interface RisksMatrixTemplatesCreateRequest {
    riskMatrixTemplateRequest: RiskMatrixTemplateRequest;
}

export interface RisksMatrixTemplatesDestroyRequest {
    id: number;
}

export interface RisksMatrixTemplatesListRequest {
    company: number;
    page?: number;
    pageSize?: number;
}

export interface RisksMatrixTemplatesPartialUpdateRequest {
    id: number;
    patchedRiskMatrixTemplateRequest?: PatchedRiskMatrixTemplateRequest;
}

export interface RisksMatrixTemplatesRetrieveRequest {
    id: number;
}

export interface RisksMatrixTemplatesUpdateRequest {
    id: number;
    riskMatrixTemplateRequest: RiskMatrixTemplateRequest;
}

export interface RisksRegistersCreateRequest {
    riskRegisterRequest: RiskRegisterRequest;
}

export interface RisksRegistersDestroyRequest {
    id: number;
}

export interface RisksRegistersListRequest {
    company: number;
    name?: string;
    nameIcontains?: string;
    objectIsPublic?: boolean;
    objectOwner?: number;
    objectOwnerIn?: Array<number>;
    objectOwnerId?: number | null;
    objectOwnerIdIn?: Array<number>;
    ordering?: string;
    page?: number;
    pageSize?: number;
}

export interface RisksRegistersPartialUpdateRequest {
    id: number;
    patchedRiskRegisterRequest?: PatchedRiskRegisterRequest;
}

export interface RisksRegistersRetrieveRequest {
    company: number;
    id: number;
}

export interface RisksRegistersUpdateRequest {
    id: number;
    riskRegisterRequest: RiskRegisterRequest;
}

export interface RisksRiskCommentsCreateRequest {
    riskCommentRequest: RiskCommentRequest;
}

export interface RisksRiskCommentsDestroyRequest {
    id: number;
}

export interface RisksRiskCommentsListRequest {
    page?: number;
    pageSize?: number;
    status?: RisksRiskCommentsListStatusEnum;
}

export interface RisksRiskCommentsPartialUpdateRequest {
    id: number;
    patchedRiskCommentRequest?: PatchedRiskCommentRequest;
}

export interface RisksRiskCommentsRetrieveRequest {
    id: number;
}

export interface RisksRiskCommentsUpdateRequest {
    id: number;
    riskCommentRequest: RiskCommentRequest;
}

export interface RisksRisksCreateRequest {
    register: number;
    riskRequest: RiskRequest;
}

export interface RisksRisksDestroyRequest {
    company: number;
    id: number;
}

export interface RisksRisksListRequest {
    company: number;
    approvalStatusIn?: Array<string>;
    approvedBy?: Array<string>;
    approvedByIn?: Array<string>;
    approvedById?: Array<string>;
    approvedByIdIn?: Array<string>;
    area?: number;
    areaIn?: Array<number>;
    areaIdIn?: Array<number>;
    createdAt?: Date;
    createdAtGt?: Date;
    createdAtIn?: Array<Date>;
    createdAtLt?: Date;
    createdAtRange?: Array<Date>;
    customIdIn?: Array<string>;
    customIdNotIn?: Array<string>;
    inherentRiskLabelIn?: Array<string>;
    keywordsIn?: Array<string>;
    keywordsNotIn?: Array<string>;
    name?: string;
    nameIcontains?: string;
    objectOwnerIdIn?: Array<number>;
    ordering?: string;
    ownerIn?: Array<number>;
    ownerIdIn?: Array<number>;
    page?: number;
    pageSize?: number;
    register?: Array<string>;
    residualRiskLabelIn?: Array<string>;
    riskType?: number;
    riskTypeIn?: Array<number>;
    search?: string;
    statusIn?: Array<string>;
    tagsIn?: Array<string>;
    target?: number;
    targetIn?: Array<number>;
    treatmentStrategy?: RisksRisksListTreatmentStrategyEnum;
}

export interface RisksRisksPartialUpdateRequest {
    company: number;
    id: number;
    patchedRiskRequest?: PatchedRiskRequest;
}

export interface RisksRisksRetrieveRequest {
    company: number;
    id: number;
}

export interface RisksRisksUpdateRequest {
    company: number;
    id: number;
    riskRequest: RiskRequest;
}

export interface RisksTemplateRegistersCreateRequest {
    riskTemplateRegisterRequest: RiskTemplateRegisterRequest;
}

export interface RisksTemplateRegistersDestroyRequest {
    id: number;
}

export interface RisksTemplateRegistersListRequest {
    company: number;
    name?: string;
    nameIcontains?: string;
    objectIsPublic?: boolean;
    objectOwner?: number;
    objectOwnerIn?: Array<number>;
    objectOwnerId?: number | null;
    objectOwnerIdIn?: Array<number>;
    page?: number;
    pageSize?: number;
}

export interface RisksTemplateRegistersPartialUpdateRequest {
    id: number;
    patchedRiskTemplateRegisterRequest?: PatchedRiskTemplateRegisterRequest;
}

export interface RisksTemplateRegistersRetrieveRequest {
    company: number;
    id: number;
}

export interface RisksTemplateRegistersUpdateRequest {
    id: number;
    riskTemplateRegisterRequest: RiskTemplateRegisterRequest;
}

export interface RisksTemplatesCreateRequest {
    riskTemplateRequest: RiskTemplateRequest;
}

export interface RisksTemplatesDestroyRequest {
    id: number;
}

export interface RisksTemplatesListRequest {
    approvalStatusIn?: Array<string>;
    approvedBy?: Array<string>;
    approvedByIn?: Array<string>;
    approvedById?: Array<string>;
    approvedByIdIn?: Array<string>;
    area?: number;
    areaIn?: Array<number>;
    areaIdIn?: Array<number>;
    createdAt?: Date;
    createdAtGt?: Date;
    createdAtIn?: Array<Date>;
    createdAtLt?: Date;
    createdAtRange?: Array<Date>;
    customIdIn?: Array<string>;
    name?: string;
    nameIcontains?: string;
    ownerIn?: Array<number>;
    ownerIdIn?: Array<number>;
    page?: number;
    pageSize?: number;
    riskType?: number;
    riskTypeIn?: Array<number>;
    target?: number;
    targetIn?: Array<number>;
    treatmentStrategy?: RisksTemplatesListTreatmentStrategyEnum;
}

export interface RisksTemplatesPartialUpdateRequest {
    id: number;
    patchedRiskTemplateRequest?: PatchedRiskTemplateRequest;
}

export interface RisksTemplatesRetrieveRequest {
    id: number;
}

export interface RisksTemplatesUpdateRequest {
    id: number;
    riskTemplateRequest: RiskTemplateRequest;
}

/**
 * 
 */
export class RisksApi extends runtime.BaseAPI {

    /**
     */
    async risksControlRegistersCreateRaw(requestParameters: RisksControlRegistersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlRegister>> {
        if (requestParameters['controlRegisterRequest'] == null) {
            throw new runtime.RequiredError(
                'controlRegisterRequest',
                'Required parameter "controlRegisterRequest" was null or undefined when calling risksControlRegistersCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/control-registers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlRegisterRequestToJSON(requestParameters['controlRegisterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlRegisterFromJSON(jsonValue));
    }

    /**
     */
    async risksControlRegistersCreate(requestParameters: RisksControlRegistersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlRegister> {
        const response = await this.risksControlRegistersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksControlRegistersDestroyRaw(requestParameters: RisksControlRegistersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksControlRegistersDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/control-registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async risksControlRegistersDestroy(requestParameters: RisksControlRegistersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.risksControlRegistersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async risksControlRegistersListRaw(requestParameters: RisksControlRegistersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedControlRegisterList>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['objectIsPublic'] != null) {
            queryParameters['object_is_public'] = requestParameters['objectIsPublic'];
        }

        if (requestParameters['objectOwner'] != null) {
            queryParameters['object_owner'] = requestParameters['objectOwner'];
        }

        if (requestParameters['objectOwnerIn'] != null) {
            queryParameters['object_owner__in'] = requestParameters['objectOwnerIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['objectOwnerId'] != null) {
            queryParameters['object_owner_id'] = requestParameters['objectOwnerId'];
        }

        if (requestParameters['objectOwnerIdIn'] != null) {
            queryParameters['object_owner_id__in'] = requestParameters['objectOwnerIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/control-registers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedControlRegisterListFromJSON(jsonValue));
    }

    /**
     */
    async risksControlRegistersList(requestParameters: RisksControlRegistersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedControlRegisterList> {
        const response = await this.risksControlRegistersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksControlRegistersPartialUpdateRaw(requestParameters: RisksControlRegistersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlRegister>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksControlRegistersPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/control-registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedControlRegisterRequestToJSON(requestParameters['patchedControlRegisterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlRegisterFromJSON(jsonValue));
    }

    /**
     */
    async risksControlRegistersPartialUpdate(requestParameters: RisksControlRegistersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlRegister> {
        const response = await this.risksControlRegistersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksControlRegistersRetrieveRaw(requestParameters: RisksControlRegistersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlRegister>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksControlRegistersRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/control-registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlRegisterFromJSON(jsonValue));
    }

    /**
     */
    async risksControlRegistersRetrieve(requestParameters: RisksControlRegistersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlRegister> {
        const response = await this.risksControlRegistersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksControlRegistersUpdateRaw(requestParameters: RisksControlRegistersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ControlRegister>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksControlRegistersUpdate().'
            );
        }

        if (requestParameters['controlRegisterRequest'] == null) {
            throw new runtime.RequiredError(
                'controlRegisterRequest',
                'Required parameter "controlRegisterRequest" was null or undefined when calling risksControlRegistersUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/control-registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlRegisterRequestToJSON(requestParameters['controlRegisterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlRegisterFromJSON(jsonValue));
    }

    /**
     */
    async risksControlRegistersUpdate(requestParameters: RisksControlRegistersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ControlRegister> {
        const response = await this.risksControlRegistersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksControlsCreateRaw(requestParameters: RisksControlsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Control>> {
        if (requestParameters['controlRequest'] == null) {
            throw new runtime.RequiredError(
                'controlRequest',
                'Required parameter "controlRequest" was null or undefined when calling risksControlsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/controls/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ControlRequestToJSON(requestParameters['controlRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlFromJSON(jsonValue));
    }

    /**
     */
    async risksControlsCreate(requestParameters: RisksControlsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Control> {
        const response = await this.risksControlsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksControlsDestroyRaw(requestParameters: RisksControlsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksControlsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/controls/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async risksControlsDestroy(requestParameters: RisksControlsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.risksControlsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async risksControlsListRaw(requestParameters: RisksControlsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedControlList>> {
        const queryParameters: any = {};

        if (requestParameters['approvalStatusIn'] != null) {
            queryParameters['approval_status__in'] = requestParameters['approvalStatusIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvedBy'] != null) {
            queryParameters['approved_by'] = requestParameters['approvedBy']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvedByIn'] != null) {
            queryParameters['approved_by__in'] = requestParameters['approvedByIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvedById'] != null) {
            queryParameters['approved_by_id'] = requestParameters['approvedById']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvedByIdIn'] != null) {
            queryParameters['approved_by_id__in'] = requestParameters['approvedByIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['description'] != null) {
            queryParameters['description'] = requestParameters['description'];
        }

        if (requestParameters['descriptionIcontains'] != null) {
            queryParameters['description__icontains'] = requestParameters['descriptionIcontains'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['objectIsPublic'] != null) {
            queryParameters['object_is_public'] = requestParameters['objectIsPublic'];
        }

        if (requestParameters['objectOwner'] != null) {
            queryParameters['object_owner'] = requestParameters['objectOwner'];
        }

        if (requestParameters['objectOwnerIn'] != null) {
            queryParameters['object_owner__in'] = requestParameters['objectOwnerIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['objectOwnerId'] != null) {
            queryParameters['object_owner_id'] = requestParameters['objectOwnerId'];
        }

        if (requestParameters['objectOwnerIdIn'] != null) {
            queryParameters['object_owner_id__in'] = requestParameters['objectOwnerIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/controls/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedControlListFromJSON(jsonValue));
    }

    /**
     */
    async risksControlsList(requestParameters: RisksControlsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedControlList> {
        const response = await this.risksControlsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksControlsPartialUpdateRaw(requestParameters: RisksControlsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Control>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksControlsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/controls/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedControlRequestToJSON(requestParameters['patchedControlRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlFromJSON(jsonValue));
    }

    /**
     */
    async risksControlsPartialUpdate(requestParameters: RisksControlsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Control> {
        const response = await this.risksControlsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksControlsRetrieveRaw(requestParameters: RisksControlsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Control>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksControlsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/controls/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlFromJSON(jsonValue));
    }

    /**
     */
    async risksControlsRetrieve(requestParameters: RisksControlsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Control> {
        const response = await this.risksControlsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksControlsUpdateRaw(requestParameters: RisksControlsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Control>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksControlsUpdate().'
            );
        }

        if (requestParameters['controlRequest'] == null) {
            throw new runtime.RequiredError(
                'controlRequest',
                'Required parameter "controlRequest" was null or undefined when calling risksControlsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/controls/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ControlRequestToJSON(requestParameters['controlRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ControlFromJSON(jsonValue));
    }

    /**
     */
    async risksControlsUpdate(requestParameters: RisksControlsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Control> {
        const response = await this.risksControlsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksIncidentRegistersCreateRaw(requestParameters: RisksIncidentRegistersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IncidentRegister>> {
        if (requestParameters['incidentRegisterRequest'] == null) {
            throw new runtime.RequiredError(
                'incidentRegisterRequest',
                'Required parameter "incidentRegisterRequest" was null or undefined when calling risksIncidentRegistersCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incident-registers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentRegisterRequestToJSON(requestParameters['incidentRegisterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentRegisterFromJSON(jsonValue));
    }

    /**
     */
    async risksIncidentRegistersCreate(requestParameters: RisksIncidentRegistersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IncidentRegister> {
        const response = await this.risksIncidentRegistersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksIncidentRegistersDestroyRaw(requestParameters: RisksIncidentRegistersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksIncidentRegistersDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incident-registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async risksIncidentRegistersDestroy(requestParameters: RisksIncidentRegistersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.risksIncidentRegistersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async risksIncidentRegistersListRaw(requestParameters: RisksIncidentRegistersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedIncidentRegisterList>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['objectIsPublic'] != null) {
            queryParameters['object_is_public'] = requestParameters['objectIsPublic'];
        }

        if (requestParameters['objectOwner'] != null) {
            queryParameters['object_owner'] = requestParameters['objectOwner'];
        }

        if (requestParameters['objectOwnerIn'] != null) {
            queryParameters['object_owner__in'] = requestParameters['objectOwnerIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['objectOwnerId'] != null) {
            queryParameters['object_owner_id'] = requestParameters['objectOwnerId'];
        }

        if (requestParameters['objectOwnerIdIn'] != null) {
            queryParameters['object_owner_id__in'] = requestParameters['objectOwnerIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incident-registers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedIncidentRegisterListFromJSON(jsonValue));
    }

    /**
     */
    async risksIncidentRegistersList(requestParameters: RisksIncidentRegistersListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedIncidentRegisterList> {
        const response = await this.risksIncidentRegistersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksIncidentRegistersPartialUpdateRaw(requestParameters: RisksIncidentRegistersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IncidentRegister>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksIncidentRegistersPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incident-registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedIncidentRegisterRequestToJSON(requestParameters['patchedIncidentRegisterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentRegisterFromJSON(jsonValue));
    }

    /**
     */
    async risksIncidentRegistersPartialUpdate(requestParameters: RisksIncidentRegistersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IncidentRegister> {
        const response = await this.risksIncidentRegistersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksIncidentRegistersRetrieveRaw(requestParameters: RisksIncidentRegistersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IncidentRegister>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksIncidentRegistersRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incident-registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentRegisterFromJSON(jsonValue));
    }

    /**
     */
    async risksIncidentRegistersRetrieve(requestParameters: RisksIncidentRegistersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IncidentRegister> {
        const response = await this.risksIncidentRegistersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksIncidentRegistersUpdateRaw(requestParameters: RisksIncidentRegistersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IncidentRegister>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksIncidentRegistersUpdate().'
            );
        }

        if (requestParameters['incidentRegisterRequest'] == null) {
            throw new runtime.RequiredError(
                'incidentRegisterRequest',
                'Required parameter "incidentRegisterRequest" was null or undefined when calling risksIncidentRegistersUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incident-registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentRegisterRequestToJSON(requestParameters['incidentRegisterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentRegisterFromJSON(jsonValue));
    }

    /**
     */
    async risksIncidentRegistersUpdate(requestParameters: RisksIncidentRegistersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IncidentRegister> {
        const response = await this.risksIncidentRegistersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksIncidentsCreateRaw(requestParameters: RisksIncidentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Incident>> {
        if (requestParameters['incidentRequest'] == null) {
            throw new runtime.RequiredError(
                'incidentRequest',
                'Required parameter "incidentRequest" was null or undefined when calling risksIncidentsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incidents/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentRequestToJSON(requestParameters['incidentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFromJSON(jsonValue));
    }

    /**
     */
    async risksIncidentsCreate(requestParameters: RisksIncidentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Incident> {
        const response = await this.risksIncidentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksIncidentsDestroyRaw(requestParameters: RisksIncidentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksIncidentsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incidents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async risksIncidentsDestroy(requestParameters: RisksIncidentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.risksIncidentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async risksIncidentsDocumentsCreateRaw(requestParameters: RisksIncidentsDocumentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Incident>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksIncidentsDocumentsCreate().'
            );
        }

        if (requestParameters['incidentRequest'] == null) {
            throw new runtime.RequiredError(
                'incidentRequest',
                'Required parameter "incidentRequest" was null or undefined when calling risksIncidentsDocumentsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incidents/{id}/documents/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentRequestToJSON(requestParameters['incidentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFromJSON(jsonValue));
    }

    /**
     */
    async risksIncidentsDocumentsCreate(requestParameters: RisksIncidentsDocumentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Incident> {
        const response = await this.risksIncidentsDocumentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksIncidentsDocumentsDestroyRaw(requestParameters: RisksIncidentsDocumentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['docId'] == null) {
            throw new runtime.RequiredError(
                'docId',
                'Required parameter "docId" was null or undefined when calling risksIncidentsDocumentsDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksIncidentsDocumentsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incidents/{id}/documents/{doc_id}/`.replace(`{${"doc_id"}}`, encodeURIComponent(String(requestParameters['docId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async risksIncidentsDocumentsDestroy(requestParameters: RisksIncidentsDocumentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.risksIncidentsDocumentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async risksIncidentsDocumentsRetrieveRaw(requestParameters: RisksIncidentsDocumentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Incident>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksIncidentsDocumentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incidents/{id}/documents/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFromJSON(jsonValue));
    }

    /**
     */
    async risksIncidentsDocumentsRetrieve(requestParameters: RisksIncidentsDocumentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Incident> {
        const response = await this.risksIncidentsDocumentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksIncidentsListRaw(requestParameters: RisksIncidentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedIncidentList>> {
        const queryParameters: any = {};

        if (requestParameters['description'] != null) {
            queryParameters['description'] = requestParameters['description'];
        }

        if (requestParameters['descriptionIcontains'] != null) {
            queryParameters['description__icontains'] = requestParameters['descriptionIcontains'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incidents/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedIncidentListFromJSON(jsonValue));
    }

    /**
     */
    async risksIncidentsList(requestParameters: RisksIncidentsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedIncidentList> {
        const response = await this.risksIncidentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksIncidentsPartialUpdateRaw(requestParameters: RisksIncidentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Incident>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksIncidentsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incidents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedIncidentRequestToJSON(requestParameters['patchedIncidentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFromJSON(jsonValue));
    }

    /**
     */
    async risksIncidentsPartialUpdate(requestParameters: RisksIncidentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Incident> {
        const response = await this.risksIncidentsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksIncidentsRetrieveRaw(requestParameters: RisksIncidentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Incident>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksIncidentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incidents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFromJSON(jsonValue));
    }

    /**
     */
    async risksIncidentsRetrieve(requestParameters: RisksIncidentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Incident> {
        const response = await this.risksIncidentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksIncidentsUpdateRaw(requestParameters: RisksIncidentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Incident>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksIncidentsUpdate().'
            );
        }

        if (requestParameters['incidentRequest'] == null) {
            throw new runtime.RequiredError(
                'incidentRequest',
                'Required parameter "incidentRequest" was null or undefined when calling risksIncidentsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/incidents/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: IncidentRequestToJSON(requestParameters['incidentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IncidentFromJSON(jsonValue));
    }

    /**
     */
    async risksIncidentsUpdate(requestParameters: RisksIncidentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Incident> {
        const response = await this.risksIncidentsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksMatrixTemplatesCreateRaw(requestParameters: RisksMatrixTemplatesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskMatrixTemplate>> {
        if (requestParameters['riskMatrixTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'riskMatrixTemplateRequest',
                'Required parameter "riskMatrixTemplateRequest" was null or undefined when calling risksMatrixTemplatesCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/matrix-templates/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RiskMatrixTemplateRequestToJSON(requestParameters['riskMatrixTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskMatrixTemplateFromJSON(jsonValue));
    }

    /**
     */
    async risksMatrixTemplatesCreate(requestParameters: RisksMatrixTemplatesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskMatrixTemplate> {
        const response = await this.risksMatrixTemplatesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksMatrixTemplatesDestroyRaw(requestParameters: RisksMatrixTemplatesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksMatrixTemplatesDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/matrix-templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async risksMatrixTemplatesDestroy(requestParameters: RisksMatrixTemplatesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.risksMatrixTemplatesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async risksMatrixTemplatesListRaw(requestParameters: RisksMatrixTemplatesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRiskMatrixTemplateList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling risksMatrixTemplatesList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/matrix-templates/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRiskMatrixTemplateListFromJSON(jsonValue));
    }

    /**
     */
    async risksMatrixTemplatesList(requestParameters: RisksMatrixTemplatesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRiskMatrixTemplateList> {
        const response = await this.risksMatrixTemplatesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksMatrixTemplatesPartialUpdateRaw(requestParameters: RisksMatrixTemplatesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskMatrixTemplate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksMatrixTemplatesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/matrix-templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedRiskMatrixTemplateRequestToJSON(requestParameters['patchedRiskMatrixTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskMatrixTemplateFromJSON(jsonValue));
    }

    /**
     */
    async risksMatrixTemplatesPartialUpdate(requestParameters: RisksMatrixTemplatesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskMatrixTemplate> {
        const response = await this.risksMatrixTemplatesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksMatrixTemplatesRetrieveRaw(requestParameters: RisksMatrixTemplatesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskMatrixTemplate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksMatrixTemplatesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/matrix-templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskMatrixTemplateFromJSON(jsonValue));
    }

    /**
     */
    async risksMatrixTemplatesRetrieve(requestParameters: RisksMatrixTemplatesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskMatrixTemplate> {
        const response = await this.risksMatrixTemplatesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksMatrixTemplatesUpdateRaw(requestParameters: RisksMatrixTemplatesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskMatrixTemplate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksMatrixTemplatesUpdate().'
            );
        }

        if (requestParameters['riskMatrixTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'riskMatrixTemplateRequest',
                'Required parameter "riskMatrixTemplateRequest" was null or undefined when calling risksMatrixTemplatesUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/matrix-templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RiskMatrixTemplateRequestToJSON(requestParameters['riskMatrixTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskMatrixTemplateFromJSON(jsonValue));
    }

    /**
     */
    async risksMatrixTemplatesUpdate(requestParameters: RisksMatrixTemplatesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskMatrixTemplate> {
        const response = await this.risksMatrixTemplatesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRegistersCreateRaw(requestParameters: RisksRegistersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskRegister>> {
        if (requestParameters['riskRegisterRequest'] == null) {
            throw new runtime.RequiredError(
                'riskRegisterRequest',
                'Required parameter "riskRegisterRequest" was null or undefined when calling risksRegistersCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/registers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RiskRegisterRequestToJSON(requestParameters['riskRegisterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskRegisterFromJSON(jsonValue));
    }

    /**
     */
    async risksRegistersCreate(requestParameters: RisksRegistersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskRegister> {
        const response = await this.risksRegistersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRegistersDestroyRaw(requestParameters: RisksRegistersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksRegistersDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async risksRegistersDestroy(requestParameters: RisksRegistersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.risksRegistersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async risksRegistersListRaw(requestParameters: RisksRegistersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRiskRegisterItemList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling risksRegistersList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['objectIsPublic'] != null) {
            queryParameters['object_is_public'] = requestParameters['objectIsPublic'];
        }

        if (requestParameters['objectOwner'] != null) {
            queryParameters['object_owner'] = requestParameters['objectOwner'];
        }

        if (requestParameters['objectOwnerIn'] != null) {
            queryParameters['object_owner__in'] = requestParameters['objectOwnerIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['objectOwnerId'] != null) {
            queryParameters['object_owner_id'] = requestParameters['objectOwnerId'];
        }

        if (requestParameters['objectOwnerIdIn'] != null) {
            queryParameters['object_owner_id__in'] = requestParameters['objectOwnerIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/registers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRiskRegisterItemListFromJSON(jsonValue));
    }

    /**
     */
    async risksRegistersList(requestParameters: RisksRegistersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRiskRegisterItemList> {
        const response = await this.risksRegistersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRegistersPartialUpdateRaw(requestParameters: RisksRegistersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskRegister>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksRegistersPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedRiskRegisterRequestToJSON(requestParameters['patchedRiskRegisterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskRegisterFromJSON(jsonValue));
    }

    /**
     */
    async risksRegistersPartialUpdate(requestParameters: RisksRegistersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskRegister> {
        const response = await this.risksRegistersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRegistersRetrieveRaw(requestParameters: RisksRegistersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskRegisterItem>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling risksRegistersRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksRegistersRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskRegisterItemFromJSON(jsonValue));
    }

    /**
     */
    async risksRegistersRetrieve(requestParameters: RisksRegistersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskRegisterItem> {
        const response = await this.risksRegistersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRegistersUpdateRaw(requestParameters: RisksRegistersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskRegister>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksRegistersUpdate().'
            );
        }

        if (requestParameters['riskRegisterRequest'] == null) {
            throw new runtime.RequiredError(
                'riskRegisterRequest',
                'Required parameter "riskRegisterRequest" was null or undefined when calling risksRegistersUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RiskRegisterRequestToJSON(requestParameters['riskRegisterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskRegisterFromJSON(jsonValue));
    }

    /**
     */
    async risksRegistersUpdate(requestParameters: RisksRegistersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskRegister> {
        const response = await this.risksRegistersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRiskCommentsCreateRaw(requestParameters: RisksRiskCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskComment>> {
        if (requestParameters['riskCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'riskCommentRequest',
                'Required parameter "riskCommentRequest" was null or undefined when calling risksRiskCommentsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/risk-comments/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RiskCommentRequestToJSON(requestParameters['riskCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskCommentFromJSON(jsonValue));
    }

    /**
     */
    async risksRiskCommentsCreate(requestParameters: RisksRiskCommentsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskComment> {
        const response = await this.risksRiskCommentsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRiskCommentsDestroyRaw(requestParameters: RisksRiskCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksRiskCommentsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/risk-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async risksRiskCommentsDestroy(requestParameters: RisksRiskCommentsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.risksRiskCommentsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async risksRiskCommentsListRaw(requestParameters: RisksRiskCommentsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRiskCommentList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/risk-comments/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRiskCommentListFromJSON(jsonValue));
    }

    /**
     */
    async risksRiskCommentsList(requestParameters: RisksRiskCommentsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRiskCommentList> {
        const response = await this.risksRiskCommentsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRiskCommentsPartialUpdateRaw(requestParameters: RisksRiskCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksRiskCommentsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/risk-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedRiskCommentRequestToJSON(requestParameters['patchedRiskCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskCommentFromJSON(jsonValue));
    }

    /**
     */
    async risksRiskCommentsPartialUpdate(requestParameters: RisksRiskCommentsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskComment> {
        const response = await this.risksRiskCommentsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRiskCommentsRetrieveRaw(requestParameters: RisksRiskCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksRiskCommentsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/risk-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskCommentFromJSON(jsonValue));
    }

    /**
     */
    async risksRiskCommentsRetrieve(requestParameters: RisksRiskCommentsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskComment> {
        const response = await this.risksRiskCommentsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRiskCommentsUpdateRaw(requestParameters: RisksRiskCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskComment>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksRiskCommentsUpdate().'
            );
        }

        if (requestParameters['riskCommentRequest'] == null) {
            throw new runtime.RequiredError(
                'riskCommentRequest',
                'Required parameter "riskCommentRequest" was null or undefined when calling risksRiskCommentsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/risk-comments/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RiskCommentRequestToJSON(requestParameters['riskCommentRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskCommentFromJSON(jsonValue));
    }

    /**
     */
    async risksRiskCommentsUpdate(requestParameters: RisksRiskCommentsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskComment> {
        const response = await this.risksRiskCommentsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRisksCreateRaw(requestParameters: RisksRisksCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Risk>> {
        if (requestParameters['register'] == null) {
            throw new runtime.RequiredError(
                'register',
                'Required parameter "register" was null or undefined when calling risksRisksCreate().'
            );
        }

        if (requestParameters['riskRequest'] == null) {
            throw new runtime.RequiredError(
                'riskRequest',
                'Required parameter "riskRequest" was null or undefined when calling risksRisksCreate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['register'] != null) {
            queryParameters['register'] = requestParameters['register'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/risks/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RiskRequestToJSON(requestParameters['riskRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskFromJSON(jsonValue));
    }

    /**
     */
    async risksRisksCreate(requestParameters: RisksRisksCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Risk> {
        const response = await this.risksRisksCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRisksDestroyRaw(requestParameters: RisksRisksDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling risksRisksDestroy().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksRisksDestroy().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/risks/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async risksRisksDestroy(requestParameters: RisksRisksDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.risksRisksDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async risksRisksListRaw(requestParameters: RisksRisksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRiskListList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling risksRisksList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['approvalStatusIn'] != null) {
            queryParameters['approval_status__in'] = requestParameters['approvalStatusIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvedBy'] != null) {
            queryParameters['approved_by'] = requestParameters['approvedBy']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvedByIn'] != null) {
            queryParameters['approved_by__in'] = requestParameters['approvedByIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvedById'] != null) {
            queryParameters['approved_by_id'] = requestParameters['approvedById']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvedByIdIn'] != null) {
            queryParameters['approved_by_id__in'] = requestParameters['approvedByIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['area'] != null) {
            queryParameters['area'] = requestParameters['area'];
        }

        if (requestParameters['areaIn'] != null) {
            queryParameters['area__in'] = requestParameters['areaIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['areaIdIn'] != null) {
            queryParameters['area_id__in'] = requestParameters['areaIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['createdAt'] != null) {
            queryParameters['created_at'] = (requestParameters['createdAt'] as any).toISOString();
        }

        if (requestParameters['createdAtGt'] != null) {
            queryParameters['created_at__gt'] = (requestParameters['createdAtGt'] as any).toISOString();
        }

        if (requestParameters['createdAtIn'] != null) {
            queryParameters['created_at__in'] = requestParameters['createdAtIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['createdAtLt'] != null) {
            queryParameters['created_at__lt'] = (requestParameters['createdAtLt'] as any).toISOString();
        }

        if (requestParameters['createdAtRange'] != null) {
            queryParameters['created_at__range'] = requestParameters['createdAtRange']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['customIdIn'] != null) {
            queryParameters['custom_id__in'] = requestParameters['customIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['customIdNotIn'] != null) {
            queryParameters['custom_id_not__in'] = requestParameters['customIdNotIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['inherentRiskLabelIn'] != null) {
            queryParameters['inherent_risk_label__in'] = requestParameters['inherentRiskLabelIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['keywordsIn'] != null) {
            queryParameters['keywords__in'] = requestParameters['keywordsIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['keywordsNotIn'] != null) {
            queryParameters['keywords__not_in'] = requestParameters['keywordsNotIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['objectOwnerIdIn'] != null) {
            queryParameters['object_owner_id__in'] = requestParameters['objectOwnerIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['ordering'] != null) {
            queryParameters['ordering'] = requestParameters['ordering'];
        }

        if (requestParameters['ownerIn'] != null) {
            queryParameters['owner__in'] = requestParameters['ownerIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['ownerIdIn'] != null) {
            queryParameters['owner_id__in'] = requestParameters['ownerIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['register'] != null) {
            queryParameters['register'] = requestParameters['register']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['residualRiskLabelIn'] != null) {
            queryParameters['residual_risk_label__in'] = requestParameters['residualRiskLabelIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['riskType'] != null) {
            queryParameters['risk_type'] = requestParameters['riskType'];
        }

        if (requestParameters['riskTypeIn'] != null) {
            queryParameters['risk_type__in'] = requestParameters['riskTypeIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }

        if (requestParameters['statusIn'] != null) {
            queryParameters['status__in'] = requestParameters['statusIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['tagsIn'] != null) {
            queryParameters['tags__in'] = requestParameters['tagsIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['target'] != null) {
            queryParameters['target'] = requestParameters['target'];
        }

        if (requestParameters['targetIn'] != null) {
            queryParameters['target__in'] = requestParameters['targetIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['treatmentStrategy'] != null) {
            queryParameters['treatment_strategy'] = requestParameters['treatmentStrategy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/risks/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRiskListListFromJSON(jsonValue));
    }

    /**
     */
    async risksRisksList(requestParameters: RisksRisksListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRiskListList> {
        const response = await this.risksRisksListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRisksPartialUpdateRaw(requestParameters: RisksRisksPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Risk>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling risksRisksPartialUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksRisksPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/risks/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedRiskRequestToJSON(requestParameters['patchedRiskRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskFromJSON(jsonValue));
    }

    /**
     */
    async risksRisksPartialUpdate(requestParameters: RisksRisksPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Risk> {
        const response = await this.risksRisksPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRisksRetrieveRaw(requestParameters: RisksRisksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling risksRisksRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksRisksRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/risks/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskListFromJSON(jsonValue));
    }

    /**
     */
    async risksRisksRetrieve(requestParameters: RisksRisksRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskList> {
        const response = await this.risksRisksRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksRisksUpdateRaw(requestParameters: RisksRisksUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Risk>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling risksRisksUpdate().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksRisksUpdate().'
            );
        }

        if (requestParameters['riskRequest'] == null) {
            throw new runtime.RequiredError(
                'riskRequest',
                'Required parameter "riskRequest" was null or undefined when calling risksRisksUpdate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/risks/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RiskRequestToJSON(requestParameters['riskRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskFromJSON(jsonValue));
    }

    /**
     */
    async risksRisksUpdate(requestParameters: RisksRisksUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Risk> {
        const response = await this.risksRisksUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksTemplateRegistersCreateRaw(requestParameters: RisksTemplateRegistersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskTemplateRegisterResponse>> {
        if (requestParameters['riskTemplateRegisterRequest'] == null) {
            throw new runtime.RequiredError(
                'riskTemplateRegisterRequest',
                'Required parameter "riskTemplateRegisterRequest" was null or undefined when calling risksTemplateRegistersCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/template-registers/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RiskTemplateRegisterRequestToJSON(requestParameters['riskTemplateRegisterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskTemplateRegisterResponseFromJSON(jsonValue));
    }

    /**
     */
    async risksTemplateRegistersCreate(requestParameters: RisksTemplateRegistersCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskTemplateRegisterResponse> {
        const response = await this.risksTemplateRegistersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksTemplateRegistersDestroyRaw(requestParameters: RisksTemplateRegistersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksTemplateRegistersDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/template-registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async risksTemplateRegistersDestroy(requestParameters: RisksTemplateRegistersDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.risksTemplateRegistersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async risksTemplateRegistersListRaw(requestParameters: RisksTemplateRegistersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRiskTemplateRegisterResponseList>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling risksTemplateRegistersList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['objectIsPublic'] != null) {
            queryParameters['object_is_public'] = requestParameters['objectIsPublic'];
        }

        if (requestParameters['objectOwner'] != null) {
            queryParameters['object_owner'] = requestParameters['objectOwner'];
        }

        if (requestParameters['objectOwnerIn'] != null) {
            queryParameters['object_owner__in'] = requestParameters['objectOwnerIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['objectOwnerId'] != null) {
            queryParameters['object_owner_id'] = requestParameters['objectOwnerId'];
        }

        if (requestParameters['objectOwnerIdIn'] != null) {
            queryParameters['object_owner_id__in'] = requestParameters['objectOwnerIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/template-registers/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRiskTemplateRegisterResponseListFromJSON(jsonValue));
    }

    /**
     */
    async risksTemplateRegistersList(requestParameters: RisksTemplateRegistersListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRiskTemplateRegisterResponseList> {
        const response = await this.risksTemplateRegistersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksTemplateRegistersPartialUpdateRaw(requestParameters: RisksTemplateRegistersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskTemplateRegisterResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksTemplateRegistersPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/template-registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedRiskTemplateRegisterRequestToJSON(requestParameters['patchedRiskTemplateRegisterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskTemplateRegisterResponseFromJSON(jsonValue));
    }

    /**
     */
    async risksTemplateRegistersPartialUpdate(requestParameters: RisksTemplateRegistersPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskTemplateRegisterResponse> {
        const response = await this.risksTemplateRegistersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksTemplateRegistersRetrieveRaw(requestParameters: RisksTemplateRegistersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskTemplateRegisterResponse>> {
        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling risksTemplateRegistersRetrieve().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksTemplateRegistersRetrieve().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/template-registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskTemplateRegisterResponseFromJSON(jsonValue));
    }

    /**
     */
    async risksTemplateRegistersRetrieve(requestParameters: RisksTemplateRegistersRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskTemplateRegisterResponse> {
        const response = await this.risksTemplateRegistersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksTemplateRegistersUpdateRaw(requestParameters: RisksTemplateRegistersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskTemplateRegisterResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksTemplateRegistersUpdate().'
            );
        }

        if (requestParameters['riskTemplateRegisterRequest'] == null) {
            throw new runtime.RequiredError(
                'riskTemplateRegisterRequest',
                'Required parameter "riskTemplateRegisterRequest" was null or undefined when calling risksTemplateRegistersUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/template-registers/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RiskTemplateRegisterRequestToJSON(requestParameters['riskTemplateRegisterRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskTemplateRegisterResponseFromJSON(jsonValue));
    }

    /**
     */
    async risksTemplateRegistersUpdate(requestParameters: RisksTemplateRegistersUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskTemplateRegisterResponse> {
        const response = await this.risksTemplateRegistersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksTemplatesCreateRaw(requestParameters: RisksTemplatesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskTemplate>> {
        if (requestParameters['riskTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'riskTemplateRequest',
                'Required parameter "riskTemplateRequest" was null or undefined when calling risksTemplatesCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/templates/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RiskTemplateRequestToJSON(requestParameters['riskTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskTemplateFromJSON(jsonValue));
    }

    /**
     */
    async risksTemplatesCreate(requestParameters: RisksTemplatesCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskTemplate> {
        const response = await this.risksTemplatesCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksTemplatesDestroyRaw(requestParameters: RisksTemplatesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksTemplatesDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async risksTemplatesDestroy(requestParameters: RisksTemplatesDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.risksTemplatesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async risksTemplatesListRaw(requestParameters: RisksTemplatesListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedRiskTemplateList>> {
        const queryParameters: any = {};

        if (requestParameters['approvalStatusIn'] != null) {
            queryParameters['approval_status__in'] = requestParameters['approvalStatusIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvedBy'] != null) {
            queryParameters['approved_by'] = requestParameters['approvedBy']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvedByIn'] != null) {
            queryParameters['approved_by__in'] = requestParameters['approvedByIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvedById'] != null) {
            queryParameters['approved_by_id'] = requestParameters['approvedById']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['approvedByIdIn'] != null) {
            queryParameters['approved_by_id__in'] = requestParameters['approvedByIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['area'] != null) {
            queryParameters['area'] = requestParameters['area'];
        }

        if (requestParameters['areaIn'] != null) {
            queryParameters['area__in'] = requestParameters['areaIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['areaIdIn'] != null) {
            queryParameters['area_id__in'] = requestParameters['areaIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['createdAt'] != null) {
            queryParameters['created_at'] = (requestParameters['createdAt'] as any).toISOString();
        }

        if (requestParameters['createdAtGt'] != null) {
            queryParameters['created_at__gt'] = (requestParameters['createdAtGt'] as any).toISOString();
        }

        if (requestParameters['createdAtIn'] != null) {
            queryParameters['created_at__in'] = requestParameters['createdAtIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['createdAtLt'] != null) {
            queryParameters['created_at__lt'] = (requestParameters['createdAtLt'] as any).toISOString();
        }

        if (requestParameters['createdAtRange'] != null) {
            queryParameters['created_at__range'] = requestParameters['createdAtRange']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['customIdIn'] != null) {
            queryParameters['custom_id__in'] = requestParameters['customIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameIcontains'] != null) {
            queryParameters['name__icontains'] = requestParameters['nameIcontains'];
        }

        if (requestParameters['ownerIn'] != null) {
            queryParameters['owner__in'] = requestParameters['ownerIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['ownerIdIn'] != null) {
            queryParameters['owner_id__in'] = requestParameters['ownerIdIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['page_size'] = requestParameters['pageSize'];
        }

        if (requestParameters['riskType'] != null) {
            queryParameters['risk_type'] = requestParameters['riskType'];
        }

        if (requestParameters['riskTypeIn'] != null) {
            queryParameters['risk_type__in'] = requestParameters['riskTypeIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['target'] != null) {
            queryParameters['target'] = requestParameters['target'];
        }

        if (requestParameters['targetIn'] != null) {
            queryParameters['target__in'] = requestParameters['targetIn']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['treatmentStrategy'] != null) {
            queryParameters['treatment_strategy'] = requestParameters['treatmentStrategy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/templates/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedRiskTemplateListFromJSON(jsonValue));
    }

    /**
     */
    async risksTemplatesList(requestParameters: RisksTemplatesListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedRiskTemplateList> {
        const response = await this.risksTemplatesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksTemplatesPartialUpdateRaw(requestParameters: RisksTemplatesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskTemplate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksTemplatesPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedRiskTemplateRequestToJSON(requestParameters['patchedRiskTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskTemplateFromJSON(jsonValue));
    }

    /**
     */
    async risksTemplatesPartialUpdate(requestParameters: RisksTemplatesPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskTemplate> {
        const response = await this.risksTemplatesPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksTemplatesRetrieveRaw(requestParameters: RisksTemplatesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskTemplate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksTemplatesRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskTemplateFromJSON(jsonValue));
    }

    /**
     */
    async risksTemplatesRetrieve(requestParameters: RisksTemplatesRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskTemplate> {
        const response = await this.risksTemplatesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async risksTemplatesUpdateRaw(requestParameters: RisksTemplatesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RiskTemplate>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling risksTemplatesUpdate().'
            );
        }

        if (requestParameters['riskTemplateRequest'] == null) {
            throw new runtime.RequiredError(
                'riskTemplateRequest',
                'Required parameter "riskTemplateRequest" was null or undefined when calling risksTemplatesUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // tokenAuth authentication
        }

        const response = await this.request({
            path: `/api/v1/risks/templates/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RiskTemplateRequestToJSON(requestParameters['riskTemplateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RiskTemplateFromJSON(jsonValue));
    }

    /**
     */
    async risksTemplatesUpdate(requestParameters: RisksTemplatesUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RiskTemplate> {
        const response = await this.risksTemplatesUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const RisksRiskCommentsListStatusEnum = {
    Open: 'open',
    Resolved: 'resolved'
} as const;
export type RisksRiskCommentsListStatusEnum = typeof RisksRiskCommentsListStatusEnum[keyof typeof RisksRiskCommentsListStatusEnum];
/**
 * @export
 */
export const RisksRisksListTreatmentStrategyEnum = {
    Accept: 'ACCEPT',
    Avoid: 'AVOID',
    Mitigate: 'MITIGATE',
    Transfer: 'TRANSFER'
} as const;
export type RisksRisksListTreatmentStrategyEnum = typeof RisksRisksListTreatmentStrategyEnum[keyof typeof RisksRisksListTreatmentStrategyEnum];
/**
 * @export
 */
export const RisksTemplatesListTreatmentStrategyEnum = {
    Accept: 'ACCEPT',
    Avoid: 'AVOID',
    Mitigate: 'MITIGATE',
    Transfer: 'TRANSFER'
} as const;
export type RisksTemplatesListTreatmentStrategyEnum = typeof RisksTemplatesListTreatmentStrategyEnum[keyof typeof RisksTemplatesListTreatmentStrategyEnum];
