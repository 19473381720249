import { InputHTMLAttributes } from "react";

import { Checkbox as CheckboxPrimitive, DeepPartial, FlowbiteCheckboxTheme } from "flowbite-react";

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  checked: boolean;
  onChange: (value: boolean) => void;
  theme?: DeepPartial<FlowbiteCheckboxTheme>;
};

export const Checkbox = ({ theme, checked, onChange, ...props }: CheckboxProps) => {
  return (
    <CheckboxPrimitive
      checked={checked}
      theme={theme}
      onChange={(e) => onChange(e.target.checked)}
      {...props}
    />
  );
};
