/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CommentStatusEnum } from './CommentStatusEnum';
import {
    CommentStatusEnumFromJSON,
    CommentStatusEnumFromJSONTyped,
    CommentStatusEnumToJSON,
} from './CommentStatusEnum';

/**
 * 
 * @export
 * @interface PatchedFeedArticleCommentRequest
 */
export interface PatchedFeedArticleCommentRequest {
    /**
     * 
     * @type {Array<Blob>}
     * @memberof PatchedFeedArticleCommentRequest
     */
    attachments?: Array<Blob>;
    /**
     * 
     * @type {string}
     * @memberof PatchedFeedArticleCommentRequest
     */
    text?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof PatchedFeedArticleCommentRequest
     */
    status?: CommentStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PatchedFeedArticleCommentRequest
     */
    article?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedFeedArticleCommentRequest
     */
    mentions?: Array<number>;
}



/**
 * Check if a given object implements the PatchedFeedArticleCommentRequest interface.
 */
export function instanceOfPatchedFeedArticleCommentRequest(value: object): value is PatchedFeedArticleCommentRequest {
    return true;
}

export function PatchedFeedArticleCommentRequestFromJSON(json: any): PatchedFeedArticleCommentRequest {
    return PatchedFeedArticleCommentRequestFromJSONTyped(json, false);
}

export function PatchedFeedArticleCommentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedFeedArticleCommentRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'attachments': json['attachments'] == null ? undefined : json['attachments'],
        'text': json['text'] == null ? undefined : json['text'],
        'status': json['status'] == null ? undefined : CommentStatusEnumFromJSON(json['status']),
        'article': json['article'] == null ? undefined : json['article'],
        'mentions': json['mentions'] == null ? undefined : json['mentions'],
    };
}

export function PatchedFeedArticleCommentRequestToJSON(value?: PatchedFeedArticleCommentRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attachments': value['attachments'],
        'text': value['text'],
        'status': CommentStatusEnumToJSON(value['status']),
        'article': value['article'],
        'mentions': value['mentions'],
    };
}

