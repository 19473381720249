/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EvidenceRequestRequestAreaOfFocus } from './EvidenceRequestRequestAreaOfFocus';
import {
    EvidenceRequestRequestAreaOfFocusFromJSON,
    EvidenceRequestRequestAreaOfFocusFromJSONTyped,
    EvidenceRequestRequestAreaOfFocusToJSON,
} from './EvidenceRequestRequestAreaOfFocus';
import type { ArtifactRequest } from './ArtifactRequest';
import {
    ArtifactRequestFromJSON,
    ArtifactRequestFromJSONTyped,
    ArtifactRequestToJSON,
} from './ArtifactRequest';
import type { ReviewFrequencyRequest } from './ReviewFrequencyRequest';
import {
    ReviewFrequencyRequestFromJSON,
    ReviewFrequencyRequestFromJSONTyped,
    ReviewFrequencyRequestToJSON,
} from './ReviewFrequencyRequest';
import type { EvidenceStatusEnum } from './EvidenceStatusEnum';
import {
    EvidenceStatusEnumFromJSON,
    EvidenceStatusEnumFromJSONTyped,
    EvidenceStatusEnumToJSON,
} from './EvidenceStatusEnum';

/**
 * Adds nested create feature
 * @export
 * @interface PatchedEvidenceRequestRequest
 */
export interface PatchedEvidenceRequestRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedEvidenceRequestRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedEvidenceRequestRequest
     */
    description?: string | null;
    /**
     * 
     * @type {EvidenceRequestRequestAreaOfFocus}
     * @memberof PatchedEvidenceRequestRequest
     */
    areaOfFocus?: EvidenceRequestRequestAreaOfFocus | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedEvidenceRequestRequest
     */
    tags?: Array<number>;
    /**
     * 
     * @type {Array<ArtifactRequest>}
     * @memberof PatchedEvidenceRequestRequest
     */
    artifacts?: Array<ArtifactRequest>;
    /**
     * 
     * @type {ReviewFrequencyRequest}
     * @memberof PatchedEvidenceRequestRequest
     */
    reviewFrequency?: ReviewFrequencyRequest;
    /**
     * 
     * @type {string}
     * @memberof PatchedEvidenceRequestRequest
     */
    customId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedEvidenceRequestRequest
     */
    company?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedEvidenceRequestRequest
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {EvidenceStatusEnum}
     * @memberof PatchedEvidenceRequestRequest
     */
    status?: EvidenceStatusEnum;
}



/**
 * Check if a given object implements the PatchedEvidenceRequestRequest interface.
 */
export function instanceOfPatchedEvidenceRequestRequest(value: object): value is PatchedEvidenceRequestRequest {
    return true;
}

export function PatchedEvidenceRequestRequestFromJSON(json: any): PatchedEvidenceRequestRequest {
    return PatchedEvidenceRequestRequestFromJSONTyped(json, false);
}

export function PatchedEvidenceRequestRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedEvidenceRequestRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'areaOfFocus': json['area_of_focus'] == null ? undefined : EvidenceRequestRequestAreaOfFocusFromJSON(json['area_of_focus']),
        'tags': json['tags'] == null ? undefined : json['tags'],
        'artifacts': json['artifacts'] == null ? undefined : ((json['artifacts'] as Array<any>).map(ArtifactRequestFromJSON)),
        'reviewFrequency': json['review_frequency'] == null ? undefined : ReviewFrequencyRequestFromJSON(json['review_frequency']),
        'customId': json['custom_id'] == null ? undefined : json['custom_id'],
        'company': json['company'] == null ? undefined : json['company'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'status': json['status'] == null ? undefined : EvidenceStatusEnumFromJSON(json['status']),
    };
}

export function PatchedEvidenceRequestRequestToJSON(value?: PatchedEvidenceRequestRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'area_of_focus': EvidenceRequestRequestAreaOfFocusToJSON(value['areaOfFocus']),
        'tags': value['tags'],
        'artifacts': value['artifacts'] == null ? undefined : ((value['artifacts'] as Array<any>).map(ArtifactRequestToJSON)),
        'review_frequency': ReviewFrequencyRequestToJSON(value['reviewFrequency']),
        'custom_id': value['customId'],
        'company': value['company'],
        'object_owner': value['objectOwner'],
        'status': EvidenceStatusEnumToJSON(value['status']),
    };
}

