/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlAreaSummary
 */
export interface ControlAreaSummary {
    /**
     * 
     * @type {string}
     * @memberof ControlAreaSummary
     */
    controlArea: string;
    /**
     * 
     * @type {number}
     * @memberof ControlAreaSummary
     */
    controls: number;
}

/**
 * Check if a given object implements the ControlAreaSummary interface.
 */
export function instanceOfControlAreaSummary(value: object): value is ControlAreaSummary {
    if (!('controlArea' in value) || value['controlArea'] === undefined) return false;
    if (!('controls' in value) || value['controls'] === undefined) return false;
    return true;
}

export function ControlAreaSummaryFromJSON(json: any): ControlAreaSummary {
    return ControlAreaSummaryFromJSONTyped(json, false);
}

export function ControlAreaSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAreaSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'controlArea': json['controlArea'],
        'controls': json['controls'],
    };
}

export function ControlAreaSummaryToJSON(value?: ControlAreaSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'controlArea': value['controlArea'],
        'controls': value['controls'],
    };
}

