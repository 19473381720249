import { Link } from "react-router-dom";

import { Spinner } from "flowbite-react";
import { Article } from "openapi";

import Icon from "shared/components/icon/Icon";
import Button from "shared/componentsV2/form-controls/button";
import { useCompany } from "shared/context/CompanyProvider";

import { useArticleContext } from "modules/horizonScanning/contexts/ArticleContext";
import { useSimilarArticleContext } from "modules/horizonScanning/contexts/SimilarArticleContext";
import { FeedArticleItem } from "modules/horizonScanning/feed-details/components/FeedArticleItems";

import ArticleSection from "./ArticleSection";

const ArticleSimilarArticles = () => {
  const { currentCompany } = useCompany();
  const { setShareModalArticle } = useArticleContext();
  const {
    articleId,
    feedId,
    similarArticlesQuery: { data, isFetching },
  } = useSimilarArticleContext();
  const similarArticles = (data || []) as Article[];

  return (
    <ArticleSection title="Similar articles" icon="newspaper">
      {isFetching ? (
        <div className="justify-self-center">
          <Spinner size="xl" className="!fill-mirage" />
        </div>
      ) : (
        <>
          <div className="grid gap-2">
            {!similarArticles || similarArticles.length === 0 ? (
              <div className="justify-self-center">No similar articles available</div>
            ) : null}
            {similarArticles?.slice(0, 2).map((item) => (
              <FeedArticleItem
                key={item.id}
                item={item}
                onAction={(article, action) => {
                  if (action === "share") {
                    setShareModalArticle(article);
                    return;
                  }
                }}
              />
            ))}
          </div>
        </>
      )}
      {similarArticles && similarArticles.length > 0 && (
        <Link
          className="justify-self-center"
          to={`/workspace/${currentCompany.id}/horizon-scanning/feeds/${feedId}/article/${articleId}/similar`}
        >
          <Button
            btnType="secondary"
            btnSize="sm"
            btnTitle="See more similar articles"
            btnIconAlign="right"
            className="w-fit"
            icon={<Icon type="arrow-right-new" />}
          />
        </Link>
      )}
    </ArticleSection>
  );
};

export default ArticleSimilarArticles;
