/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `ARTIFICIAL_TECH` - Artificial & Autonomous Technologies
 * * `ASSET_MANAGEMENT` - Asset Management
 * * `BUSINESS_CONTINUITY` - Business Continuity & Disaster recovery
 * * `CAPACITY_PLANNING` - Capacity Planning
 * * `CHANGE_MANAGEMENT` - Change Management
 * * `CLOUD_SECURITY` - Cloud Security
 * * `COMPLIANCE` - Compliance
 * * `CONFIGURATION_MANAGEMENT` - Configuration Management
 * * `CONTINUOUS_MONITORING` - Continuous Monitoring
 * * `CRYPTOGRAPHIC_PROTECTIONS` - Cryptographic Protections
 * * `CYBERSECURITY_DATA_PRIVACY_GOVERNANCE` - Cybersecurity & Data Protection Governance
 * * `DATA_CLASSIFICATION_HANDLING` - Data Classification & Handling
 * * `DATA_PRIVACY` - Data Privacy
 * * `EMBEDDED_TECHNOLOGY` - Embedded Technology
 * * `ENDPOINT_SECURITY` - Endpoint Security
 * * `HUMAN_RESOURCES_SECURITY` - Human Resources Security
 * * `IDENTIFICATION_AUTHENTICATION` - Identification & Authentication
 * * `INCIDENT_RESPONSE` - Incident Response
 * * `INFORMATION_ASSURANCE` - Information Assurance
 * * `MAINTENANCE` - Maintenance
 * * `MOBILE_DEVICE_MANAGEMENT` - Mobile Device Management
 * * `NETWORK_SECURITY` - Network Security
 * * `PHYSICAL_ENVIRONMENTAL_SECURITY` - Physical & Environmental Security
 * * `PROJECT_RESOURCE_MANAGEMENT` - Project & Resource Management
 * * `RISK_MANAGEMENT` - Risk Management
 * * `SECURE_ENGINEERING_ARCHITECTURE` - Secure Engineering & Architecture
 * * `SECURITY_AWARENESS_TRAINING` - Security Awareness & Training
 * * `SECURITY_OPERATIONS` - Security Operations
 * * `TECHNOLOGY_DEVELOPMENT_ACQUISITION` - Technology Development & Acquisition
 * * `THIRD_PARTY_MANAGEMENT` - Third-Party Management
 * * `THREAT_MANAGEMENT` - Threat Management
 * * `VULNERABILITY_PATCH_MANAGEMENT` - Vulnerability & Patch Management
 * * `WEB_SECURITY` - Web Security
 * @export
 */
export const ControlAreaEnum = {
    ArtificialTech: 'ARTIFICIAL_TECH',
    AssetManagement: 'ASSET_MANAGEMENT',
    BusinessContinuity: 'BUSINESS_CONTINUITY',
    CapacityPlanning: 'CAPACITY_PLANNING',
    ChangeManagement: 'CHANGE_MANAGEMENT',
    CloudSecurity: 'CLOUD_SECURITY',
    Compliance: 'COMPLIANCE',
    ConfigurationManagement: 'CONFIGURATION_MANAGEMENT',
    ContinuousMonitoring: 'CONTINUOUS_MONITORING',
    CryptographicProtections: 'CRYPTOGRAPHIC_PROTECTIONS',
    CybersecurityDataPrivacyGovernance: 'CYBERSECURITY_DATA_PRIVACY_GOVERNANCE',
    DataClassificationHandling: 'DATA_CLASSIFICATION_HANDLING',
    DataPrivacy: 'DATA_PRIVACY',
    EmbeddedTechnology: 'EMBEDDED_TECHNOLOGY',
    EndpointSecurity: 'ENDPOINT_SECURITY',
    HumanResourcesSecurity: 'HUMAN_RESOURCES_SECURITY',
    IdentificationAuthentication: 'IDENTIFICATION_AUTHENTICATION',
    IncidentResponse: 'INCIDENT_RESPONSE',
    InformationAssurance: 'INFORMATION_ASSURANCE',
    Maintenance: 'MAINTENANCE',
    MobileDeviceManagement: 'MOBILE_DEVICE_MANAGEMENT',
    NetworkSecurity: 'NETWORK_SECURITY',
    PhysicalEnvironmentalSecurity: 'PHYSICAL_ENVIRONMENTAL_SECURITY',
    ProjectResourceManagement: 'PROJECT_RESOURCE_MANAGEMENT',
    RiskManagement: 'RISK_MANAGEMENT',
    SecureEngineeringArchitecture: 'SECURE_ENGINEERING_ARCHITECTURE',
    SecurityAwarenessTraining: 'SECURITY_AWARENESS_TRAINING',
    SecurityOperations: 'SECURITY_OPERATIONS',
    TechnologyDevelopmentAcquisition: 'TECHNOLOGY_DEVELOPMENT_ACQUISITION',
    ThirdPartyManagement: 'THIRD_PARTY_MANAGEMENT',
    ThreatManagement: 'THREAT_MANAGEMENT',
    VulnerabilityPatchManagement: 'VULNERABILITY_PATCH_MANAGEMENT',
    WebSecurity: 'WEB_SECURITY'
} as const;
export type ControlAreaEnum = typeof ControlAreaEnum[keyof typeof ControlAreaEnum];


export function instanceOfControlAreaEnum(value: any): boolean {
    for (const key in ControlAreaEnum) {
        if (Object.prototype.hasOwnProperty.call(ControlAreaEnum, key)) {
            if (ControlAreaEnum[key as keyof typeof ControlAreaEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ControlAreaEnumFromJSON(json: any): ControlAreaEnum {
    return ControlAreaEnumFromJSONTyped(json, false);
}

export function ControlAreaEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlAreaEnum {
    return json as ControlAreaEnum;
}

export function ControlAreaEnumToJSON(value?: ControlAreaEnum | null): any {
    return value as any;
}

