import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import { Tooltip } from "flowbite-react";

import { useQueryClient } from "@tanstack/react-query";

import styles from "assets/css/articlesV2.module.css";

import Icon from "shared/components/icon/Icon";
import PaginationIcon from "shared/components/pagination-icon/PaginationIcon";
import { useHorizonScanningMenuBar } from "shared/storeV2/horizonScanningMenubar";
import { useCurrentModule } from "shared/storeV2/module";

import { useArticleContext } from "modules/horizonScanning/contexts/ArticleContext";
import { useFeedArticlesWithFeed } from "modules/horizonScanning/contexts/useFeedArticlesWithFeed";
import { useFeedItemActionState } from "modules/horizonScanning/hooks/useFeedActionState";
import useFeed from "modules/horizonScanning/shared/hooks/useFeed";

const ActionBar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const stickyActionsRef = useRef<HTMLDivElement>(null);

  const {
    articleId,
    companyId,
    feedId,
    isPublic,
    articleQuery: { data: article },
    setShareModalArticle,
    ...rest
  } = useArticleContext();

  const stateAction = useFeedItemActionState({ feedId: 0, articleId });
  const archiveStateAction = useFeedItemActionState({ feedId, articleId });
  const { toggleMenuBar } = useHorizonScanningMenuBar();
  const [currentModule] = useCurrentModule((state) => [state.currentModule]);
  const queryClient = useQueryClient();

  const { data: feed } = useFeed({ feedId });

  if (!article) return null;
  const isRead = stateAction.isRead ?? article.isRead;
  const isSaved = stateAction.isSaved ?? article.isSaved;
  const isArchived = archiveStateAction.isArchived ?? article.isArchived;

  const onSuccessCallback = () => {
    if (toggleMenuBar) {
      queryClient.invalidateQueries(["getActivityLogs", currentModule?.id]);
    }
  };

  const actions = [
    {
      tooltip: isRead ? "This article was marked as read" : "Mark as read",
      icon: "book-open",
      fill: isRead,
      badgeColor: isRead ? "fill-blue-600 !stroke-blue-600" : "inherit",
      onClick: () => (isRead ? rest.unread(onSuccessCallback) : rest.read(onSuccessCallback)),
      hide: isPublic,
    },
    {
      tooltip: isSaved ? "Saved" : "Save",
      icon: "bookmark",
      fill: isSaved,
      onClick: () => (isSaved ? rest.unsave(onSuccessCallback) : rest.save(onSuccessCallback)),
      hide: isPublic,
    },
    {
      tooltip: isArchived ? "Archived" : "Archive",
      icon: isArchived ? "archive-box-fill" : "archive-box",
      className: isArchived ? "fill-blue-600 stroke-none" : "inherit",
      fill: isArchived,
      onClick: () =>
        isArchived ? rest.unarchive(onSuccessCallback) : rest.archive(onSuccessCallback),
      hide: isPublic || !(Number(feedId) > 0),
    },
    {
      tooltip: "Share",
      icon: "share",
      onClick: () => setShareModalArticle(article),
    },
  ];

  useEffect(() => {
    if (stickyActionsRef.current?.parentElement) {
      const handleParentScroll = (event: Event) => {
        if (event.target instanceof HTMLDivElement && event.target.scrollTop >= 32) {
          setIsSticky(true);
          return;
        }

        setIsSticky(false);
      };

      stickyActionsRef.current?.parentElement.addEventListener("scroll", handleParentScroll);

      return () => {
        stickyActionsRef.current?.parentElement?.removeEventListener("scroll", handleParentScroll);
      };
    }
  }, []);

  return (
    <div
      className={clsx(
        "sticky -top-8 flex items-center justify-between gap-2",
        styles.article_details_actions,
        {
          "!col-start-1 !col-end-4 bg-white px-8 py-3 shadow-sm dark:bg-mirage": isSticky,
        }
      )}
      ref={stickyActionsRef}
    >
      {!isPublic ? (
        <div className="vendor_pagination_button flex gap-2">
          {feed && (
            <Link
              to={`/workspace/${companyId}/horizon-scanning/feeds/${feedId}`}
              className="text-xs font-medium"
            >
              <button className="h-5 rounded-md border px-1.5 py-0.5 dark:text-gray-400">
                {feed.name}
              </button>
            </Link>
          )}

          <ArticlePagination />
        </div>
      ) : (
        <Link
          to="https://www.grand.io"
          className="text-xs font-medium"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="flex items-center gap-2 rounded-md !bg-darkJungleGreen px-2 py-2">
            <Icon type="post" fill={false} size="icon-xs" className="notification_icon" />
            <h4
              data-testid="unauthorized-warning"
              className="text-xs font-medium text-white dark:text-mirage"
            >
              Article shared from Grand's news monitoring service
            </h4>
          </button>
        </Link>
      )}
      <div className="flex gap-3">
        {actions
          .filter((action) => !action.hide)
          .map((action) => (
            <Tooltip
              key={action.icon}
              className="metadata_tooltip whitespace-nowrap"
              content={action.tooltip}
              arrow={false}
            >
              <div onClick={() => action.onClick()}>
                <Icon
                  className={clsx(
                    "cursor-pointer",
                    {
                      "fill-blue-600 stroke-blue-600": action.fill,
                    },
                    action.className
                  )}
                  type={action.icon}
                  size="icon-xs"
                  fill={action.fill}
                  badgeColor={action?.badgeColor}
                />
              </div>
            </Tooltip>
          ))}
      </div>
    </div>
  );
};

export default ActionBar;

const ArticlePagination = () => {
  const { articleId, companyId, feedId } = useArticleContext();

  const { feedArticlesQuery } = useFeedArticlesWithFeed({ feedId });
  const flatArticles = feedArticlesQuery.data?.pages.flatMap((page) => page.results) || [];

  const currentArticleIndex = flatArticles.findLastIndex((a) => a.id === articleId);

  const previousArticleIndex = currentArticleIndex - 1;
  const previousArticle = previousArticleIndex >= 0 ? flatArticles[previousArticleIndex] : null;

  const nextArticleIndex = currentArticleIndex + 1;
  const nextArticle =
    nextArticleIndex < flatArticles.length ? flatArticles[nextArticleIndex] : null;

  useEffect(() => {
    if (currentArticleIndex >= flatArticles.length - 2) {
      feedArticlesQuery.hasNextPage && feedArticlesQuery.fetchNextPage();
    }
  }, [currentArticleIndex, flatArticles.length]);

  return (
    <div className="flex">
      <div className="h-5 rounded-l-md border border-r-0 px-1.5 py-0.5 text-xs font-medium">
        <PaginationIcon
          to={`/workspace/${companyId}/horizon-scanning/feeds/${feedId}/article/${previousArticle?.id}`}
          iconType="leftarrow"
          isDisabled={!previousArticle}
        />
      </div>
      <div className="h-5 rounded-r-md border px-1.5 py-0.5 text-xs font-medium">
        <PaginationIcon
          to={`/workspace/${companyId}/horizon-scanning/feeds/${feedId}/article/${nextArticle?.id}`}
          iconType="rightarrow"
          isDisabled={!nextArticle}
        />
      </div>
    </div>
  );
};
