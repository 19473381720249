/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthorityBodyMini
 */
export interface AuthorityBodyMini {
    /**
     * 
     * @type {string}
     * @memberof AuthorityBodyMini
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AuthorityBodyMini
     */
    readonly id: number;
}

/**
 * Check if a given object implements the AuthorityBodyMini interface.
 */
export function instanceOfAuthorityBodyMini(value: object): value is AuthorityBodyMini {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function AuthorityBodyMiniFromJSON(json: any): AuthorityBodyMini {
    return AuthorityBodyMiniFromJSONTyped(json, false);
}

export function AuthorityBodyMiniFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorityBodyMini {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
    };
}

export function AuthorityBodyMiniToJSON(value?: Omit<AuthorityBodyMini, 'id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
    };
}

