/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TextArea } from './TextArea';
import {
    TextAreaFromJSON,
    TextAreaFromJSONTyped,
    TextAreaToJSON,
} from './TextArea';
import type { ControlMini } from './ControlMini';
import {
    ControlMiniFromJSON,
    ControlMiniFromJSONTyped,
    ControlMiniToJSON,
} from './ControlMini';

/**
 * 
 * @export
 * @interface Obligation
 */
export interface Obligation {
    /**
     * 
     * @type {number}
     * @memberof Obligation
     */
    readonly id: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Obligation
     */
    categories: Array<string>;
    /**
     * 
     * @type {Array<TextArea>}
     * @memberof Obligation
     */
    textAreas: Array<TextArea>;
    /**
     * 
     * @type {string}
     * @memberof Obligation
     */
    readonly obligationTopic: string;
    /**
     * 
     * @type {string}
     * @memberof Obligation
     */
    readonly authorityDocumentName: string;
    /**
     * 
     * @type {Array<ControlMini>}
     * @memberof Obligation
     */
    readonly controls: Array<ControlMini>;
    /**
     * 
     * @type {Date}
     * @memberof Obligation
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Obligation
     */
    readonly updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Obligation
     */
    objectIsPublic?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Obligation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Obligation
     */
    text: string;
    /**
     * 
     * @type {boolean}
     * @memberof Obligation
     */
    policyCoverage?: boolean;
    /**
     * Indicates whether the text was extracted using AI.
     * @type {boolean}
     * @memberof Obligation
     */
    readonly isAiExtracted: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Obligation
     */
    indexed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Obligation
     */
    startingPageIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Obligation
     */
    minY0?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Obligation
     */
    objectOwner?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Obligation
     */
    authorityDocument: number;
}

/**
 * Check if a given object implements the Obligation interface.
 */
export function instanceOfObligation(value: object): value is Obligation {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('categories' in value) || value['categories'] === undefined) return false;
    if (!('textAreas' in value) || value['textAreas'] === undefined) return false;
    if (!('obligationTopic' in value) || value['obligationTopic'] === undefined) return false;
    if (!('authorityDocumentName' in value) || value['authorityDocumentName'] === undefined) return false;
    if (!('controls' in value) || value['controls'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('text' in value) || value['text'] === undefined) return false;
    if (!('isAiExtracted' in value) || value['isAiExtracted'] === undefined) return false;
    if (!('authorityDocument' in value) || value['authorityDocument'] === undefined) return false;
    return true;
}

export function ObligationFromJSON(json: any): Obligation {
    return ObligationFromJSONTyped(json, false);
}

export function ObligationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Obligation {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'categories': json['categories'],
        'textAreas': ((json['text_areas'] as Array<any>).map(TextAreaFromJSON)),
        'obligationTopic': json['obligation_topic'],
        'authorityDocumentName': json['authority_document_name'],
        'controls': ((json['controls'] as Array<any>).map(ControlMiniFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'objectIsPublic': json['object_is_public'] == null ? undefined : json['object_is_public'],
        'name': json['name'],
        'text': json['text'],
        'policyCoverage': json['policy_coverage'] == null ? undefined : json['policy_coverage'],
        'isAiExtracted': json['is_ai_extracted'],
        'indexed': json['indexed'] == null ? undefined : json['indexed'],
        'startingPageIndex': json['starting_page_index'] == null ? undefined : json['starting_page_index'],
        'minY0': json['min_y0'] == null ? undefined : json['min_y0'],
        'objectOwner': json['object_owner'] == null ? undefined : json['object_owner'],
        'authorityDocument': json['authority_document'],
    };
}

export function ObligationToJSON(value?: Omit<Obligation, 'id'|'obligation_topic'|'authority_document_name'|'controls'|'created_at'|'updated_at'|'is_ai_extracted'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'categories': value['categories'],
        'text_areas': ((value['textAreas'] as Array<any>).map(TextAreaToJSON)),
        'object_is_public': value['objectIsPublic'],
        'name': value['name'],
        'text': value['text'],
        'policy_coverage': value['policyCoverage'],
        'indexed': value['indexed'],
        'starting_page_index': value['startingPageIndex'],
        'min_y0': value['minY0'],
        'object_owner': value['objectOwner'],
        'authority_document': value['authorityDocument'],
    };
}

