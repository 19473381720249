import { useEffect, useState } from "react";

import { client } from "client";
import { VisibilityEnum } from "openapi";

import { useQuery, useQueryClient } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";
import usePagination from "shared/hooks/usePagination";

type FeedsQueryParams = Partial<{
  name: string;
  search: string;
  isArchived: boolean;
  visibility: VisibilityEnum;
}>;

const PAGE_SIZE = 25;

const usePaginatedFeedsQuery = (props: FeedsQueryParams) => {
  const [totalItems, setTotalItems] = useState(0);
  const { currentCompany } = useCompany();
  const queryClient = useQueryClient();

  const { currentPage, nextPage, prevPage, goToPage, totalPages } = usePagination({
    initialPage: 1,
    initialPageSize: PAGE_SIZE,
    totalItems,
  });

  const queryResponse = useQuery(
    ["get-feeds-paginated", props, currentPage, PAGE_SIZE],
    () =>
      client.horizonScanning.horizonFeedsList({
        ...props,
        companyId: currentCompany.id,
        page: currentPage,
        pageSize: PAGE_SIZE,
      }),
    {
      onSuccess: (response) => {
        setTotalItems(response.count || 0);
      },
      enabled: !!currentCompany.id,
    }
  );

  const invalidateFeedsQuery = () => {
    queryClient.invalidateQueries(["get-feeds-paginated", props, currentPage, PAGE_SIZE]);
  };

  useEffect(() => {
    goToPage(1);
  }, [props.name]);

  return {
    ...queryResponse,
    currentPage,
    nextPage,
    prevPage,
    goToPage,
    totalPages,
    invalidateFeedsQuery,
  };
};

export default usePaginatedFeedsQuery;
