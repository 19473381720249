import { client } from "client";

import { useQuery } from "@tanstack/react-query";

import { useCompany } from "shared/context/CompanyProvider";

const useSimilarArticles = ({
  feedId,
  articleId,
  isFilterReady = true,
}: {
  feedId: number;
  articleId: number;
  isFilterReady?: boolean;
}) => {
  const { currentCompany } = useCompany();
  return useQuery({
    queryKey: ["similar-articles", feedId, currentCompany.id, articleId],
    queryFn: () =>
      client.horizonScanning.horizonFeedsArticlesSimilarArticlesList({
        feedId,
        companyId: currentCompany.id,
        id: articleId,
      }),
    enabled: !!articleId && !!feedId && isFilterReady,
  });
};

export default useSimilarArticles;
