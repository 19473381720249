/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlRisk
 */
export interface ControlRisk {
    /**
     * 
     * @type {number}
     * @memberof ControlRisk
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ControlRisk
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ControlRisk
     */
    readonly registerId: string;
    /**
     * 
     * @type {string}
     * @memberof ControlRisk
     */
    inherentRiskScore?: string;
    /**
     * 
     * @type {string}
     * @memberof ControlRisk
     */
    residualRiskScore?: string;
}

/**
 * Check if a given object implements the ControlRisk interface.
 */
export function instanceOfControlRisk(value: object): value is ControlRisk {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('registerId' in value) || value['registerId'] === undefined) return false;
    return true;
}

export function ControlRiskFromJSON(json: any): ControlRisk {
    return ControlRiskFromJSONTyped(json, false);
}

export function ControlRiskFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlRisk {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'registerId': json['register_id'],
        'inherentRiskScore': json['inherent_risk_score'] == null ? undefined : json['inherent_risk_score'],
        'residualRiskScore': json['residual_risk_score'] == null ? undefined : json['residual_risk_score'],
    };
}

export function ControlRiskToJSON(value?: Omit<ControlRisk, 'id'|'register_id'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'inherent_risk_score': value['inherentRiskScore'],
        'residual_risk_score': value['residualRiskScore'],
    };
}

