/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ControlConnectionRequest
 */
export interface ControlConnectionRequest {
    /**
     * 
     * @type {number}
     * @memberof ControlConnectionRequest
     */
    controlId: number;
}

/**
 * Check if a given object implements the ControlConnectionRequest interface.
 */
export function instanceOfControlConnectionRequest(value: object): value is ControlConnectionRequest {
    if (!('controlId' in value) || value['controlId'] === undefined) return false;
    return true;
}

export function ControlConnectionRequestFromJSON(json: any): ControlConnectionRequest {
    return ControlConnectionRequestFromJSONTyped(json, false);
}

export function ControlConnectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlConnectionRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'controlId': json['control_id'],
    };
}

export function ControlConnectionRequestToJSON(value?: ControlConnectionRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'control_id': value['controlId'],
    };
}

