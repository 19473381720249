import React, { memo, useCallback, useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { Dropdown, Label } from "flowbite-react";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import toString from "lodash/toString";
import { useOnClickOutside } from "usehooks-ts";

import headerStyles from "assets/css/common-header.module.css";

import useConditionalyBodyLock from "shared/hooks/useConditionalyBodyLock";
import { TableFilterTypeProps, useTableFilter } from "shared/store/table-column-selection";

import {
  BOOLEAN_FILTER,
  DATETIME_FILTER,
  DATE_FILTER,
  DROPDOWN_FILTER,
  FILTER_TYPE,
  PAGINATED_FILTER,
  PREDEFINED_DATE_FILTER,
  TEXT_FILTER,
  TYPEAHEAD_FILTER,
  UNIT_VALUE_FILTER,
  USER_FILTER,
} from "../../helpers/constant";
import Icon from "../icon/Icon";
import BooleanFilter from "./BooleanFilter";
import DateFilter from "./DateFilter";
import FilterDropDownLabel from "./FilterDropDownLabel";
import InputFilter from "./InputFilter";
import PredefinedDateFilter from "./PredefinedDateFilter";
import UnitValueFilter from "./UnitValueFilter";
import UserFilter from "./UserFilter";
import PaginatedFilter from "./components/paginated-filter";
import TypeaheadFilter from "./components/typeahead-filter";
import { FilterOptionType, FiltersProp, MagicWandProps, SelectProps } from "./types";

const Filters = ({
  filters,
  name,
  displayFilters,
  magicWandProps,
}: {
  name: string;
  filters: FiltersProp[];
  displayFilters: boolean;
  magicWandProps?: MagicWandProps;
}) => {
  const setSelectedFilters = useTableFilter((state) => state.setSelectedFilters);
  const selectedFilters = useTableFilter((state) => state.selectedFilters);

  const [innerDropdownValue, setInnerDropdownValue] = useState<FiltersProp | null>(null);
  const [dropDown, setDropDown] = useState<boolean>(false);

  const [topPosition, setTopPosition] = useState<number>();

  const filterRef = useRef<HTMLDivElement>(null);

  const handleClickOutsideOfFilter = () => {
    filterRef?.current
      ?.querySelectorAll<HTMLButtonElement>("[aria-describedby]  button")
      ?.forEach((button) => button?.click());
    setInnerDropdownValue(null);
    setDropDown(false);
  };

  useEffect(() => {
    const foundItem = filters?.find((item) => innerDropdownValue?.field === item?.field);
    if (foundItem) {
      setInnerDropdownValue(cloneDeep(foundItem));
    }
  }, [filters]);

  useOnClickOutside(filterRef, handleClickOutsideOfFilter);

  const removeFilter = (filter: FilterOptionType) => {
    setSelectedFilters((prevFilters: TableFilterTypeProps | null): TableFilterTypeProps | null => {
      const data = Object.assign({}, prevFilters, {
        [name]: get(prevFilters, name)?.filter(
          (item: SelectProps) => !(item.field === filter.field && item.key === filter.key)
        ),
      });
      return data;
    });
  };

  const createLabel = (e: FilterOptionType) => {
    return {
      label: (
        <div className="flex items-center rounded-md bg-cyanblue !px-0 !py-0 !pr-2">
          <span className=" mr-2 inline-block rounded-l-[5px] bg-newcar !px-1.5 !py-1">
            <Icon type="filter" fill={true} size="icon-smd" className="themesvg_white" />
          </span>
          <span className="mr-2 text-sm text-newcar">{e.label}</span>
          <span className="cursor-pointer" onClick={() => removeFilter(e)}>
            <Icon type="close" fill={true} size="icon-xs" className="close_filter_icon" />
          </span>
        </div>
      ),
      value: `${e.key}`,
      field: e.field,
      key: e.key,
      operator: e.operator,
      operators: e.operators,
      values: e.values,
      conditions: e.conditions,
    };
  };

  const onFilterClick = useCallback((e: FilterOptionType) => {
    setSelectedFilters((_search: TableFilterTypeProps | null): TableFilterTypeProps | null => {
      if (
        e.single_option &&
        get(_search, name)?.findIndex((item: SelectProps) => item.field === e.field) != undefined
      ) {
        const data = Object.assign({}, _search, {
          [name]: [
            ...get(_search, name).filter((item: SelectProps) => item.field !== e.field),
            createLabel(e),
          ].filter((filterData) => filterData.value !== "null"),
        });
        return data;
      }

      const data = Object.assign({}, _search, {
        [name]: get(_search, name)?.find(
          (item: SelectProps) => item.key === e.key && item.field === e.field
        )
          ? get(_search, name)
          : !get(_search, name)
          ? [createLabel(e)]
          : [...get(_search, name), createLabel(e)],
      });
      return data;
    });
    handleClickOutsideOfFilter();
  }, []);

  const dropdownItem = (type: string, item: FiltersProp) => {
    switch (type) {
      case TEXT_FILTER:
        return (
          <Dropdown.Item className="hover:!bg-transparent">
            <div className={headerStyles.formElement}>
              <Label className={headerStyles.formLabel}>Contains</Label>
              <InputFilter onClick={onFilterClick} item={item} magicWandProps={magicWandProps} />
            </div>
          </Dropdown.Item>
        );
      case PREDEFINED_DATE_FILTER:
        return (
          <Dropdown.Item className="hover:!bg-transparent">
            <div className={headerStyles.formElement}>
              <PredefinedDateFilter onClick={onFilterClick} item={item} />
            </div>
          </Dropdown.Item>
        );
      case DATE_FILTER:
      case DATETIME_FILTER:
        return (
          <Dropdown.Item className="hover:!bg-transparent">
            <div className={headerStyles.formElement}>
              <DateFilter onClick={onFilterClick} item={item} type={type} />
            </div>
          </Dropdown.Item>
        );
      case DROPDOWN_FILTER:
        return (
          item?.values &&
          item?.values?.map((innerItem, i) => (
            <Dropdown.Item
              key={i}
              onClick={() => {
                onFilterClick({
                  key: innerItem?.key,
                  label: <span>{innerItem.value}</span>,
                  value: innerItem.value,
                  field: item.field,
                  type: FILTER_TYPE,
                  operator: item.operator,
                  conditions: [
                    {
                      field: item.field,
                      operator: item.operator,
                      value: innerItem?.key?.toString(),
                    },
                  ],
                  single_option: item.single_option,
                });
              }}
            >
              {innerItem.value}
            </Dropdown.Item>
          ))
        );
      case PAGINATED_FILTER:
        return (
          <Dropdown.Item className="!px-0 hover:!bg-transparent">
            <div className={clsx(headerStyles.formElement, "w-full")}>
              <PaginatedFilter
                isLoading={item?.isLoading}
                hasNextPage={item?.hasNextPage}
                isFetchingNextPage={item?.isFetchingNextPage}
                fetchNextPage={item?.fetchNextPage}
                field={item.field}
                operator={toString(item.operator)}
                onClick={onFilterClick}
                items={item?.values ?? []}
                single_option={item.single_option}
                name={name}
                isFetchingData={item?.isFetchingData}
              />
            </div>
          </Dropdown.Item>
        );
      case TYPEAHEAD_FILTER:
        return (
          <Dropdown.Item className="!px-0 hover:!bg-transparent">
            <div className={clsx(headerStyles.formElement, "w-full")}>
              <TypeaheadFilter
                field={item.field}
                operator={toString(item.operator)}
                onClick={onFilterClick}
                items={item?.values ?? []}
                single_option={item.single_option}
              />
            </div>
          </Dropdown.Item>
        );
      case BOOLEAN_FILTER:
        return (
          <Dropdown.Item className="hover:!bg-transparent">
            <div className={headerStyles.formElement}>
              <Label className={headerStyles.formLabel}>Has {item.label}?</Label>
              <BooleanFilter
                label={item.label}
                field={item.field}
                operator={toString(item.operator)}
                onClick={onFilterClick}
                single_option={item.single_option}
              />
            </div>
          </Dropdown.Item>
        );
      case UNIT_VALUE_FILTER:
        return (
          <Dropdown.Item className="hover:!bg-transparent">
            <div className={headerStyles.formElement}>
              <Label className={headerStyles.formLabel}>Filter</Label>
              <UnitValueFilter
                field={item.field}
                operator={toString(item.operator)}
                onClick={onFilterClick}
                single_option={item.single_option}
                values={item.values}
                label={item.label}
              />
            </div>
          </Dropdown.Item>
        );
      case USER_FILTER:
        return (
          <div className="w-full px-4 py-2 hover:!bg-transparent">
            <UserFilter
              item={item}
              selectedFilters={
                selectedFilters?.task
                  ?.filter((filter) => filter.field === item.field)
                  .flatMap((filter) => filter.values || []) ?? []
              }
              onClick={(values) => {
                const filters = values.map(({ value, label }) => ({
                  key: value,
                  label: <span key={value}>{label}</span>,
                  values: [value],
                  field: item.field,
                  type: FILTER_TYPE,
                  operator: item.operator,
                  conditions: [{ field: item.field, operator: item.operator, value }],
                  single_option: item.single_option,
                }));

                setSelectedFilters(
                  (filterType: TableFilterTypeProps | null): TableFilterTypeProps => {
                    return Object.assign({}, filterType, {
                      [name]: [
                        // clear all assignees filters first
                        ...(get(filterType, name)?.filter(
                          (f: SelectProps) => f.field !== item.field
                        ) ?? []),
                        ...filters.map((filter) => createLabel(filter)),
                      ],
                    });
                  }
                );
                handleClickOutsideOfFilter();
              }}
            />
          </div>
        );
    }
  };

  useConditionalyBodyLock(dropDown);

  return (
    <>
      <div className="drop_down" ref={filterRef}>
        <div
          className={clsx("drop_down_button ml-5 cursor-pointer", { "button-active": dropDown })}
          onClick={() => setDropDown(!dropDown)}
        >
          <FilterDropDownLabel displayFilters={displayFilters} />
        </div>
        {dropDown ? (
          <>
            <div className="sub_dropdown_list">
              <ul>
                {filters?.map((item) => {
                  return (
                    <li
                      key={item?.field}
                      onClick={(e) => {
                        const selectedListItem = e.currentTarget as HTMLElement;
                        const position = selectedListItem.getBoundingClientRect();

                        const objectHeight =
                          selectedListItem.clientHeight > 36
                            ? selectedListItem.clientHeight / 2
                            : selectedListItem.clientHeight;

                        setTopPosition(Math.abs(position?.top - 100 - objectHeight));

                        if (innerDropdownValue?.field === item.field) {
                          setInnerDropdownValue(null);
                        } else {
                          setInnerDropdownValue(null);
                          setTimeout(() => {
                            setInnerDropdownValue(cloneDeep(item));
                          }, 100);
                        }
                      }}
                      className={clsx({
                        "active-list": innerDropdownValue?.field === item.field,
                        "pointer-events-none opacity-50":
                          item?.values?.length === 0 && innerDropdownValue?.field !== item.field,
                      })}
                    >
                      <span className="dark:text-greychateau">{item.label}</span>
                      <Icon type="rightarrow" fill={true} size="icon-smd" />
                    </li>
                  );
                })}
              </ul>
            </div>
            {innerDropdownValue ? (
              <ul
                className="inner_dropdown inner-filter-dropdown"
                style={{ top: `${topPosition}px` }}
              >
                {innerDropdownValue
                  ? dropdownItem(innerDropdownValue.type || "", cloneDeep(innerDropdownValue))
                  : null}
              </ul>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  );
};

export default memo(Filters);
