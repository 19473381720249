/* tslint:disable */
/* eslint-disable */
/**
 * Grand Backend API
 * Documentation of API endpoints of Grand Backend
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ObligationMini
 */
export interface ObligationMini {
    /**
     * 
     * @type {string}
     * @memberof ObligationMini
     */
    readonly name: string;
    /**
     * 
     * @type {number}
     * @memberof ObligationMini
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof ObligationMini
     */
    readonly objectOwner: number | null;
    /**
     * 
     * @type {number}
     * @memberof ObligationMini
     */
    readonly authorityDocument: number;
    /**
     * 
     * @type {string}
     * @memberof ObligationMini
     */
    readonly authorityDocumentName: string;
}

/**
 * Check if a given object implements the ObligationMini interface.
 */
export function instanceOfObligationMini(value: object): value is ObligationMini {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('objectOwner' in value) || value['objectOwner'] === undefined) return false;
    if (!('authorityDocument' in value) || value['authorityDocument'] === undefined) return false;
    if (!('authorityDocumentName' in value) || value['authorityDocumentName'] === undefined) return false;
    return true;
}

export function ObligationMiniFromJSON(json: any): ObligationMini {
    return ObligationMiniFromJSONTyped(json, false);
}

export function ObligationMiniFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObligationMini {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
        'objectOwner': json['object_owner'],
        'authorityDocument': json['authority_document'],
        'authorityDocumentName': json['authority_document_name'],
    };
}

export function ObligationMiniToJSON(value?: Omit<ObligationMini, 'name'|'id'|'object_owner'|'authority_document'|'authority_document_name'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
    };
}

